<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  // computed: {
  //   isFront() {
  //     console.log('this.$route.name', this.$route.name);
  //     return this.$route.name === 'Front'
  //   }
  // },
  methods: {
    created: function () {
      // Автовыход, если ошибка 401 от бэка
      this.$http.interceptors.response.use(undefined, function (err) {
        // return new Promise(function (resolve, reject) {
        return new Promise(function () {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
            this.$router.push({ path: "login" })
          }
          throw err;
        });
      });

      // Автовход
      // this.$store.dispatch('AutoLogin');

    }
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>