import {router} from "./main.js"

import Front from "@/pages/Front";
// import Dates from "@/pages/Dates";
import Jobs from "@/pages/Jobs";
import Auth from "@/pages/Auth";
import Remind from "@/pages/Remind";
import Registration from "@/pages/Registration";

import store from './store'
import Logout from "@/pages/Logout";
import Privacy from "@/pages/Privacy";
import RemindWithToken from "@/pages/RemindWithToken";
import NotFound from "@/pages/NotFound";
// import Chat from "@/pages/Chat";
import About from "@/pages/About";
import Terms from "@/pages/Terms";
import PaymentFail from "@/pages/PaymentFail";
import PaymentSuccess from "@/pages/PaymentSuccess";
import Pay from "@/pages/Pay";
//import JobsQuestions from "@/pages/JobsQuestions";
import JobsList from "@/pages/JobsList";
import Faq from "@/pages/Faq";
import ConfirmEmail from "@/pages/ConfirmEmail";
import Security from "@/pages/Security";
// import DatesSimple from "@/pages/DatesSimple";
// import DatesHard from "@/pages/DatesHard";
// import Examples from "@/pages/Examples";
import Admin from "@/pages/Admin";
import Want from "@/pages/Want";
import Latest from "@/pages/Latest";
import PayAction from "@/pages/PayAction";
import Phrases from "@/pages/Phrases";
import Pro from "@/pages/Pro";
import SavedList from "@/pages/SavedList";
import Saved from "@/pages/Saved";
import Cart from "@/pages/Cart";
import Sex from "@/pages/Sex";
import ExamplePhrases from "@/pages/ExamplePhrases";
import ExampleDescriptions from "@/pages/ExampleDescriptions";
import Promo from "@/pages/Promo";
import Portfolio from "@/pages/Portfolio";
import Settings from "@/pages/Settings";
import Main from "@/pages/Main";
import Music from "@/pages/Music";
import MusicPhrases from "@/pages/MusicPhrases";

// Если авторизован, запрещаем доступ к некоторым страницам
const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    store.dispatch('checkTokenSimple');
    if (!localStorage.getItem('token')) {
      next()
    }
    return
  }
  next('/')
}

// Если не авторизован, отправляем на авторизацию
const ifAuthenticated = (to, from, next) => {
  // console.log('localStorage.getItem(\'token\')', localStorage.getItem('token'));
  if (localStorage.getItem('token')) {
    // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTIwNTBkM2E2NjZmMzA2M2ZhNmNlODJmNTBlYzY1YzgwYzM5YWM2ZjllNzY5ODVlNWNlZTFlOWRjMWY5ZjRjOGI2OGNlNWRhNGVmYmExYzAiLCJpYXQiOjE2MDg5NzcxMDEsIm5iZiI6MTYwODk3NzEwMSwiZXhwIjoxNjQwNTEzMTAxLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.ISOyal1If-vb5FzDZkvWadBkO8CttJwwnbf9rZfPT1B7x8HAmHhCaXZQnP8t5ivAvlpNRC1gS4tKkxUB_7jeA-xBkZNa22s3fTHfsvB-yrqPn3_FZO3r-tWBQ4Pg0GRU0QsdFjh6-svnKqjrB5CCcRsMe7bed-Sa7Vtqh2sx6DWg7i_Y96vgBmjSAECqH_EFSY3Y0uq3xTAr0HbObJexillZVRziXItf2XoDOBTGT-tLFnN-SJ5ToYA8rYdhv0ZCkEfkyejahQFoI9BcmrOXcGdj53x1kK8Z4ASHwEBoqrqMO3-dcNPBmMhyFCYBkfkcZkaTb9PpvrshKs2RjHEsebAAdxAfHU5Ju-UQM3S63iSffrh4HudFKPNG8GTrHHf1P9xapIYkUWp_6iGW-MU5hU1_Nt-sUr6E8oEdoL9jowAtEznuaWXOpJIhy0ivo-TW4yI28AqSbBhcD4gjtFc0F2fuMoxLJ3rfFO47GNQHR4AeZlgHYIEfOhNFbJmRrcw6rmo7lDMfJ0dpvPC8mlj3V7fDOtQ_g3oCmU3JYU4URxIUKJ4wUhGXxpvY_27y7jFI3ne7kRJUpn_QIqtT9_95HIRAweiP00SooKM_fsJFszbkzwvW5l0BsdUFBU1dlgyU1__swKDq9WvuOmUzq7QcpHSvBnjY7uLh8a1iAPbRLgo');
    // axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    store.dispatch('checkTokenSimple');
    if (!localStorage.getItem('token')) {
      next('/registration')
    }
    next()
    // return
  } else {
    next('/registration')
  }
}

// todo: Отрефакторить
const isPayAccess = (to, from, next) => {
  let premiumType = 0;

  // Проверка на токен
  if (!localStorage.getItem('token')) {
    if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('empty token (isPayAccess)');
    }
    // next('/registration')
    // return next(false)
    next()
    return
  }

  // if (localStorage.getItem('premiumOrderId')) {
    // store.dispatch('setPremium', {order_id: localStorage.getItem('premiumOrderId')}).then((resp) => {
    store.dispatch('setPremium').then((resp) => {
      if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log('Check "setPremium"', resp.data)
      }
      premiumType = resp.data.type;

      if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log(resp.data);
      }

      // Если подписка закончилась пускаем на /pay для оплаты
      if (resp.data.is_active === false) {
        next()
        return
      } else {
        if (premiumType === 1) {
          next('/main')
          return
        } else {
          next()
          return
        }
      }


    }).catch(err => {
      if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true) || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
        console.log(err)
        console.log(err.response);
      }
      // Токен закончился или не актуален. Отправляем на авторизацию
      if (err.response.status === 401) {
        // store.dispatch("logout")
        // next('/login')
        return
      }
    });
  // }

  next()
  return
}

// Проверка на админа
const isAdmin = (to, from, next) => {
  // Проверка на токен
  if (!localStorage.getItem('token')) {
    if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true)  || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('empty token');
    }
    next('/registration')
    return
  }

  store.dispatch('isAdminCheck').then((resp) => {
    if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true)  || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('Check "isAdmin"', resp.data)
      console.log(resp.data);
    }

    if (resp.data.isAdmin === true) {
      next()
      return
    }
    else {
      next('/')
    }
  }).catch(err => {
    if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true)  || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
      console.log('routes isAdmin', err);
      console.log('routes isAdmin', err.response);
    }
    // Токен закончился или не актуален. Отправляем на авторизацию
    if (err.response.status === 401) {
      store.dispatch("logout")
      next('/login')
      return
    }
    else {
      next('/')
      return
    }
  });

  next()
  return
}

export const routes = [
  {
    path: "/",
    component: Front,
    name: "Front",
    meta: {
      title: "Best Me – Красиво о себе",
      metaTags: [
        {
          name: 'description',
          content: 'Онлайн-копирайтер. Составит Ваше резюме, сопроводительное письмо и даже анкету для приложений знакомств. Сочинять о себе не нужно. Best Me всё сочинит за Вас – на русском и английском языках.'
        },
        {
          property: 'og:description',
          content: 'Онлайн-копирайтер. Составит Ваше резюме, сопроводительное письмо и даже анкету для приложений знакомств. Сочинять о себе не нужно. Best Me всё сочинит за Вас – на русском и английском языках.'
        }
      ]
    }
  },
  {
    path: "/main",
    component: Main,
    name: "main",
    meta: {
      title: "Best Me",
    }
  },
  {
    path: "/mindfulconnections",
    component: Promo,
    name: "Promo",
    meta: {
      title: "Best Me – Mindful Connections",
    }
  },
  {
    path: "/music",
    component: Music,
    name: "Music",
    meta: {
      title: "Best Me – Music",
    }
  },
  {
    path: "/music/phrases",
    component: MusicPhrases,
    name: "MusicPhrases",
    meta: {
      title: "Best Me – Music",
    }
  },
  {
    path: "/admin",
    component: Admin,
    name: "Admin",
    beforeEnter: isAdmin,
    meta: {
      title: "Административная панель",
    }
  },
  {
    name: "pro",
    path: "/pro",
    component: Pro
  },
  {
    name: "examplePhrases",
    path: "/example-phrases",
    component: ExamplePhrases
  },
  {
    name: "exampleDescriptions",
    path: "/example-descriptions",
    component: ExampleDescriptions
  },
  {
    name: "exampleDescriptions_type",
    path: "/example-descriptions/:type",
    component: ExampleDescriptions
  },
  {
    name: "sex",
    path: "/sex",
    component: Sex
  },
  {
    name: "saved",
    path: "/saved",
    // beforeEnter: ifAuthenticated,
    component: SavedList
  },
  {
    name: "savedItem",
    path: "/saved/:id",
    beforeEnter: ifAuthenticated,
    component: Saved
  },
  {
    name: "cart",
    path: "/cart",
    component: Cart
  },
  {
    name: "want",
    path: "/want",
    component: Want
  },
  {
    name: "settings",
    path: "/settings",
    component: Settings
  },
  {
    // Портфолио - категории
    name: "portfolio_categories",
    path: "/portfolio-categories",
    component: Portfolio
  },
  {
    name: "phrases",
    path: "/phrases",
    component: Phrases
  },
  // {
  //   name: "phrases_ofu",
  //   path: "/phrases/:id_ofu",
  //   component: Phrases
  // },
  {
    // Резюме, Письмо, Портфолио, Шпаргалка, Соцсети, Знакомства
    name: "phrases_types",
    path: "/phrases/:type",
    component: Phrases
  },
  {
    // Резюме, Письмо, Портфолио, Шпаргалка, Соцсети, Знакомства
    name: "phrases_ofu",
    path: "/phrases/:type/:ofu",
    component: Phrases
  },
  // {
  //   // Фразы: Резюме
  //   name: "resume",
  //   path: "/resume",
  //   component: Phrases
  // },
  // {
  //   // Фразы: Письмо
  //   name: "letter",
  //   path: "/letter",
  //   component: Phrases
  // },
  // {
  //   // Фразы: Портфолио
  //   name: "portfolio",
  //   path: "/portfolio",
  //   component: Phrases
  // },
  // {
  //   // Фразы: Шпаргалка
  //   name: "crib",
  //   path: "/crib",
  //   component: Phrases
  // },
  // {
  //   // Фразы: Соцсети
  //   name: "socials",
  //   path: "/socials",
  //   component: Phrases
  // },
  // {
  //   // Фразы: Знакомства
  //   name: "connection",
  //   path: "/connection",
  //   component: Phrases
  // },
  {
    name: "phrases_filter",
    path: "/phrases/:id_ofu/:id_filter",
    component: Phrases
  },
  {
    name: "latest",
    path: "/latest",
    component: Latest
  },
  {
    // Можно посмотреть анониму список
    path: "/jobs",
    component: Jobs,
    props: true,
    meta: {
      title: "Для работы"
    },
    children: [
      {
        name: "jobs",
        path: '',
        component: JobsList,
        props: true,
      },
    ]
  },
  // {
  //   path: "/chat",
  //   name: "Chat",
  //   component: Chat,
  //   meta: {
  //     title: "Best Me Chat"
  //   }
  // },
  {
    path: "/about",
    component: About,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Мои описания"
    }
  },
  {
    name: "login",
    path: "/login",
    component: Auth,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Авторизация"
    }
  },
  {
    name: "registration",
    path: "/registration",
    component: Registration,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Регистрация"
    }
  },
  {
    name: "remind",
    path: "/remind",
    component: Remind,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Восстановление пароля"
    }
  },
  {
    path: "/reset-password/:token",
    component: RemindWithToken,
    // beforeEnter: ifNotAuthenticated,
    meta: {
      title: "Восстановление пароля"
    }
  },
  {
    name: "confirm-email",
    path: "/confirm-email/:hash",
    component: ConfirmEmail,
  },
  {
    name: "logout",
    path: "/logout",
    component: Logout,
    beforeEnter: ifAuthenticated,
    meta: {
      title: "Выход"
    }
  },
  {
    path: "/terms",
    component: Terms,
    meta: {
      title: "Соглашение"
    }
  },
  {
    path: "/faq",
    component: Faq,
    meta: {
      title: "Вопросы и ответы"
    }
  },
  {
    path: "/privacy",
    component: Privacy,
    meta: {
      title: "Политика конфиденциальности"
    }
  },
  {
    name: "pay",
    path: "/pay",
    beforeEnter: isPayAccess,
    component: Pay,
  },
  {
    name: "payAction",
    path: "/pay-action",
    beforeEnter: isPayAccess,
    component: PayAction,
  },
  {
    path: "/payment-success",
    component: PaymentSuccess,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Платёж прошел успешно"
    }
  },
  {
    name: "paymentFail",
    path: "/payment-fail",
    component: PaymentFail,
    // beforeEnter: ifAuthenticated,
    meta: {
      title: "Ошибка при платеже"
    }
  },
  {
    path: "/security",
    component: Security,
    meta: {
      title: "Оплата и безопасность платежей"
    }
  },
  // {
  //   path: "/examples",
  //   component: Examples,
  //   meta: {
  //     title: "Примеры"
  //   }
  // },
  {
    path: '/.well-known/apple-developer-merchantid-domain-association',
    name: 'StripeApplePay',
    redirect: '/.well-known/apple-developer-merchantid-domain-association'
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: "Страница не найдена"
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
];