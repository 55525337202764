<template>
  <div>

    <header class="header">
      <div class="container">
        <div class="header__burger-button" @click="$router.push('/music/phrases')"></div>
        <a class="header__logo" href="#" @click.prevent="$router.push('/music/phrases')">Best Me</a>
        <div class="header__right">
          <a class="header__btn-pro" href="#" @click.prevent="openFilters()"></a>
          <transition name="slide-fade" mode="out-in">
            <div class="header__menu-counter" :class="{'header__menu-counter_active': savedCount>0}" @click.prevent="openCart()" :key="savedCount">{{ savedCount }}</div>
          </transition>
        </div>
      </div>
    </header>


    <div class="full-page full-page_grey full-page_flex-column full-page_flex-column_phrases full-page_wrap">

      <div class="container full-page__phrases">

        <div class="phrases-start phrases-title" v-if="!isFilterCategory"></div>

        <div class="phrases-start full-page__filters phrases-category" v-if="isFilterCategory">
          <div class="phrases-category__name" @click="removeFilter">{{ filterCategoryTitle }}
            <div class="phrases-category__close"></div>
          </div>
        </div>

        <div class="phrases">
          <div class="phrases__menu"
               v-if="phrasesCategories.length > 1"
          >
            <div class="phrases__menu-item"
                 v-for="category in phrasesCategories"
                 v-bind:key="category.id"
                 @click="changeCategory(category.id)"
                 :class="{'phrases__menu-item_active': currentCategory === category.id}"
            >
              {{ category.name }}
            </div>
          </div>
          <div class="phrases__container">
            <div class="phrases__list">
              <div class="lds-ring" v-if="!isDataLoaded">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="swiper-container"
                   ref='mySwiper'
                   v-swiper:mySwiper="swiperOptions"
                   @slideChange="changeSwiperIndex"
                   @slideNextTransitionStart="onSlideNextTransitionStart"
                   @slidePrevTransitionStart="onSlidePrevTransitionStart"
                   @touchEnd="onTouchEnd"
                   @touchStart="onTouchStart"
                   :auto-update="true"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide" :class="['slide' + slide.id, {'slide_help': slide.is_help, 'slide_resume': isResume(slide.category_id)}]"
                       :ref="'slideMusic' + index"
                       :key="slide.title"
                       v-for="(slide, index) in slides"
                       @click="showPopup(slide)"
                  >
                    <div class="phrases__item" :class="{'phrases__item_special': isSlideSpecial(slide)}">
                      <div class="phrases__light" v-if="slide.is_help"></div>
                      <div class="phrases__title" :ref="'slideTitleMusic' + index">{{ slide.name }}</div>
                      <div class="phrases__text" :ref="'slideTextMusic' + index" v-html="slide.text"></div>
                      <!--<div class="btn btn_phrases" @click="changeCategory(slide.next_category)" v-if="slide.next_category">Начать</div>-->
                      <div class="phrases__help" v-if="slide.id === 1785">Нажмите на фразу,<br>чтобы адаптировать её под себя.</div>
                      <div class="phrases__pro" v-if="slide.isPro">PRO</div>
                      <div class="phrases__text-additional" v-if="isSlideSpecial(slide)">
                        <div class="phrases__icon-filter"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="phrases__dots swiper-pagination swiper-pagination-bullets" :class="{'phrases__dots_flex': isSlidesCountMass}"></div>
            <div class="phrases__add btn btn_bg-dark" @click="save" v-if="!isCurrentSlideIsHelp">Добавить</div>
            <!--<div class="phrases__add btn btn_bg-light-grey" @click="start" v-if="isCurrentSlideIsHelp">Начать</div>-->
          </div>
        </div>

      </div>
    </div>

    <phrase-popup-music @closePopup="onHidePopup" @clickAdd="onClickAdd" :show="setPopupShow" :phraseTitle="phraseTitle" :phraseText="phraseText" :isPhraseResume="isPhraseResume"/>

    <!--<footer-front/>-->

    <FiltersMusic :isActive="isFiltersActive" @closeFilters="onCloseFilters"/>
    <cart-popup-music :isActive="isCartActive" @closeCart="onCloseCart" @emptyCart="onEmptyCart"/>

  </div>
</template>

<script>
// import Menu from "@/components/Menu";

import {directive} from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import PhrasePopupMusic from "@/components/PhrasePopupMusic";
import FiltersMusic from '@/components/FiltersMusic';
// import FooterFront from "@/components/FooterFront";
import CartPopupMusic from "@/components/CartPopupMusic";

import popupCloseMixin from '@/mixins/popupClose';
import axios from "axios";

export default {
  name: "MusicPhrases",
  mixins: [popupCloseMixin],
  directives: {
    swiper: directive
  },
  components: {
    // FooterFront,
    // Menu,
    PhrasePopupMusic,
    FiltersMusic,
    CartPopupMusic
  },
  data() {
    // var self = this;
    return {
      //region Корзина
      count: 0,
      isCartActive: false,
      //endregion
      //region Slider
      slides: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: 1,
      },
      isSlideLast: false,
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      setPopupShow: false,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 220,
      isFilterCategory: false,
      filterCategoryTitle: '',
      isDataLoaded: false,
      dynamicValue: false,
      openMenu: false,
      isSlidesCountMass: false,
      isCurrentSlideIsHelp: false,
      // Знакомство
      isConnection: false,
      connectionModel: null,
      categoryName: null,
      isPhraseResume: false,


      isFiltersActive: false,
    }
  },
  watch: {
    '$route'(to) {
      // console.log('to', to);
      if (to.query && to.query.filter_id) {
        // console.log('to', to);
        // console.log('from', from);
        // console.log('get cat 1');
        this.getCategories(to.query.filter_id);
        // this.siteData = this.getSiteData();
      }
      else {
        this.getCategories(220)
      }
    },
  },
  mounted() {

    localStorage.setItem('lastPageMusic', this.$route.name)

    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)


    if (this.$route.query && this.$route.query.filter_id) {
      // console.log('FILTER');
      this.isFilterCategory = true;
      // console.log('get cat 4');
      this.getCategories(this.$route.query.filter_id);
      // this.getCategories(44);
    }
    else {
      // console.log('else');
      // console.log('get cat 9');
      this.getCategories(220)
    }

    // this.getSlides();

    if (this.$route.params.openMenu) {
      this.openMenu = true
    }

    this.getSavedCount();

  },
  computed: {},
  methods: {

    // Получить количество сохранённых элементов
    getSavedCount() {
      const getSavedFromLocalstorage = localStorage.getItem('saved_count_music');
      if (getSavedFromLocalstorage) {
        this.savedCount = getSavedFromLocalstorage;
      }
    },

    //region Корзина
    openCart() {
      this.isCartActive = true;
      // document.body.classList.add('modal-open');
      // document.documentElement.style.overflow = 'hidden';
    },
    onCloseCart() {
      this.isCartActive = false;
      // document.body.classList.remove('modal-open');
    },
    onEmptyCart() {
      this.getSavedCount(0);
      this.count = 0;
      this.savedCount = 0;
    },
    //endregion
    //region Фильтры
    openFilters() {
      this.isFiltersActive = true;
    },
    onCloseFilters() {
      this.isFiltersActive = false;
      this.$emit('filterSelected');
    },
    //endregion

    // Когда в меню с фильтром нажали на категорию
    onFilterSelected() {
      if (this.$route.query && this.$route.query.filter_id) {
        this.mySwiper.slideTo(0, 1000, false);
      }
    },
    // Чистый текст без кода
    pureText(str) {
      str = str.replace(/<\/?span[^>]*>/g, "");
      return str.replace(/<\/?br[^>]*>/g, "\n");
    },

    // Если это слайд "Смотрите больше фраз в иконке "Фильтры"
    isSlideSpecial(slide) {
      if (slide.is_help === 1 && (slide.text === 'Больше фраз –<br>в иконке «Фильтры».')) {
        return true
      }
      return false
    },
    // Если резюме
    isResume(category_id) {
      if (category_id) {
        // console.log('category_id', category_id);
        const arrResume = [6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 3];
        return arrResume.includes(category_id)
      }
      return false
    },
    //Получение id категории по названию
    getIdCategory(name) {
      if (name === 'resume') {
        return 2
      }
      else if (name === 'letter') {
        // this.getCategories(26);
        return 26
      }
      else if (name === 'portfolio') {
        // Frontend
        // this.getCategories(107);
        return 107
      }
      else if (name === 'crib') {
        // this.getCategories(37);
        return 37
      }
      else if (name === 'socials') {
        // this.getCategories(46);
        return 46
      }
      else if (name === 'connection') {
        // Знакомство вариант 1
        // this.getCategories(80);
        return 80
      }
    },

    //region Слайдер
    changeCategoryOnLastSlide() {
      // console.log('ELSE');
      if (this.isSlideLast) {
        // console.log('IF');
        // console.log('last slide');
        // console.log('categories', this.phrasesCategories);
        // console.log('current', this.currentCategory);

        const arrLengthIndexes = this.phrasesCategories.length - 1;
        // console.log('arrLengthIndexes', arrLengthIndexes);
        const currentCategoryIndex = this.phrasesCategories.findIndex(x => x.id === this.currentCategory);
        let nextCategoryIndex = currentCategoryIndex + 1;

        // console.log('nextCategoryIndex', nextCategoryIndex);
        if (nextCategoryIndex <= arrLengthIndexes) {
          // console.log('try');
          const nextCategory = this.phrasesCategories[nextCategoryIndex].id;
          this.currentCategory = nextCategory;
          this.changeCategory(nextCategory);
        }
        // console.log('find index', currentCategoryIndex);
        // console.log('this.phrasesCategories', this.phrasesCategories);
      }
    },

    onSlideNextTransitionStart() {
      // console.log('onSlideNextTransitionStart');
      // alert('change');
      this.isSlideLast = false;
    },

    onSlidePrevTransitionStart() {
      // console.log('onSlidePrevTransitionStart');
      // alert('change');
      this.isSlideLast = false;
    },

    onSlideChangeTransitionStart() {
      this.isSlideLast = false;
      // console.log('onSlideChangeTransitionStart');
    },

    sliderMove() {
      // console.log('sliderMove');
    },

    toEdge() {
      // console.log('toEdge');
    },

    sliderFirstMove() {
      // console.log('sliderFirstMove');
    },

    onSwipe() {
      // console.log('Swiped')
    },
    onTouchStart() {
      // console.log('onTouchStart');
      this.isSlideLast = false;
      // console.log('swiper', swiper);
      // console.log('e', e);
      // console.log('e', e);
      // if (e && e.type === 'touchstart') {
      //   this.swiperTouchStartX = e.touches[0].clientX;
      // } else {
      //   this.swiperTouchStartX = e.clientX;
      // }
    },

    // todo: почти получилось менять категорию
    onTouchEnd() {
      // console.log('onTouchEnd');

      // alert('ok');
      this.isSlideLast = true;
      // console.log('onReachEnd');

      // if (this.isSlideLast) {
      //   setTimeout(() => {
      //     this.changeCategoryOnLastSlide();
      //   }, 200);
      // }
      // console.log('onTouchEnd', swiper, e);

      // console.log('this.$refs.slide2', this.$refs.slide2.getBoundingClientRect());

      // const offset = this.$refs.mySwiper.getBoundingClientRect(); // or `this.$refs.firstOneRef`
      // const offset = this.$refs.slide2.getBoundingClientRect(); // or `this.$refs.firstOneRef`
      // const { left, width } = offset;
      // if (left > 0 && left + width <= window.innerWidth) {
      //   // The last one show, do what you want to do
      //   // console.log('touchend1');
      // } else {
      //   // The last one leave, stop the last one things
      //   // console.log('touchend2');
      // }
    },


    // При инициации слайдера
    swiperInit() {
      // this.totalSlides = this.mySwiper.slides.length
    },
    //endregion

    removeFilter() {
      this.isFilterCategory = false;
      this.$router.push({query: {}, params: {isBack: true}});

      // console.log('close filters');

      if (localStorage.getItem('category')) {
        // console.log('go!', localStorage.getItem('category'));

        // Сохраняем, какой был последний слайд, если передаём его в параметре
        if (this.$route.params && this.$route.params.lastSlideMusic) {
          // console.log('ELSE LAST');
          localStorage.setItem('lastSlideMusic', this.$route.params.lastSlideMusic);
        }

        const categoryId = parseInt(localStorage.getItem('category'));
        this.currentCategory = categoryId;

        // console.log('get1');
        this.getSlides(categoryId);
      }

    },

    //region Данные
    getCategories(cat_id) {
      let category_id = parseInt(cat_id);

      // console.log('cat_id', cat_id);
      // console.log('category_id typeof', typeof category_id);

      // let category_id = cat_id;
      // console.log('category_id', category_id);
      //region Jobs
      // HTTP.get('jobs')
      const sex = 2

      axios({
        url: process.env.VUE_APP_API + 'phrases-categories/' + category_id + '/' + sex,
        method: 'GET',
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('phrases-categories', resp);
            }
            if (resp.status === 200 && resp.data) {

              this.phrasesCategories = resp.data['list'];

              // console.log('resp.data[\'list\']', resp.data['list'].length);

              // Если есть список категорий, сразу открываем первую из списка. Иначе единственные
              if (resp.data['list'].length) {
                if (this.$route.params.isBack && localStorage.getItem('category')) {
                  const categoryId = parseInt(localStorage.getItem('category'));
                  this.currentCategory = categoryId;
                  // console.log('get2');
                  this.getSlides(categoryId);
                }
                else {
                  this.currentCategory = resp.data['list'][0].id;
                  // console.log('get3');
                  this.getSlides(resp.data['list'][0].id);
                }

              }
              else {
                this.currentCategory = category_id;
                // console.log('category_id', category_id);
                // console.log('get4');
                this.getSlides(category_id);
              }

              // Если это фильтр
              if (resp.data['category'] && resp.data['category'].is_filter) {
                this.isFilterCategory = true;
                this.filterCategoryTitle = resp.data['category'].name;
              }
              else {
                this.isFilterCategory = false;
                this.filterCategoryTitle = '';
              }

            }
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })
      //endregion
    },

    getSex() {
      let sex = localStorage.getItem('sex');
      if (sex) {
        if (sex === 'man') {
          return 1;
        }
        return 0;
      }
      return 1;
    },

    getSlides(categoryId) {

      categoryId = parseInt(categoryId);

      // Проверка на "Подсказка"
      this.tagCategoryHelp(categoryId);

      const sex = this.getSex();

      axios({
        url: process.env.VUE_APP_API + 'phrases/' + sex + '/' + categoryId,
        method: 'GET',
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('phrases/{category_id}', resp);
            }
            if (resp.status === 200 && resp.data) {
              //   this.phrasesCategories = resp.data;
              //   this.currentCategory = resp.data[0].id;
              //   this.getSlides(resp.data[0].id);
              this.slides = resp.data;

              // Пролистываю до последнего слайда
              const lastSlide = localStorage.getItem('lastSlideMusic');
              // console.log('lastSlide', lastSlide);
              if (this.$route.params.isBack && lastSlide) {
                // console.log('lastSlide2', lastSlide);
                this.mySwiper.slideTo(parseInt(lastSlide), 1000, false);
              }
              else {
                this.$refs.mySwiper.swiper.slideTo(0, 1000)
              }

              this.isDataLoaded = true;

              this.isSlidesCountMass = false;

              if (resp.data.length > 9) {
                this.isSlidesCountMass = true;
              }

              // Переинициация опций свайпера
              // setTimeout(() => this.swiperInit(), 400);
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })

      // let slides = [];
      // let randomBoolean = index => index % 2
      // Array.from({length: 7}).map(
      //     (el, index) => {
      //       slides[index] = {
      //         title: `Работа с информацией ${index + 1}`,
      //         text: `Получаю удовольствие от работы с информацией. Мне нравится процесс анализа и систематизации.
      //        Инстинктивно стремлюсь к тому, чтобы конвертировать разрозненные данные в четкие, аргументированные смыслы. ${index + 1}`,
      //         isPro: randomBoolean(index)
      //       };
      //     }
      // );
      // this.slides = slides;
    },

    checkCategoryHelp(cat) {
      const helpArr = [5, 38, 50, 195, 196, 197, 198, 199, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 214, 215, 216, 217, 218];
      if (helpArr.includes(cat)) {
        return true;
      }
      return false
    },
    tagCategoryHelp(cat) {
      if (this.checkCategoryHelp(cat)) {
        this.isCurrentSlideIsHelp = true;
      }
      else {
        this.isCurrentSlideIsHelp = false;
      }
    },

    // Сменить категорию
    changeCategory(newCategoryId) {

      // Проверка текущего слайда
      // this.changeSwiperIndex();
      // this.isCurrentSlideIsHelp();

      // Смена категории
      this.currentCategory = newCategoryId;
      // Возврат к первому слайду
      this.mySwiper.slideTo(0, 1000, false);

      // Проверка на "Подсказка"
      this.tagCategoryHelp(newCategoryId);

      // Получение новых данных
      // console.log('get6');
      this.getSlides(newCategoryId);

      if (!this.isFilterCategory) {
        localStorage.setItem('category', newCategoryId);
      }
    },
    //endregion


    //region Попапы
    // Показать попап
    showPopup(slide) {
      if (!slide.is_help) {
        // console.log('this.currentSlide', this.currentSlide);
        // console.log('Пролистываю, чтобы избежать бага');
        // this.mySwiper.slideTo(this.currentSlide, 0, false);
        this.setPopupShow = true;
        // текст
        // const refText = this.$refs['slideText' + this.currentSlide];
        // if (refText) {
        //   this.phraseText = refText[0].innerText;
        // }
        if (slide.text) {
          this.phraseText = this.pureText(slide.text);
        }
        // заголовок
        // const refTitle = this.$refs['slideTitle' + this.currentSlide];
        // if (refTitle) {
        //   this.phraseTitle = refTitle[0].innerHTML;
        // }
        if (slide.name) {
          this.phraseTitle = slide.name;
        }

        this.isPhraseResume = this.isResume(slide.category_id);

      }
    },

    popupClose() {
      this.onHidePopup();
    },

    //region Функции из попапа
    // Закрыть попап
    onHidePopup() {
      this.setPopupShow = false
    },

    onClickAdd(data) {
      this.savedCount = data.newItem
    },
    //endregion
    //endregion

    // slideNextTransitionStart() {
    //   // console.log('START', this.$refs.mySwiper.swiper.allowSlideNext);
    //
    // },

    // При смене слайда
    changeSwiperIndex() {
      // console.log('slide REAL', this.$refs.mySwiper.swiper.realIndex);
      // console.log('slide ACTIVE', this.$refs.mySwiper.swiper.activeIndex);
      this.currentSlide = this.$refs.mySwiper.swiper.activeIndex;

      // console.log('change slide', localStorage.getItem('lastSlide'));

      // console.log('this.isFilterCategory1 ', this.isFilterCategory);

      // console.log('par', this.$route.params.isBack);

      if (!this.isFilterCategory) {
        // console.log('this.isFilterCategory 2', this.isFilterCategory);
        localStorage.setItem('lastSlideMusic', this.$refs.mySwiper.swiper.activeIndex);
      }
      // this.currentSlide = this.$refs.mySwiper.swiper.realIndex;
    },

    //region Сохранение
    save() {
      const refSlide = this.$refs['slideMusic' + this.currentSlide];
      const refText = this.$refs['slideTextMusic' + this.currentSlide];

      // Если это не подсказка
      if (!refSlide[0].classList.contains('slide_help')) {
        if (refText) {
          this.saveToLocalStorage(refText[0].innerText);
        }
      }

      // Пролистываю на 1
      this.mySwiper.slideNext()
    },

    saveToLocalStorage(newString, type = 'default') {
      const currentLocalstorage = localStorage.getItem('saved_music');

      if (!currentLocalstorage) {
        let newArr = [];
        newArr.push(newString)
        localStorage.setItem("saved_music", JSON.stringify(newArr));
        localStorage.setItem("saved_count_music", '1');

        if (newString.length && newString != 'null') {
          if (type === 'default') {
            localStorage.setItem("saved_text_music", newString + '\n\n');
          }
        }

      }
      else {
        let currentArr = JSON.parse(localStorage.getItem('saved_music'));
        currentArr.push(newString);
        localStorage.setItem("saved_music", JSON.stringify(currentArr));

        if (newString.length && newString != 'null') {
          if (type === 'default') {
            let currentText = localStorage.getItem('saved_text_music');
            let newText = '';
            if (currentText) {
              newText = currentText + newString + '\n\n';
            }
            else {
              newText = newString + '\n\n';
            }
            localStorage.setItem("saved_text_music", newText);
          }
        }

        // Сохраняем количество фраз
        let currentSavedCount = localStorage.getItem('saved_count_music');
        let newSavedCount = parseInt(currentSavedCount) + 1;
        localStorage.setItem("saved_count_music", newSavedCount.toString());
      }

      // Передаём данные в шапку
      // this.savedCount = JSON.parse(localStorage.getItem('saved')).length;
      this.savedCount = parseInt(localStorage.getItem('saved_count_music'));

    },
    //endregion

    // Переход к следующему слайду
    start() {
      // console.log('start');
    },

    // Открытие меню
    onOpenMenu() {
      this.openMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header__burger-button {
  opacity: 0;
  visibility: hidden;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */ {
  transform: scale(1.3);
  //opacity: 0;
}
</style>