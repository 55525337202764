<template>
  <div class="page">
    <main class="page__content container container_main">
      <h1 class="page__title">Мои описания</h1>

      <div class="message message_empty" v-if="(!isHasJobs && !isHasDates)">
        <p>Здесь отображаются описания, <br>которые Вы создаете для работы <br>и для знакомств.</p>

        <div class="message__links" v-if="!$store.getters.isAuthenticated">
          <p><a href="#" @click="$router.push('/login')">Войти</a></p>
          <p><a href="#" @click="$router.push('/registration')">Зарегистрироваться</a></p>
        </div>
      </div>

      <template v-if="isHasJobs || isHasDates">
        <section class="questionary" v-if="isHasJobs">
          <h2 class="questionary__title"><b>Для работы</b></h2>
          <ul class="questionary__list">
            <SingleJob v-for="item in dataJobs" :key="item.id" v-bind:data="item" v-on:deleteRequest="onDeleteJobClick" />
          </ul>
        </section>

        <br>
        <br>
        <br>

        <section class="questionary" v-if="isHasDates">
          <h2 class="questionary__title"><b>Для знакомств</b></h2>
          <ul class="questionary__list">
            <SingleDate v-for="item in dataDates" :key="item.id"  v-bind:data="item" v-on:deleteRequest="onDeleteDateClick"/>
          </ul>
        </section>

        <div class="popup" v-if="isDeleteJobPopupShow">
          <div class="popup__body">
            <div class="popup__title">Удалить?</div>
            <div class="popup__actions">
              <button class="btn btn_ghost" type="button" @click="deleteJobItem()">Да</button>
              <button class="btn btn_ghost" type="button" @click="isDeleteJobPopupShow = false">Нет</button>
            </div>
          </div>
        </div>

        <div class="popup" v-if="isDeleteDatePopupShow">
          <div class="popup__body">
            <div class="popup__title">Удалить?</div>
            <div class="popup__actions">
              <button class="btn btn_ghost" type="button" @click="deleteDateItem()">Да</button>
              <button class="btn btn_ghost" type="button" @click="isDeleteDatePopupShow = false">Нет</button>
            </div>
          </div>
        </div>

      </template>

    </main>
  </div>
</template>

<script>
import SingleJob from "@/components/saved/SingleJob";
import SingleDate from "@/components/saved/SingleDate";
// import {HTTP} from "@/http-common";
import store from "@/store";
import axios from "axios";
export default {
  name: "About",
  components: {SingleJob, SingleDate},
  data() {
    return {
      dataJobs:[],
      dataDates:[],
      isHasJobs: false,
      isHasDates: false,
      isDeleteJobPopupShow: false,
      isDeleteDatePopupShow: false,
      itemToDelete: null
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {

    loadData() {
      if (localStorage.getItem('token')) {

        //region Jobs
        // HTTP.get('jobs')
        axios({
          url: process.env.VUE_APP_API + 'jobs',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(resp => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('About jobs resp', resp);
              }
              if (resp.status === 200) {
                if (resp.data.total > 0) {
                  this.dataJobs = resp.data.data;
                  this.isHasJobs = true;

                  localStorage.setItem('userSaved', resp.data.total);
                  this.$store.dispatch('setUserSaved', resp.data.total)
                }
              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('err', err);
              }
            })
        //endregion

        //region Dates
        // HTTP.get('dates')
        axios({
          url: process.env.VUE_APP_API + 'dates',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(resp => {

              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('About dates resp', resp);
              }

              if (resp.status === 200) {
                if (resp.data.total > 0) {
                  this.dataDates = resp.data.data;
                  this.isHasDates = true;

                  if (localStorage.getItem('userSaved')) {
                    let newUserSaved = parseInt(localStorage.getItem('userSaved'));
                    newUserSaved = newUserSaved + parseInt(resp.data.total);
                    localStorage.setItem('userSaved', newUserSaved);
                    store.commit('updateUserSaved', newUserSaved)
                  }
                }
              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('err', err);
              }
            })
        //endregion
      }
    },

    //region Удалить работу
    onDeleteJobClick: function(item) {
      this.isDeleteDatePopupShow = false;
      this.isDeleteJobPopupShow = true;
      this.itemToDelete = item;
    },
    deleteJobItem: function() {
      let self = this;
      // HTTP.delete('jobs/' + this.itemToDelete.id)
      axios({
        url: process.env.VUE_APP_API + 'jobs/' + this.itemToDelete.id,
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            // console.log('resp', resp);
            if (resp.status === 200) {
              this.isDeleteJobPopupShow = false;
              this.dataJobs.splice(this.dataJobs.indexOf(this.itemToDelete), 1);
              this.itemToDelete = null;

              // Обновляем "Обо мне" (-1)
              self.$store.commit('updateUserSavedMinus1');

            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })
    },
    //endregion

    //region Удалить свидание
    onDeleteDateClick: function(item) {
      this.isDeleteJobPopupShow = false;
      this.isDeleteDatePopupShow = true;
      this.itemToDelete = item;
    },
    deleteDateItem: function() {
      // HTTP.delete('dates/' + this.itemToDelete.id)
      axios({
        url: process.env.VUE_APP_API + 'dates/' + this.itemToDelete.id,
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            // console.log('resp', resp);
            if (resp.status === 200) {
              this.isDeleteDatePopupShow = false;
              this.dataDates.splice(this.dataDates.indexOf(this.itemToDelete), 1);
              this.itemToDelete = null;
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })
    }
    //endregion
  }
}
</script>

<style scoped lang="scss">
.popup__actions .btn {
  margin-left: 10px;
  margin-right: 10px;
}
.page {
  background-color: #f1f1f2;
  min-height: 100vh;
}
.page>.container_main {
  height: auto;
}
</style>