<template>
  <div class="phrase-full" :class="{'js-active' : show}">
    <div class="container">
      <div class="phrase-full__title" @click="focusTextarea">{{ phraseTitle }}</div>
      <textarea-autosize
          class="phrase-full__text"
          placeholder="Введите что-нибудь"
          ref="myTextarea"
          v-model="phraseTextLocal"
          :min-height="30"
      />
      <div class="phrase-full__add btn btn_bg-dark" @click="save">Добавить</div>
      <div class="phrase-full__close" @click="hidePopup"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhrasePopupMusic",
  props: [
    'phraseTitle',
    'phraseText',
    'isPhraseResume',
    'show'
  ],
  watch: {
    phraseText() {
      if (this.show) {
        this.phraseTextLocal = this.phraseText;
        this.isPhraseResumeLocal = this.isPhraseResume;
        this.focusTextarea();
      }
    },
    show() {
      if (this.show) {
        this.phraseTextLocal = this.phraseText;
        this.isPhraseResumeLocal = this.isPhraseResume;
        this.focusTextarea();
      }
    }
  },
  data() {
    return {
      textareaHeight: 100,
      savedCount: 0,
      phraseTextLocal: this.phraseText,
      isPhraseResumeLocal: false,
    }
  },
  mounted() {
    this.phraseTextLocal = this.phraseText;
    this.isPhraseResumeLocal = this.isPhraseResume;
    this.focusTextarea();
  },
  methods: {

    focusTextarea() {
      this.$refs.myTextarea.$el.focus()
      setTimeout(() => this.$refs.myTextarea.$el.focus(), 300);
    },

    //region Сохранение
    save() {
      if (this.phraseTextLocal.length) {
        if (this.isPhraseResumeLocal) {
          this.saveToLocalStorage(this.phraseTextLocal, 'resume');
        }
        else {
          this.saveToLocalStorage(this.phraseTextLocal);
        }
      }
      else {
        // Закрываем попап
        this.hidePopup()
      }
    },

    saveToLocalStorage(newString) {
      const currentLocalstorage = localStorage.getItem('saved_music');

      if (!currentLocalstorage) {
        let newArr = [];
        newArr.push(newString)
        localStorage.setItem("saved_music", JSON.stringify(newArr));
        localStorage.setItem("saved_count_music", '1');

        // localStorage.setItem("saved_text", newString);

        if (newString.length && newString != 'null') {
          localStorage.setItem("saved_text_music", newString + '\n\n');
        }


      }
      else {

        let currentArr = JSON.parse(localStorage.getItem('saved_music'));
        currentArr.push(newString);
        localStorage.setItem("saved_music", JSON.stringify(currentArr));

        // let currentText = localStorage.getItem('saved_text');
        // let newText = currentText + '\n\n' + newString;
        // localStorage.setItem("saved_text", newText);

        // console.log('newString', newString);

        if (newString.length && newString != 'null') {
            let currentText = localStorage.getItem('saved_text_music');
            let newText = '';
            if (currentText) {
              newText = currentText + newString + '\n\n';
            } else {
              newText = newString + '\n\n';
            }

            localStorage.setItem("saved_text_music", newText);
        }

        // Сохраняем количество фраз
        let currentSavedCount = localStorage.getItem('saved_count_music');
        let newSavedCount = parseInt(currentSavedCount) + 1;
        localStorage.setItem("saved_count_music", newSavedCount.toString());
      }

      // Передаём данные в шапку
      // this.savedCount = JSON.parse(localStorage.getItem('saved')).length;
      this.savedCount = parseInt(localStorage.getItem('saved_count_music'));

      // Закрываем
      this.hidePopup()

      this.clickAdd(this.savedCount);

    },
    //endregion


    // Закрыть попап
    hidePopup() {
      this.$emit('closePopup')
    },

    // Нажато на "добавить", обновляем количество сохранённых в шапке
    clickAdd(num) {
      this.$emit('clickAdd', {
        newItem: num
      })
    }
  }
}
</script>

<style scoped>

</style>