<template>
  <div class="phrase-full" :class="{'js-active' : show}">
    <div class="container">
      <div class="phrase-full__title" @click="focusTextarea">{{ phraseTitle }}</div>
      <textarea-autosize
          class="phrase-full__text"
          placeholder="Введите что-нибудь"
          ref="myTextarea"
          v-model="phraseTextLocal"
          :min-height="30"
      />
      <div class="phrase-full__add btn btn_bg-dark" @click="save">Добавить</div>
      <div class="phrase-full__close" @click="hidePopup"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PromoPhrasePopup",
  props: [
    'phraseTitle',
    'phraseText',
    'show'
  ],
  watch: {
    phraseText() {
      if (this.show) {
        this.phraseTextLocal = this.phraseText;
        this.focusTextarea();
      }
    },
    show() {
      if (this.show) {
        this.phraseTextLocal = this.phraseText;
        this.focusTextarea();
      }
    }
  },
  data() {
    return {
      textareaHeight: 100,
      savedCount: 0,
      phraseTextLocal: this.phraseText,
    }
  },
  mounted() {
    this.phraseTextLocal = this.phraseText;

    this.focusTextarea();
  },
  methods: {

    focusTextarea() {
      this.$refs.myTextarea.$el.focus()
      setTimeout(() => this.$refs.myTextarea.$el.focus(), 300);
    },

    //region Сохранение
    save() {
      if (this.phraseTextLocal.length) {
        this.saveToLocalStorage(this.phraseTextLocal);
      }
      else {
        // Закрываем попап
        this.hidePopup()
      }
    },

    saveToLocalStorage(newString) {
      const currentLocalstorage = localStorage.getItem('promo_saved');

      if (!currentLocalstorage) {
        let newArr = [];
        newArr.push(newString)
        localStorage.setItem("promo_saved", JSON.stringify(newArr));
        localStorage.setItem("promo_saved_count", '1');

        localStorage.setItem("promo_saved_text", newString);
      }
      else {
        let currentArr = JSON.parse(localStorage.getItem('promo_saved'));
        currentArr.push(newString);
        localStorage.setItem("promo_saved", JSON.stringify(currentArr));

        let currentText = localStorage.getItem('promo_saved_text');
        let newText = currentText + '\n\n' + newString;
        localStorage.setItem("promo_saved_text", newText);

        // Сохраняем количество фраз
        let currentSavedCount = localStorage.getItem('promo_saved_count');
        let newSavedCount = parseInt(currentSavedCount) + 1;
        localStorage.setItem("promo_saved_count", newSavedCount.toString());
      }

      // Передаём данные в шапку
      // this.savedCount = JSON.parse(localStorage.getItem('saved')).length;
      this.savedCount = parseInt(localStorage.getItem('promo_saved_count'));

      // Закрываем
      this.hidePopup()

      this.clickAdd(this.savedCount);

    },
    //endregion


    // Закрыть попап
    hidePopup() {
      this.$emit('closePopup')
    },

    // Нажато на "добавить", обновляем количество сохранённых в шапке
    clickAdd(num) {
      this.$emit('clickAdd', {
        newItem: num
      })
    }
  }
}
</script>

<style scoped>

</style>