<template>
  <div>
    <div class="phrases">
      <div class="phrases__container">
        <div class="phrases__list">
          <div class="swiper-container"
               ref='mySwiper'
               v-swiper:mySwiper="swiperOptions"
               @slideChange="changeSwiperIndex"
               :auto-update="true"
               @click="showPopup"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class="'slide' + slide.id"
                   :ref="'slide' + slide.id"
                   :key="index"
                   v-for="(slide, index) in slides"
              >
                <div class="phrases__item">
                  <div class="phrases__title" :ref="'slideTitle' + index">{{ slide.title }}</div>
                  <div class="phrases__text" :ref="'slideText' + index" v-html="slide.text"></div>
                  <div class="phrases__pro" v-if="slide.isPro">PRO</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="phrases__dots phrases__dots_promo swiper-pagination swiper-pagination-bullets"></div>
        <div class="phrases__add btn btn_bg-dark" @click="save">Добавить</div>
      </div>
    </div>
    <promo-phrase-popup @closePopup="onHidePopup" @clickAdd="onClickAdd" :show="setPopupShow" :phraseTitle="phraseTitle" :phraseText="phraseText"/>
  </div>
</template>

<script>
import {directive} from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

import popupCloseMixin from '@/mixins/popupClose'
import PromoPhrasePopup from "@/components/promo/PromoPhrasePopup";

export default {
  name: "PromoPhrases",
  mixins: [popupCloseMixin],
  directives: {
    swiper: directive
  },
  components: {
    PromoPhrasePopup
  },
  data() {
    // var self = this;
    return {
      //region Slider
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: 1,
        on: {},

      },
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      setPopupShow: false,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 1,
      isProCategory: false,
      isDataLoaded: false,
      dynamicValue: false,
      openMenu: false,

      slides: [
        {
          'title': 'Альтернатива',
          'text': 'Всё то, что люди называют дерзким, неформатным, возможно, странным и вообще непонятным, мне крайне импонирует.'
        },
        {
          'title': '+1',
          'text': 'Видимо поэтому я без ума от <span>Сусумы Хирасавы</span>. <span>Его</span> музыку поймет далеко не каждый. Абсолютный шедевр!'
        },
        {
          'title': 'Классическая',
          'text': 'Поклонник классической музыки и особенно <span>Антонио Вивальди</span>. Лично мне она помогает <span>сконцентрироваться</span>.'
        },
        {
          'title': 'Когда грустно',
          'text': 'Если на душе тоскливо, включаю <span>Something Beautiful</span> в исполнении <span>Mat Kearney</span>. И делаю погромче.\n'
        },
        {
          'title': 'Слушаю всё подряд',
          'text': 'Меня постоянно окружает музыка. Сейчас она всё больше кроссжанровая, поэтому я слушаю всё. Обожаю <span>Lana Del Rei</span>.'
        },
        {
          'title': '+1',
          'text': 'Мне важен не столько жанр, сколько аранжировка – меня цепляет то, как умело в композиции сочетаются инструменты, мелодия и стиль.'
        },
        {
          'title': 'Сочиняю',
          'text': 'Влюблен в музыку настолько, что сочиняю сам.'
        },
        {
          'title': 'Рок',
          'text': 'Часто слушаю <span>Rock’N’Roll</span>. Когда-то я даже собирался отрастить длинные волосы. Но мне сразу сказали – тебе не пойдёт )'
        },
        {
          'title': 'Любимая группа',
          'text': 'Поклонник <span>хард-рока и немного хеви-метал</span>. И я из тех, кто обожает <span>Металлику</span>. <span>Master of Puppets</span> – пожалуй, их лучший альбом.'
        },
        {
          'title': '+1',
          'text': 'Раньше постер с любимой группой вешали на стену, теперь у нас есть Instagram )'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {
    //region Попапы
    // Показать попап
    showPopup() {
      // console.log('this.currentSlide', this.currentSlide);
      // console.log('Пролистываю, чтобы избежать бага');
      // this.mySwiper.slideTo(this.currentSlide, 0, false);
      this.setPopupShow = true;
      // текст
      const refText = this.$refs['slideText' + this.currentSlide];
      if (refText) {
        this.phraseText = refText[0].innerText;
      }
      // заголовок
      const refTitle = this.$refs['slideTitle' + this.currentSlide];
      if (refTitle) {
        this.phraseTitle = refTitle[0].innerHTML;
      }
    },

    popupClose() {
      this.onHidePopup();
    },

    //region Функции из попапа
    // Закрыть попап
    onHidePopup() {
      this.setPopupShow = false
    },

    onClickAdd(data) {
      // this.savedCount = data.newItem

      this.$emit('savedCount', {
        // count: parseInt(localStorage.getItem('promo_saved_count'))
        count: data.newItem
      })
    },
    //endregion
    //endregion

    // slideNextTransitionStart() {
    //   console.log('START', this.$refs.mySwiper.swiper.allowSlideNext);
    //
    // },

    // При смене слайда
    changeSwiperIndex() {
      // console.log('slide REAL', this.$refs.mySwiper.swiper.realIndex);
      // console.log('slide ACTIVE', this.$refs.mySwiper.swiper.activeIndex);
      this.currentSlide = this.$refs.mySwiper.swiper.activeIndex;

      localStorage.setItem('promoLastSlide', this.$refs.mySwiper.swiper.activeIndex);
      // this.currentSlide = this.$refs.mySwiper.swiper.realIndex;
    },

    //region Сохранение
    save() {
      const refText = this.$refs['slideText' + this.currentSlide];
      if (refText) {
        this.saveToLocalStorage(refText[0].innerText);
      }
      // Пролистываю на 1
      this.mySwiper.slideNext()



    },

    saveToLocalStorage(newString) {
      const currentLocalstorage = localStorage.getItem('promo_saved');

      if (!currentLocalstorage) {
        console.log('if');
        let newArr = [];
        newArr.push(newString)
        localStorage.setItem("promo_saved", JSON.stringify(newArr));
        localStorage.setItem("promo_saved_count", '1');

        localStorage.setItem("promo_saved_text", newString);
      }
      else {

        console.log('else');

        let currentArr = JSON.parse(localStorage.getItem('promo_saved'));
        currentArr.push(newString);
        localStorage.setItem("promo_saved", JSON.stringify(currentArr));

        let currentText = localStorage.getItem('promo_saved_text');
        let newText = currentText + '\n\n' + newString;
        localStorage.setItem("promo_saved_text", newText);

        // Сохраняем количество фраз
        let currentSavedCount = localStorage.getItem('promo_saved_count');
        let newSavedCount = parseInt(currentSavedCount) + 1;
        localStorage.setItem("promo_saved_count", newSavedCount.toString());
      }

      // Передаём данные в шапку
      // this.savedCount = JSON.parse(localStorage.getItem('saved')).length;
      // this.savedCount = parseInt(localStorage.getItem('promo_saved_count'));

      this.$emit('savedCount', {
        count: parseInt(localStorage.getItem('promo_saved_count'))
      })


    },
    //endregion


  }
}
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
.phrases__dots.phrases__dots_promo {
  .swiper-pagination-bullet {

    @media only screen and (max-width: 388px) {
      margin-left: 12px;
      margin-right: 12px;
    }
    @media only screen and (max-width: 340px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    &.swiper-pagination-bullet-active {
      background-color: #333;
    }
  }
}
</style>