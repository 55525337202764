<template>
  <div>

    <Menu :savedCount="savedCount" :isNeedOpen="openMenu" @openMenu="onOpenMenu" @filterSelected="onFilterSelected"/>


    <div class="full-page full-page_grey full-page_flex-column full-page_flex-column_phrases full-page_wrap">

      <div class="container full-page__phrases">

        <div class="phrases-start phrases-title" v-if="categoryName && !isFilterCategory">{{ categoryName }}</div>

        <div class="full-page__filters phrases-category" v-if="isConnection && !isFilterCategory">
          <v-select
              @input="setSelected"
              v-model="connectionModel"
              :options="connectionOptions"
              :clearable="false"
              :searchable="false"
              item-value="category_id"
              placeholder="Выберите вариант"
          >
          </v-select>
        </div>

        <div class="phrases-start full-page__filters phrases-category" v-if="isFilterCategory">
          <div class="phrases-category__name" @click="removeFilter">{{ filterCategoryTitle }}
            <div class="phrases-category__close"></div>
          </div>
        </div>

        <div class="phrases">
          <div class="phrases__menu"
               v-if="phrasesCategories.length > 1"
          >
            <div class="phrases__menu-item"
                 v-for="category in phrasesCategories"
                 v-bind:key="category.id"
                 @click="changeCategory(category.id)"
                 :class="{'phrases__menu-item_active': currentCategory === category.id}"
            >
              {{ category.name }}
            </div>
          </div>
          <div class="phrases__container">
            <div class="phrases__list">
              <div class="lds-ring" v-if="!isDataLoaded">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="swiper-container"
                   ref='mySwiper'
                   v-swiper:mySwiper="swiperOptions"
                   @slideChange="changeSwiperIndex"
                   @slideNextTransitionStart="onSlideNextTransitionStart"
                   @slidePrevTransitionStart="onSlidePrevTransitionStart"
                   @reachEnd="onReachEnd"
                   @touchEnd="onTouchEnd"
                   @touchStart="onTouchStart"
                   :auto-update="true"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide" :class="['slide' + slide.id, {'slide_help': slide.is_help, 'slide_resume': isResume(slide.category_id)}]"
                       :ref="'slide' + index"
                       :key="slide.title"
                       v-for="(slide, index) in slides"
                       @click="showPopup(slide)"
                  >
                    <div class="phrases__item" :class="{'phrases__item_special': isSlideSpecial(slide)}">
                      <div class="phrases__light" v-if="slide.is_help"></div>
                      <div class="phrases__title" :ref="'slideTitle' + index">{{ slide.name }}</div>
                      <div class="phrases__text" :ref="'slideText' + index" v-html="slide.text"></div>
                      <div class="btn btn_phrases" @click="changeCategory(slide.next_category)" v-if="slide.next_category">Начать</div>
                      <div class="phrases__pro" v-if="slide.isPro">PRO</div>
                      <div class="phrases__text-additional" v-if="isSlideSpecial(slide)">
                        <div class="phrases__icon-filter"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="phrases__dots swiper-pagination swiper-pagination-bullets" :class="{'phrases__dots_flex': isSlidesCountMass}"></div>
            <div class="phrases__add btn btn_bg-dark" @click="save" v-if="!isCurrentSlideIsHelp">Добавить</div>
            <!--<div class="phrases__add btn btn_bg-light-grey" @click="start" v-if="isCurrentSlideIsHelp">Начать</div>-->
          </div>
        </div>

      </div>
    </div>

    <phrase-popup @closePopup="onHidePopup" @clickAdd="onClickAdd" :show="setPopupShow" :phraseTitle="phraseTitle" :phraseText="phraseText" :isPhraseResume="isPhraseResume"/>

    <footer-front/>

  </div>
</template>

<script>
import Menu from "@/components/Menu";

import {directive} from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import PhrasePopup from "@/components/PhrasePopup";

import popupCloseMixin from '@/mixins/popupClose'
import axios from "axios";

//region Красивые селекты
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import FooterFront from "@/components/FooterFront";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({});
//endregion

export default {
  name: "Phrases",
  mixins: [popupCloseMixin],
  directives: {
    swiper: directive
  },
  components: {
    FooterFront,
    Menu,
    PhrasePopup
  },
  data() {
    // var self = this;
    return {
      //region Slider
      slides: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: 1,
        // onSlideChangeEnd(() => { this.onSwipe(); }),
        on: {
          // touchStart: (swiper, event) => {
          //   // console.log('ok swiper', swiper);
          //   // console.log('ok event', event);
          //   // self.onSwipe()
          // },

          slideNextTransitionEnd: () => {
            // console.log('ok');
          }
        },

        // loop: true,
        // loopedSlides: 0,
        // loopAdditionalSlides: 0,
        // loopFillGroupWithBlank: true,
        // loopedSlides: 0,
        // on: {
        //   init: function () {
        //     // console.log('this.$refs', self.$refs);
        //     // console.log('ok', self.$refs.mySwiper.swiper.allowSlideNext);
        //     // console.log('prev', self.$refs.mySwiper.swiper.allowSlidePrev);
        //     // self.swiperInit
        //     // self.$refs.mySwiper.swiper.slideTo(1,1000)
        //     // console.log('self.$refs.mySwiper.swiper', self.$refs.mySwiper.swiper.$el);
        //     self.$refs.mySwiper.swiper.slideTo(1, 1000, false)
        //     const totalSlidesLen = self.$refs.mySwiper.swiper.slides.length;
        //     // console.log('totalSlidesLen', totalSlidesLen);
        //   }
        // }
      },
      isSlideLast: false,
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      setPopupShow: false,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 1,
      isFilterCategory: false,
      filterCategoryTitle: '',
      isDataLoaded: false,
      dynamicValue: false,
      openMenu: false,
      isSlidesCountMass: false,
      isCurrentSlideIsHelp: false,
      // Знакомство
      isConnection: false,
      connectionModel: null,
      categoryName: null,
      isPhraseResume: false,
      connectionOptions: [
        {
          label: 'Вариант 1',
          category_id: 80
        },
        {
          label: 'Вариант 2',
          category_id: 81
        },
        {
          label: 'Вариант 3',
          category_id: 82
        },
        {
          label: 'Вариант 4',
          category_id: 83
        },
        {
          label: 'Вариант 5',
          category_id: 84
        }
      ]
    }
  },
  watch: {
    '$route'(to) {
      // console.log('to', to);
      if (to.query && to.query.filter_id) {
        // console.log('to', to);
        // console.log('from', from);
        // console.log('get cat 1');
        this.getCategories(to.query.filter_id);
        // this.siteData = this.getSiteData();
      }
          // else if (to.params.isBack && localStorage.getItem('category')) {
          //   // console.log('get cat BACK');
          //   this.getCategories(localStorage.getItem('category'));
      // }
      else if (to.params && to.params.type && to.params.type === "portfolio" && to.params.ofu) {
        // console.log('get cat 2');
        this.getCategories(to.params.ofu);
      }
      else if (to.params && to.params.type && to.params.type === "connection" && to.params.ofu) {
        // console.log('get cat 3');
        this.getCategories(to.params.ofu);
      }
      else {
        // console.log('to.params.type', to.params.type);
        // console.log('get cat 4');
        this.getCategories(this.getIdCategory(to.params.type))
        this.getCategoryName(to.params.type);
      }
    },
  },
  mounted() {

    localStorage.setItem('lastPage', this.$route.name)

    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)

    // Категории
    // this.getData();

    if (this.$route.path === '/phrases') {
      this.$router.push({name: 'want'})
    }

    // console.log('this.$route', this.$route);
    // console.log('this.$route.params.type', this.$route.params.type);

    // Определение раздела
    if (this.$route.params.type) {
      // Сохранение, в каком разделе мы находились
      localStorage.setItem('category_type', this.$route.params.type);

      this.getCategoryName(this.$route.params.type);

      // Сохранение выбранного типа
      if (!localStorage.getItem('saved_text') && !localStorage.getItem('saved_resume_text')) {
        localStorage.setItem('saved_type', this.getCategoryName(this.$route.params.type));
      }

      // Если это знакомства
      if (this.$route.params.type === 'connection') {
        this.isConnection = true;
        // Устанавливаем пункт по умолчанию (очень криво, спешу)
        if (this.$route.params.ofu) {
          this.connectionModel = this.connectionOptions[this.$route.params.ofu - 80];
        }
        else {
          this.connectionModel = this.connectionOptions[0];
        }
      }
    }

    if (this.$route.query && this.$route.query.filter_id) {
      // console.log('FILTER');
      this.isFilterCategory = true;
      // console.log('get cat 4');
      this.getCategories(this.$route.query.filter_id);
      // this.getCategories(44);
    }
    else if (this.$route.params.isBack && localStorage.getItem('category_type')) {
      // console.log('BACK1');
      const categoryType = localStorage.getItem('category_type');
      // console.log('get cat 5');
      this.getCategories(this.getIdCategory(categoryType));
    }
    else if (this.$route.params.isBack && !this.$route.params.type) {
      // console.log('BACK2');
      // console.log('get cat 6');
      this.getCategories(this.getIdCategory('resume'));
    }
    else if (this.$route.params && this.$route.params.type === "portfolio" && this.$route.params.ofu) {
      // console.log('portfolio');
      // console.log('get cat 7');
      this.getCategories(this.$route.params.ofu);
    }
    else if (this.$route.params && this.$route.params.type === "connection" && this.$route.params.ofu) {
      // console.log('connection');
      // console.log('get cat 8');
      this.getCategories(this.$route.params.ofu);
    }
    else {
      // console.log('else');
      // console.log('get cat 9');
      this.getCategories(this.getIdCategory(this.$route.params.type))
      this.getCategoryName(this.$route.params.type);
      // if (this.$route.params.type === 'resume') {
      //   this.getCategories(2);
      // } else if (this.$route.params.type === 'letter') {
      //   this.getCategories(26);
      // } else if (this.$route.params.type === 'portfolio') {
      //   // Frontend
      //   this.getCategories(107);
      // } else if (this.$route.params.type === 'crib') {
      //   this.getCategories(37);
      // } else if (this.$route.params.type === 'socials') {
      //   this.getCategories(46);
      // } else if (this.$route.params.type === 'connection') {
      //   // Знакомство вариант 1
      //   this.getCategories(80);
      // }

      if (this.$route.params.id_ofu) {
        // console.log('if id_ofu');
        // console.log('get cat 10');
        this.getCategories(this.$route.params.id_ofu);
      }
      // else {
      //   this.getCategories(2);
      // }
    }

    // this.getSlides();

    if (this.$route.params.openMenu) {
      this.openMenu = true
    }

  },
  computed: {},
  methods: {
    // Когда в меню с фильтром нажали на категорию
    onFilterSelected() {
      if (this.$route.query && this.$route.query.filter_id) {
        this.mySwiper.slideTo(0, 1000, false);
      }
    },
    // Чистый текст без кода
    pureText(str) {
      str = str.replace(/<\/?span[^>]*>/g, "");
      return str.replace(/<\/?br[^>]*>/g, "\n");
    },
    //region Выпадающее поле в "Знакомства"
    setSelected(value) {
      if (value.category_id) {
        this.$router.push({name: 'phrases_ofu', params: {type: 'connection', ofu: value.category_id}})
        // console.log('get cat 11');
        this.getCategories(value.category_id);
      }
      else {
        this.$router.push({name: 'phrases_ofu', params: {type: 'connection'}})
        // console.log('get cat 12');
        this.getCategories(80);
      }
    },
    //endregion

    // Если это слайд "Смотрите больше фраз в иконке "Фильтры"
    isSlideSpecial(slide) {
      if (slide.is_help === 1 && (slide.text === 'Больше фраз –<br>в иконке «Фильтры».')) {
        return true
      }
      return false
    },
    // Если резюме
    isResume(category_id) {
      if (category_id) {
        // console.log('category_id', category_id);
        const arrResume = [6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 3];
        return arrResume.includes(category_id)
      }
      return false
    },
    //Получение id категории по названию
    getIdCategory(name) {
      if (name === 'resume') {
        return 2
      }
      else if (name === 'letter') {
        // this.getCategories(26);
        return 26
      }
      else if (name === 'portfolio') {
        // Frontend
        // this.getCategories(107);
        return 107
      }
      else if (name === 'crib') {
        // this.getCategories(37);
        return 37
      }
      else if (name === 'socials') {
        // this.getCategories(46);
        return 46
      }
      else if (name === 'connection') {
        // Знакомство вариант 1
        // this.getCategories(80);
        return 80
      }
    },

    getCategoryName(name) {
      if (name === 'resume') {
        this.categoryName = "Резюме"
      }
      else if (name === 'letter') {
        this.categoryName = "Письмо"
      }
      else if (name === 'portfolio') {
        this.categoryName = "Портфолио"
      }
      else if (name === 'crib') {
        this.categoryName = "Шпаргалка"
      }
      else if (name === 'socials') {
        this.categoryName = "Социальные сети"
      }
      else if (name === 'connection') {
        this.categoryName = "Знакомства"
      }
      return this.categoryName;
    },

    //region Слайдер
    onReachEnd() {

    },

    changeCategoryOnLastSlide() {
      // console.log('ELSE');
      if (this.isSlideLast) {
        // console.log('IF');
        // console.log('last slide');
        // console.log('categories', this.phrasesCategories);
        // console.log('current', this.currentCategory);

        const arrLengthIndexes = this.phrasesCategories.length - 1;
        // console.log('arrLengthIndexes', arrLengthIndexes);
        const currentCategoryIndex = this.phrasesCategories.findIndex(x => x.id === this.currentCategory);
        let nextCategoryIndex = currentCategoryIndex + 1;

        // console.log('nextCategoryIndex', nextCategoryIndex);
        if (nextCategoryIndex <= arrLengthIndexes) {
          // console.log('try');
          const nextCategory = this.phrasesCategories[nextCategoryIndex].id;
          this.currentCategory = nextCategory;
          this.changeCategory(nextCategory);
        }
        // console.log('find index', currentCategoryIndex);
        // console.log('this.phrasesCategories', this.phrasesCategories);
      }
    },

    onSlideNextTransitionStart() {
      // console.log('onSlideNextTransitionStart');
      // alert('change');
      this.isSlideLast = false;
    },

    onSlidePrevTransitionStart() {
      // console.log('onSlidePrevTransitionStart');
      // alert('change');
      this.isSlideLast = false;
    },

    onSlideChangeTransitionStart() {
      this.isSlideLast = false;
      // console.log('onSlideChangeTransitionStart');
    },

    sliderMove() {
      // console.log('sliderMove');
    },

    toEdge() {
      // console.log('toEdge');
    },

    sliderFirstMove() {
      // console.log('sliderFirstMove');
    },

    onSwipe() {
      // console.log('Swiped')
    },
    onTouchStart() {
      // console.log('onTouchStart');
      this.isSlideLast = false;
      // console.log('swiper', swiper);
      // console.log('e', e);
      // console.log('e', e);
      // if (e && e.type === 'touchstart') {
      //   this.swiperTouchStartX = e.touches[0].clientX;
      // } else {
      //   this.swiperTouchStartX = e.clientX;
      // }
    },

    // todo: почти получилось менять категорию
    onTouchEnd() {
      // console.log('onTouchEnd');

      // alert('ok');
      this.isSlideLast = true;
      // console.log('onReachEnd');

      // if (this.isSlideLast) {
      //   setTimeout(() => {
      //     this.changeCategoryOnLastSlide();
      //   }, 200);
      // }
      // console.log('onTouchEnd', swiper, e);

      // console.log('this.$refs.slide2', this.$refs.slide2.getBoundingClientRect());

      // const offset = this.$refs.mySwiper.getBoundingClientRect(); // or `this.$refs.firstOneRef`
      // const offset = this.$refs.slide2.getBoundingClientRect(); // or `this.$refs.firstOneRef`
      // const { left, width } = offset;
      // if (left > 0 && left + width <= window.innerWidth) {
      //   // The last one show, do what you want to do
      //   // console.log('touchend1');
      // } else {
      //   // The last one leave, stop the last one things
      //   // console.log('touchend2');
      // }
    },


    // При инициации слайдера
    swiperInit() {
      // this.totalSlides = this.mySwiper.slides.length
    },
    //endregion

    removeFilter() {
      this.isFilterCategory = false;
      this.$router.push({query: {}, params: {isBack: true}});

      // console.log('close filters');

      if (localStorage.getItem('category')) {
        // console.log('go!', localStorage.getItem('category'));

        // Сохраняем, какой был последний слайд, если передаём его в параметре
        if (this.$route.params && this.$route.params.lastSlide) {
          // console.log('ELSE LAST');
          localStorage.setItem('lastSlide', this.$route.params.lastSlide);
        }

        const categoryId = parseInt(localStorage.getItem('category'));
        this.currentCategory = categoryId;

        // console.log('get1');
        this.getSlides(categoryId);
      }

    },

    //region Данные
    getCategories(cat_id) {
      let category_id = parseInt(cat_id);

      // console.log('cat_id', cat_id);
      // console.log('category_id typeof', typeof category_id);

      // let category_id = cat_id;
      // console.log('category_id', category_id);
      //region Jobs
      // HTTP.get('jobs')
      let sex = 2
      if (this.$route.params && this.$route.params.type && this.$route.params.type === 'connection' && !this.isFilterCategory) {
        sex = this.getSex();
      }
      if (this.$route.query && this.$route.query.filter_id) {
        sex = 2
      }

      axios({
        url: process.env.VUE_APP_API + 'phrases-categories/' + category_id + '/' + sex,
        method: 'GET',
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('phrases-categories', resp);
            }
            if (resp.status === 200 && resp.data) {

              this.phrasesCategories = resp.data['list'];

              // console.log('resp.data[\'list\']', resp.data['list'].length);

              // Если есть список категорий, сразу открываем первую из списка. Иначе единственные
              if (resp.data['list'].length) {
                if (this.$route.params.isBack && localStorage.getItem('category')) {
                  const categoryId = parseInt(localStorage.getItem('category'));
                  this.currentCategory = categoryId;
                  // console.log('get2');
                  this.getSlides(categoryId);
                }
                else {
                  this.currentCategory = resp.data['list'][0].id;
                  // console.log('get3');
                  this.getSlides(resp.data['list'][0].id);
                }

              }
              else {
                this.currentCategory = category_id;
                // console.log('category_id', category_id);
                // console.log('get4');
                this.getSlides(category_id);
              }

              // Если это фильтр
              if (resp.data['category'] && resp.data['category'].is_filter) {
                this.isFilterCategory = true;
                this.filterCategoryTitle = resp.data['category'].name;
              }
              else {
                this.isFilterCategory = false;
                this.filterCategoryTitle = '';
              }

              // todo: Почему это здесь, а не в mounted?
              // console.log('this.$route.params.isBack', this.$route.params.isBack);
              // if (this.$route.params.isBack && localStorage.getItem('category')) {
              //   const categoryId = parseInt(localStorage.getItem('category'));
              //   this.currentCategory = categoryId;
              //   // console.log('get3');
              //   this.getSlides(categoryId);
              // } else {
              //   if (resp.data['list'] && resp.data['category']) {
              //     // this.currentCategory = resp.data['list'][0].id;
              //     // this.getSlides(resp.data['category'].id);
              //     let category_id = resp.data['category'].id;
              //     this.currentCategory = category_id;
              //     // console.log('get4');
              //     this.getSlides(category_id);
              //
              //     if (resp.data['category'].is_filter) {
              //       this.isFilterCategory = true;
              //       this.filterCategoryTitle = resp.data['category'].name;
              //     } else {
              //       this.isFilterCategory = false;
              //       this.filterCategoryTitle = '';
              //     }
              //
              //   } else {
              //     this.currentCategory = category_id;
              //     // console.log('get5');
              //     this.getSlides(category_id);
              //   }
              // }
            }
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })
      //endregion
    },

    getSex() {
      let sex = localStorage.getItem('sex');
      if (sex) {
        if (sex === 'man') {
          return 1;
        }
        return 0;
      }
      return 1;
    },

    getSlides(categoryId) {

      categoryId = parseInt(categoryId);

      // Проверка на "Подсказка"
      this.tagCategoryHelp(categoryId);

      const sex = this.getSex();

      axios({
        url: process.env.VUE_APP_API + 'phrases/' + sex + '/' + categoryId,
        method: 'GET',
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('phrases/{category_id}', resp);
            }
            if (resp.status === 200 && resp.data) {
              //   this.phrasesCategories = resp.data;
              //   this.currentCategory = resp.data[0].id;
              //   this.getSlides(resp.data[0].id);
              this.slides = resp.data;

              // Пролистываю до последнего слайда
              const lastSlide = localStorage.getItem('lastSlide');
              // console.log('lastSlide', lastSlide);
              if (this.$route.params.isBack && lastSlide) {
                // console.log('lastSlide2', lastSlide);
                this.mySwiper.slideTo(parseInt(lastSlide), 1000, false);
              }
              else {
                this.$refs.mySwiper.swiper.slideTo(0, 1000)
              }

              this.isDataLoaded = true;

              this.isSlidesCountMass = false;

              if (resp.data.length > 9) {
                this.isSlidesCountMass = true;
              }

              // Переинициация опций свайпера
              // setTimeout(() => this.swiperInit(), 400);
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })

      // let slides = [];
      // let randomBoolean = index => index % 2
      // Array.from({length: 7}).map(
      //     (el, index) => {
      //       slides[index] = {
      //         title: `Работа с информацией ${index + 1}`,
      //         text: `Получаю удовольствие от работы с информацией. Мне нравится процесс анализа и систематизации.
      //        Инстинктивно стремлюсь к тому, чтобы конвертировать разрозненные данные в четкие, аргументированные смыслы. ${index + 1}`,
      //         isPro: randomBoolean(index)
      //       };
      //     }
      // );
      // this.slides = slides;
    },

    checkCategoryHelp(cat) {
      const helpArr = [5, 38, 50, 195, 196, 197, 198, 199, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 214, 215, 216, 217, 218];
      if (helpArr.includes(cat)) {
        return true;
      }
      return false
    },
    tagCategoryHelp(cat) {
      if (this.checkCategoryHelp(cat)) {
        this.isCurrentSlideIsHelp = true;
      }
      else {
        this.isCurrentSlideIsHelp = false;
      }
    },

    // Сменить категорию
    changeCategory(newCategoryId) {

      // Проверка текущего слайда
      // this.changeSwiperIndex();
      // this.isCurrentSlideIsHelp();

      // Смена категории
      this.currentCategory = newCategoryId;
      // Возврат к первому слайду
      this.mySwiper.slideTo(0, 1000, false);

      // Проверка на "Подсказка"
      this.tagCategoryHelp(newCategoryId);

      // Получение новых данных
      // console.log('get6');
      this.getSlides(newCategoryId);

      if (!this.isFilterCategory) {
        localStorage.setItem('category', newCategoryId);
      }
    },
    //endregion


    //region Попапы
    // Показать попап
    showPopup(slide) {
      if (!slide.is_help) {
        // console.log('this.currentSlide', this.currentSlide);
        // console.log('Пролистываю, чтобы избежать бага');
        // this.mySwiper.slideTo(this.currentSlide, 0, false);
        this.setPopupShow = true;
        // текст
        // const refText = this.$refs['slideText' + this.currentSlide];
        // if (refText) {
        //   this.phraseText = refText[0].innerText;
        // }
        if (slide.text) {
          this.phraseText = this.pureText(slide.text);
        }
        // заголовок
        // const refTitle = this.$refs['slideTitle' + this.currentSlide];
        // if (refTitle) {
        //   this.phraseTitle = refTitle[0].innerHTML;
        // }
        if (slide.name) {
          this.phraseTitle = slide.name;
        }

        this.isPhraseResume = this.isResume(slide.category_id);

      }
    },

    popupClose() {
      this.onHidePopup();
    },

    //region Функции из попапа
    // Закрыть попап
    onHidePopup() {
      this.setPopupShow = false
    },

    onClickAdd(data) {
      this.savedCount = data.newItem
    },
    //endregion
    //endregion

    // slideNextTransitionStart() {
    //   // console.log('START', this.$refs.mySwiper.swiper.allowSlideNext);
    //
    // },

    // При смене слайда
    changeSwiperIndex() {
      // console.log('slide REAL', this.$refs.mySwiper.swiper.realIndex);
      // console.log('slide ACTIVE', this.$refs.mySwiper.swiper.activeIndex);
      this.currentSlide = this.$refs.mySwiper.swiper.activeIndex;

      // console.log('change slide', localStorage.getItem('lastSlide'));

      // console.log('this.isFilterCategory1 ', this.isFilterCategory);

      // console.log('par', this.$route.params.isBack);

      if (!this.isFilterCategory) {
        // console.log('this.isFilterCategory 2', this.isFilterCategory);
        localStorage.setItem('lastSlide', this.$refs.mySwiper.swiper.activeIndex);
      }
      // this.currentSlide = this.$refs.mySwiper.swiper.realIndex;
    },

    //region Сохранение
    save() {
      const refSlide = this.$refs['slide' + this.currentSlide];
      const refText = this.$refs['slideText' + this.currentSlide];

      // Если это не подсказка
      if (!refSlide[0].classList.contains('slide_help')) {
        if (refText) {
          if (refSlide[0].classList.contains('slide_resume')) {
            this.saveToLocalStorage(refText[0].innerText, 'resume');
          }
          else {
            this.saveToLocalStorage(refText[0].innerText);
          }

        }
      }

      // Пролистываю на 1
      this.mySwiper.slideNext()
    },

    saveToLocalStorage(newString, type = 'default') {
      const currentLocalstorage = localStorage.getItem('saved');

      if (!currentLocalstorage) {
        let newArr = [];
        newArr.push(newString)
        localStorage.setItem("saved", JSON.stringify(newArr));
        localStorage.setItem("saved_count", '1');

        if (newString.length && newString != 'null') {
          if (type === 'default') {
            localStorage.setItem("saved_text", newString + '\n\n');
          }
          else {
            localStorage.setItem("saved_resume_text", newString + '\n\n');
          }
        }

      }
      else {
        let currentArr = JSON.parse(localStorage.getItem('saved'));
        currentArr.push(newString);
        localStorage.setItem("saved", JSON.stringify(currentArr));

        if (newString.length && newString != 'null') {
          if (type === 'default') {
            let currentText = localStorage.getItem('saved_text');
            let newText = '';
            if (currentText) {
              newText = currentText + newString + '\n\n';
            }
            else {
              newText = newString + '\n\n';
            }
            localStorage.setItem("saved_text", newText);
          }
          else {
            let currentText = localStorage.getItem('saved_resume_text');
            let newText = '';
            if (currentText) {
              newText = currentText + newString + '\n\n';
            }
            else {
              newText = newString + '\n\n';
            }

            localStorage.setItem("saved_resume_text", newText);
          }
        }

        // Сохраняем количество фраз
        let currentSavedCount = localStorage.getItem('saved_count');
        let newSavedCount = parseInt(currentSavedCount) + 1;
        localStorage.setItem("saved_count", newSavedCount.toString());
      }

      // Передаём данные в шапку
      // this.savedCount = JSON.parse(localStorage.getItem('saved')).length;
      this.savedCount = parseInt(localStorage.getItem('saved_count'));

    },
    //endregion

    // Переход к следующему слайду
    start() {
      console.log('start');
    },

    // Открытие меню
    onOpenMenu() {
      this.openMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>