<template>
  <div class="popup-filters popup-filters_music" :class="{'js-active' : isActive}">
    <div class="container">
      <div class="popup-filters__content">
        <div class="popup-filters__header">
          <div class="popup-filters__title">Дополняйте</div>
          <div class="popup-filters__icon popup-filters__icon_active" @click="close"></div>
          <div class="popup-filters__help">
            Если хотите.<br>
            А затем возвращайтесь в основной экран,<br>
            чтобы продолжить создавать описание.
          </div>
        </div>

        <div class="genre">
          <div class="genre__item" v-for="(item, index) of arrListenedAssoc"
               :class="{
            'genre__item_delimiter': item.type === 'delimiter',
            'genre__item_big': item.size === 'big'
          }"
               v-bind:key="index"
               @click="go(item.id)"
               v-html="item.name"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiltersMusic",
  props: ['isActive'],
  methods: {
    close() {
      this.$emit('closeFilters')
    },
    go(id) {
      const lastSlide = localStorage.getItem('lastSlideMusic');
      if (this.$route && this.$route.query && this.$route.query.filter_id && parseInt(this.$route.query.filter_id) === id) {
        this.close();
      }
      else {
        this.$router.push({query: {filter_id: id}, params: {lastSlideMusic: lastSlide, isBack: false}});
        this.close();
      }
    }
  },
  data() {
    return {
      // arrListened: ["Pop", "delimiter",
      //   "Rock", "Heavy Metal", "delimiter",
      //   "Alternative", "Hip-Hop", "Jazz", "delimiter",
      //   "Soul & Blues", "R&B", "Classic", "Reggae", "delimiter",
      //   "Country", "Drum 'n' Bass", "Lounge", "delimiter",
      //   "Punk", "K-Pop", "delimiter",
      //   "Indie"],
      arrListenedAssoc: [
        {
          name: "Pop",
          id: 228,
        },
        {
          name: "Rock",
          id: 229
        },
        {
          name: "Heavy Metal",
          id: 230,
          size: "big"
        },
        {type: "delimiter"},

        {
          name: "Reggae",
          id: 238
        },
        {
          name: "Alternative",
          id: 231
        },
        {
          name: "Hip-Hop",
          id: 232
        },
        {
          name: "Jazz",
          id: 233
        },
        {type: "delimiter"},

        {
          name: "Soul &<br/> Blues",
          id: 234,
          // size: "big"
        },
        {
          name: "R&B",
          id: 235
        },
        {
          name: "Classic",
          id: 236
        },
        {type: "delimiter"},

        {
          name: "Techno",
          id: 250
        },
        {
          name: "Country",
          id: 237
        },
        {
          name: "Drum 'n' Bass",
          id: 239
        },
        {
          name: "Lounge",
          id: 240
        },
        {type: "delimiter"},
        {
          name: "Punk",
          id: 243
        },
        {
          name: "K-Pop",
          id: 244
        },
        {
          name: "Indie",
          id: 245
        },
        {type: "delimiter"},
        {
          name: "House &<br/>Trance",
          id: 242
        },
        {
          name: "Grunge",
          id: 241
        },
        {
          name: "Folk",
          id: 246
        },
        {
          name: "Ethnic",
          id: 247
        },
        {type: "delimiter"},
        {
          name: "Disco",
          id: 248
        },
        {
          name: "Rapcore",
          id: 249
        }


      ]
    }
  }
}
</script>

<style scoped lang="scss">
.genre {
  text-align: center;
  padding-bottom: 100px;

  &__item {
    //display: inline-block;
    margin-right: 2px;
    margin-bottom: 0;
    vertical-align: middle;

    border: 1px solid #d6d6d6;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    line-height: 15px;
    width: 90px;
    border-radius: 23px;
    font-size: 12px;
    font-weight: 300;
    color: #333;
    transition: all 200ms ease-in-out;
    outline: none;

    @media only screen and (max-width: 410px) {
      width: 80px;
    }
    @media only screen and (max-width: 370px) {
      font-size: 11px;
      width: 70px;
    }
    @media only screen and (max-width: 330px) {
      width: 65px;
    }

    &:not(.genre__item_delimiter) {
      cursor: pointer;
    }

    &_big {
      @media only screen and (max-width: 370px) {
        width: 75px;
      }
      @media only screen and (max-width: 330px) {
        width: 75px;
      }
    }

    &_delimiter {
      width: 100%;
      margin-bottom: 0;
      height: 6px;
      padding: 0;
      display: block;
      border: none;
      background: none;

      &:hover {
        background: none;
      }
    }

    &:not(.genre__item_delimiter):hover {
      background: #333;
      color: #fff;
    }
  }
}
</style>