<template>
  <div>
    <div class="page full-page full-page_flex-column full-page_wrap">
      <div class="container container_create">

        <div class="create" ref="scrollable">
          <h1>Хотите создать</h1>
          <div class="create__list">
            <div class="create__item">
              <div class="create__block" @click="go('phrases/resume')">
                <div class="create__title">Резюме</div>
                <div class="create__text">Ваши деловые и личные качества</div>
                <div class="create__bottom">Идеально для HeadHunter<span class="delimiter-dot delimiter-dot_grey"></span>SuperJob<span class="delimiter-dot delimiter-dot_grey"></span>Rabota.ru</div>
              </div>
              <div class="create__link" @click="example('resume')">Посмотреть примеры</div>
            </div>
            <div class="create__item">
              <div class="create__block" @click="go('phrases/letter')">
                <div class="create__title">Письмо</div>
                <div class="create__text">Ваши навыки и достижения</div>
                <div class="create__bottom">Идеально, чтобы рассказать о себе в деталях</div>
              </div>
              <div class="create__link" @click="example('letter')">Посмотреть примеры</div>
            </div>
            <div class="create__item">
              <div class="create__block" @click="go('portfolio-categories')">
                <div class="create__title">Портфолио</div>
                <div class="create__text create__text_small">Дизайн<span class="delimiter-dot delimiter-dot_black"></span>Продакшн<span class="delimiter-dot delimiter-dot_black"></span>Программирование</div>
                <div class="create__bottom"><div>Подходит также для письма</div>Идеально, чтобы рассказать о своем творчестве</div>
              </div>
              <div class="create__link" @click="example('portfolio')">Посмотреть примеры</div>
            </div>
            <div class="create__item">
              <div class="create__block" @click="go('phrases/crib')">
                <div class="create__title">Шпаргалка</div>
                <div class="create__text">Ваш характер и недостатки</div>
                <div class="create__bottom">Идеально на случай собеседований</div>
              </div>
              <div class="create__link" @click="example('crib')">Посмотреть примеры</div>
            </div>
            <div class="create__item">
              <div class="create__block" @click="go('phrases/socials')">
                <div class="create__title">Соцсети</div>
                <div class="create__text">Ваш внутренний мир</div>
                <div class="create__bottom">Идеально для Instagram<span class="delimiter-dot delimiter-dot_grey"></span>Facebook<span class="delimiter-dot delimiter-dot_grey"></span>VK</div>
              </div>
              <div class="create__link" @click="example('socials')">Посмотреть примеры</div>
            </div>
            <div class="create__item">
              <div class="create__block" @click="go('phrases/connection')">
                <div class="create__title">Знакомства</div>
                <div class="create__text">Ваши ценности и увлечения</div>
                <div class="create__bottom">Идеально для Tinder<span class="delimiter-dot delimiter-dot_grey"></span>Badoo<span class="delimiter-dot delimiter-dot_grey"></span>Bumble</div>
              </div>
              <div class="create__link" @click="example('connection')">Посмотреть примеры</div>
            </div>
          </div>
        </div>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>

      </div>
    </div>
    <footer-front/>
  </div>

</template>

<script>
const savedPosition = {
  x: 0,
  y: 0,
}

import FooterFront from "@/components/FooterFront";
export default {
  name: "Want",
  components: {FooterFront},
  data() {
    return {

    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.scrollTo({
      left: savedPosition.x,
      top: savedPosition.y,
    })
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  methods: {
    // Сохраняем позицию скролла
    handleScroll() {
      // console.log('event', event);
      savedPosition.y = window.scrollY;
    },
    go(url) {
      this.$router.push(url)
    },
    example(url) {
      this.$router.push({path: '/example-descriptions/' + url})
    }
  }
}
</script>

<style scoped>
.create__block {
  cursor: pointer;
}
</style>