<template>
  <div>
    <header class="header">
      <div class="container">
        <a href="#" class="header__back" @click="$router.push({name: backDestination, params: {openMenu: true}})" v-if="isShowBack"></a>
        <div class="header__logo">PRO</div>
        <div class="header__right">
          <a class="header__btn-pro header__btn-pro_active" href="#" @click.prevent="$router.push('phrases')"></a>
        </div>
      </div>
    </header>
    <div class="full-page full-page_v-start full-page_pro">
      <div class="container">
        <div class="pro" style="max-width: 310px;">
          <div class="pro__top"><b>149 ₽ · 30 дн</b><span>Без автопродления</span>
            <div class="pro__button btn btn_bg-dark">Купить</div>
          </div>
          <div class="pro__list">
            <div class="pro__column"><a class="pro__item" href="#">Начало</a><a class="pro__item" href="#">Личные качества</a><a class="pro__item" href="#">Без опыта</a><a class="pro__item" href="#">Музыка</a><a class="pro__item" href="#">Мотивация</a><a
                class="pro__item" href="#">Ошибки</a>
            </div>
            <div class="pro__column"><a class="pro__item" href="#">Завершение</a><a class="pro__item" href="#">Деловые качества</a><a class="pro__item" href="#">Достижения</a><a class="pro__item" href="#">Кино</a><a class="pro__item"
                                                                                                                                                                                                                        href="#">Увлечения</a><a
                class="pro__item" href="#">Недостатки</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pro",
  data() {
    return {
      isShowBack: false,
      backDestination: 'phrases'
    }
  },
  mounted() {
    if (this.$route.params.isBack) {
      this.isShowBack = true
      this.backDestination = this.$route.params.destination
    }
  }
}
</script>

<style scoped>

</style>