<template>
  <div class="page">
    <div class="full-page full-page_v-center">
      <div class="container front-sex">
        <div class="front-sex__title">Я</div>
        <div class="front-sex__buttons">
          <div class="front-sex__button btn btn_grey" @click="finish('woman')">Женщина</div>
          <div class="front-sex__button btn btn_grey" @click="finish('man')">Мужчина</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sex",
  methods: {
    // Завершение
    finish(sex) {
      localStorage.setItem('sex', sex);
      this.$router.push({name:'phrases', params: {sex: sex}})
    },
  }
}
</script>

<style scoped>

</style>