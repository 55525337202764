<template>
  <div class="page">
    <div class="container container_narrow">
      <h1>Вопрос – Ответ</h1>
    </div>
    <main class="page__content container container_narrow">

      <div class="faq">
        <div class="faq__list faq-list" :class="{'faq-list_show':openedArr[index] === true}" v-for="(item, index) in fag" v-bind:key="index">
          <div class="faq-list__title" @click="open(index)">{{ item.title }}</div>
          <button class="faq-list__show" type="button" @click="open(index)"></button>
          <div class="faq-list__items">
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      // opened: null,
      openedArr: [false,false,false,false,false,false,false,false],
      fag: [
        {
          'title': 'Мне достаточно пройти опрос?',
          'text': 'Верно. Опрос включает около 20 вопросов и занимает не более 3 минут. Сочинять ничего не нужно. Best Me всё сочинит за Вас.'
        },
        {
          'title': 'Best Me напишет лучше меня?',
          'text': 'Вы удивитесь, но, скорее всего, да. Особенно если Вы испытываете сложности с изложением Ваших сильных сторон. Благодаря Best Me они станут очевидными. Не исключено, что Вы скажите: «Да, точно, это про меня».'
        },
        {
          'title': 'Как использовать Best Me Jobs?',
          'text': 'Проходите опрос и получаете текст о Ваших профессиональных и личных качествах. В двух вариантах – тезисном и подробном. Тезисный вариант можно вставить в Ваше резюме, подробный – использовать в качестве сопроводительного письма.'
        },
        {
          'title': 'Как использовать Best Me Dates?',
          'text': 'Проходите опрос и получаете текст о Ваших увлечениях и жизненных ценностях. Текст можно вставить в раздел «О себе» наиболее популярных приложений и сайтов знакомств.'
        },
        {
          'title': 'Кто составляет фразы?',
          'text': 'Редакторы Best Me. Они пишут не только фразы, но и вопросы, ответы на которые и позволяют определить Ваши достоинства. При составлении опросов редакторы опираются на мнения рекрутеров и CEO крупных, средних и небольших по размеру компаний, а также на опыт заказчиков фриланс-услуг – в России и за рубежом. В анкетах для знакомства используется нетривиальная подача и немного юмора.'
        },
        {
          'title': 'Почему Вы с большой буквы?',
          'text': 'Из уважения к Вам.'
        }
      ]
    }
  },
  methods: {
    open(list) {
      if (this.openedArr[list] === true) {
        // this.$set(this.openedArr, list - 1, false)
        this.$set(this.openedArr, list, false)
      } else {
        // this.$set(this.openedArr, list - 1, true)
        this.$set(this.openedArr, list, true)
      }
    }
  }
}
</script>

<style scoped>
.page {
  padding-top: 70px;
}
</style>