<template>
  <div>
    <header class="header">
      <div class="container">
        <a class="header__logo" href="#" @click.prevent="">Best Me</a>
      </div>
    </header>

    <div class="full-page full-page_p-top">
      <div class="container">
        <div class="main">
          <div class="main__row">
            <div class="main__title">Создать описание</div>
            <div class="main__list main__list_create">
              <a class="main__item" href="#" @click.prevent="go('phrases/resume')">Резюме</a>
              <a class="main__item" href="#" @click.prevent="go('phrases/letter')">Письмо</a>
              <a class="main__item" href="#" @click.prevent="go('portfolio-categories')">Портфолио</a>
              <a class="main__item" href="#" @click.prevent="go('phrases/crib')">Шпаргалка</a>
              <a class="main__item" href="#" @click.prevent="go('phrases/socials')">Соцсети</a>
              <a class="main__item" href="#" @click.prevent="go('phrases/connection')">Знакомства</a>
            </div>
          </div>
          <div class="main__row">
            <div class="main__title">Посмотреть примеры</div>
            <div class="main__list main__list_examples">
              <a class="main__item" href="#" @click.prevent="go('example-phrases')">Фразы</a>
              <a class="main__item" href="#" @click.prevent="go('example-descriptions')">Описания</a>
            </div>
          </div>
          <div class="main__row">
            <div class="main__title">Мои описания</div>
            <div class="main__list">
              <template v-if="dataSaved.length">
                <a class="main__item main__item_description"
                   href="#"
                   @click.prevent="$router.push('saved/' + item.id)"
                   v-for="(item, index) in dataSaved"
                   v-bind:key="index">{{ item.name }} <span>{{ index + 1 }}</span></a>
              </template>
              <p class="description-empty" v-else>Здесь отображаются созданные вами описания</p>
              <!--<a class="main__item main__item_description" href="#" @click.prevent="go('')">Резюме<span>1</span></a>-->
              <!--<a class="main__item main__item_description" href="#" @click.prevent="go('')">Резюме 2<span>1</span></a>-->
            </div>
          </div>
          <div class="main__row">
            <div class="main__title">Настройки</div>
            <div class="main__list main__list_settings">
              <a class="main__item main__item_w-icon main__item_sex" :class="phraseClass" href="#" @click.prevent="changeSex()">{{ phrase }}<span>Изменить</span></a>
              <a class="main__item main__item_w-icon main__item_login" href="#" @click.prevent="go('login')" v-if="!isAuth">Войти в аккаунт</a>
              <a class="main__item main__item_w-icon main__item_signup" href="#" @click.prevent="go('registration')" v-if="!isAuth">Зарегистрироваться</a>
              <a class="main__item main__item_w-icon main__item_logout" href="#" @click.prevent="go('logout')" v-if="isAuth">Выйти из аккаунта</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-front/>

  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import FooterFront from "@/components/FooterFront";

export default {
  name: "Main",
  components: {FooterFront},
  data() {
    return {
      phrase: "Фразы в мужском роде",
      phraseMan: "Фразы в мужском роде",
      phraseWoman: "Фразы в женском роде",
      phraseClass: 'main__item_sex_man',
      phraseClassMan: 'main__item_sex_man',
      phraseClassWoman: 'main__item_sex_woman',

      isAuth: false,

      isLoaded: false,

      dataSaved: []
    }
  },
  mounted() {
    this.loadSavedData();

    this.getSex();
    this.checkIfIsAuth();
  },
  methods: {
    go(url) {
      this.$router.push(url)
    },

    // Проверка на авторизацию
    checkIfIsAuth() {
      store.dispatch('checkTokenSimple').then(() => {
        // console.log('done')
        if (localStorage.getItem('token')) {
          this.isAuth = true
        }
        else {
          this.isAuth = false
        }
        this.isLoaded = true;
      })
    },
    // Сменить пол
    changeSex() {
      if (localStorage.getItem('sex') === "man") {
        localStorage.setItem('sex', 'woman');
      }
      else {
        localStorage.setItem('sex', 'man');
      }
      // Меняем класс для пункта с родом
      this.getSex()
    },
    // Определяем пол и меняем класс и фразу в пункте
    getSex() {
      if (localStorage.getItem('sex') === "man") {
        this.phrase = this.phraseMan;
        this.phraseClass = this.phraseClassMan;
      }
      else {
        this.phrase = this.phraseWoman;
        this.phraseClass = this.phraseClassWoman;
      }
    },
    // Загрузка сохранённых
    loadSavedData() {
      if (localStorage.getItem('token')) {
        //region Dates
        axios({
          url: process.env.VUE_APP_API + 'saved-phrases',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(resp => {

              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('saved-phrases resp', resp);
              }

              if (resp.status === 200) {
                if (resp.data.total > 0) {

                  this.dataSaved = resp.data.data;

                  if (localStorage.getItem('userSaved')) {

                    // let newUserSaved = parseInt(localStorage.getItem('userSaved'));
                    // newUserSaved = newUserSaved + parseInt(resp.data.total);
                    localStorage.setItem('userSaved', resp.data.total);
                    store.commit('updateUserSaved', resp.data.total)
                  }
                }
              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('err', err);
              }
            })
        //endregion
      }
    },
  }
}
</script>

<style scoped lang="scss">
.header__logo {
  margin-left: auto;
  margin-right: auto;
  padding-right: 38px;
  padding-top: 1px;
}
</style>