import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import {HTTP} from './http-common'

import {router} from "./main.js"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    userSaved: parseInt(localStorage.getItem('userSaved')) || parseInt(0),
    premiumType: localStorage.getItem('premiumType') || 0,
    premiumExpired: localStorage.getItem('premiumExpired') || null,
    premiumOrderId: localStorage.getItem('premiumOrderId') || null,
    emailConfirmed: false,
    isAdmin: false,
    savedData: localStorage.getItem('savedData') || null
    // savedData: localStorage.savedData || {
    //   jobs: {
    //     realSubject: '',
    //     result1: '',
    //     result2: '',
    //     result1en: '',
    //     result2en: ''
    //   },
    //   dates: {
    //     result1: '',
    //     result2: '',
    //     result3: '',
    //     result4: '',
    //     result5: '',
    //     result1en: '',
    //     result2en: '',
    //     result3en: '',
    //     result4en: '',
    //     result5en: ''
    //   }
    // }
  },
  mutations: {
    check_token(state, token){
      // state.status = status
      state.status = 'success'
      state.token = token
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload){
      state.status = 'success'
      state.token = payload.token
      // console.log('token!!!', token);
      // console.log('state!!!', state);
      // console.log('user!!!', user);
      // console.log('payload', payload);
      // console.log('user', payload.user);
      state.user = payload.user
    },
    email_confirmed(state) {
      state.emailConfirmed = true
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.userSaved = 0;
    },
    updateUserSaved(state, newCount) {
      state.userSaved = newCount
    },
    updateUserSavedPlus1(state) {
      state.userSaved = parseInt(state.userSaved) + 1
    },
    updateUserSavedPlus(state, value) {
      state.userSaved = parseInt(state.userSaved) + parseInt(value)
    },
    updateUserSavedMinus1(state) {
      state.userSaved = parseInt(state.userSaved) - 1
    },
    premium_request(state){
      state.premiumType = 0
      state.premiumOrderId = null
    },
    premium_type(state, payload) {
      state.premiumType = payload.type
      state.premiumOrderId = payload.order_id
      state.premiumExpired = payload.expired_at
    },
    premium_unset(state) {
      state.premiumType = 0
      state.premiumOrderId = null
      state.premiumExpired = null
      localStorage.removeItem('premiumType');
      localStorage.removeItem('premiumOrderId');
      localStorage.removeItem('premiumExpired');
    },
    isAdmin_request(state){
      state.isAdmin = false
    },
    isAdmin_set(state, payload){
      state.isAdmin = payload.isAdmin
    },
    savedDataUnset(state) {
      // state.savedData = {
      //   jobs: {
      //     result1: '',
      //     result2: '',
      //     result1en: '',
      //     result2en: '',
      //     realSubject: ''
      //   },
      //   dates: {
      //     result1: '',
      //     result2: '',
      //     result3: '',
      //     result4: '',
      //     result5: '',
      //     result1en: '',
      //     result2en: '',
      //     result3en: '',
      //     result4en: '',
      //     result5en: ''
      //   }
      // }
      state.savedData = null;
      localStorage.setItem('savedData', null);
    }
  },
  actions: {
    //region Авторизация
    // Авториация
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: process.env.VUE_APP_API + 'login', data: user, method: 'POST' })
          .then(resp => {

            // console.log('resp', resp);

            let payload = {};
            const token = resp.data.token
            const user = resp.data.user
            payload.token = token
            payload.user = user
            payload.status = 'success'

            localStorage.setItem('token', token)

            if (resp.data.user.saved_count) {
              localStorage.setItem('userSaved', resp.data.user.saved_count)
            }

            // Информация о платном аккаунте, если он есть
            if (resp.data.premium && resp.data.premium.order_id && resp.data.premium.type && resp.data.premium.expired_at) {
              // localStorage.setItem('premiumOrderId', resp.data.premium_order)
              // localStorage.setItem('premiumType', resp.data.premium.type)
              localStorage.setItem('premiumOrderId', resp.data.premium.order_id)
              localStorage.setItem('premiumType', resp.data.premium.type)
              localStorage.setItem('premiumExpired', resp.data.premium.expired_at)

              let premiumPayload = {};
              premiumPayload.type = resp.data.premium.type
              premiumPayload.order_id = resp.data.premium.order_id
              premiumPayload.expired_at = resp.data.premium.expired_at

              commit('premium_type', premiumPayload)

            }

            // axios.defaults.headers.common['Authorization'] = token
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            // commit('auth_success', token, user)
            // commit('auth_success', token, user)
            commit('auth_success', payload)
            // this.store.commit('auth_success', {
            //   token: token,
            //   user: user,
            // });
            resolve(resp)
          })
          .catch(err => {
            if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true) || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store login');
              console.log(err)
              console.log(err.response);
            }
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    // Восстановление пароля
    remind({commit}, passwordToken){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: process.env.VUE_APP_API + 'remind/' + passwordToken.token, method: 'POST' })
          .then(resp => {
            let payload = {};
            const token = resp.data.token
            const user = resp.data.user
            payload.status = 'success'
            payload.token = token
            payload.user = user

            // Информация о платном аккаунте, если он есть
            if (resp.data.premium && resp.data.premium.order_id && resp.data.premium.type && resp.data.premium.expired_at) {
              localStorage.setItem('premiumOrderId', resp.data.premium.order_id)
              localStorage.setItem('premiumType', resp.data.premium.type)
              localStorage.setItem('premiumExpired', resp.data.premium.expired_at)

              let premiumPayload = {};
              premiumPayload.type = resp.data.premium.type
              premiumPayload.order_id = resp.data.premium.order_id
              premiumPayload.expired_at = resp.data.premium.expired_at

              commit('premium_type', premiumPayload)

            }

            localStorage.setItem('token', token)
            // axios.defaults.headers.common['Authorization'] = token
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            // commit('auth_success', token, user)
            commit('auth_success', payload)
            resolve(resp)
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store remind');
              console.log(err)
              console.log(err.response);
            }
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    // Регистрация
    register({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: process.env.VUE_APP_API + 'register', data: user, method: 'POST' })
          .then(resp => {
            let payload = {};
            const token = resp.data.token
            const user = resp.data.user
            payload.status = 'success'
            payload.token = token
            payload.user = user
            localStorage.setItem('token', token)
            // axios.defaults.headers.common['Authorization'] = token
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            // commit('auth_success', token, user)
            commit('auth_success', payload)
            resolve(resp)
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store register');
              console.log(err)
              console.log(err.response);
            }
            commit('auth_error', err)
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    // Подтверждение почты
    confirmEmail({commit}, hash){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API + 'confirm-email', data: hash, method: 'POST' })
          .then(resp => {
            let payload = {};
            const token = resp.data.token
            payload.token = token
            localStorage.setItem('token', token)
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

            // Информация о платном аккаунте, если он есть
            // if (resp.data.premium) {
            //   localStorage.setItem('premiumOrderId', resp.data.premium_order)
            //   localStorage.setItem('premiumType', resp.data.premium.type)
            // }
            if (resp.data.premium && resp.data.premium.order_id && resp.data.premium.type && resp.data.premium.expired_at) {
              localStorage.setItem('premiumOrderId', resp.data.premium.order_id)
              localStorage.setItem('premiumType', resp.data.premium.type)
              localStorage.setItem('premiumExpired', resp.data.premium.expired_at)

              let premiumPayload = {};
              premiumPayload.type = resp.data.premium.type
              premiumPayload.order_id = resp.data.premium.order_id
              premiumPayload.expired_at = resp.data.premium.expired_at

              commit('premium_type', premiumPayload)

            }

            commit('email_confirmed')
            resolve(resp)
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store confirmEmail');
              console.log(err)
              console.log(err.response);
            }
            reject(err)
          })
      })
    },

    // Выход
    logout({commit}){
      console.log('logout');
      // return new Promise((resolve, reject) => {
      return new Promise((resolve) => {
        // console.log('resolve', resolve);
        commit('auth_error')
        commit('logout')
        localStorage.removeItem('token')
        localStorage.setItem('userSaved', 0)
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },

    // Смена пароля
    changePassword({commit}, passwords){
      return new Promise((resolve, reject) => {
        // console.log('passwords', passwords);
        commit('auth_request')
        axios({url: process.env.VUE_APP_API + 'change-password', data: passwords, method: 'POST' })
          .then(resp => {
            // const token = resp.data.token
            const user = resp.data.user
            this.state.user = user
            // localStorage.setItem('token', token)
            // axios.defaults.headers.common['Authorization'] = token
            // axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            // commit('auth_success', token, user)
            resolve(resp)
          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store changePassword');
              console.log(err)
              console.log(err.response);
            }
            // console.log('err.response', err.response);
            // console.log('error_store', err);
            // commit('auth_error', err)
            // localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    //endregion

    // Проверка жизни токена
    checkToken({commit}){
      return new Promise((resolve, reject) => {
        // console.log('resolve,', resolve);
        // console.log('reject,', reject);
        commit('check_token')

        // axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        // console.log('!!!!axios.defaults.headers.common', axios.defaults.headers.common);

        // console.log('header', axios.defaults.headers);

        // console.log('token', localStorage.getItem('token'));

        // axios({url: process.env.VUE_APP_API + 'check-token', data: {'check':'token'}, method: 'POST' })
        // var self = this;
        axios({url: process.env.VUE_APP_API + 'check-token', method: 'POST',
          data: {check: 'token'}, headers: {
            // todo: Не работает без заголовков, хотя они по умолчанию
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(resp => {
            if(resp.data) {
              if (localStorage.getItem('token')) {
                // commit('auth_success', localStorage.getItem('token'), localStorage.getItem('user'))

                const token = localStorage.getItem('token')
                const user = localStorage.getItem('user')

                let payload = {};
                payload.status = "status";
                payload.token = token;
                payload.user = user
                commit('auth_success', payload)

              }
              resolve(resp)
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store check-token');
              console.log(err)
              console.log(err.response);
            }

            // console.log('axios.defaults.headers.common', axios.defaults.headers.common);
            commit('logout')
            if (localStorage.getItem('token')) {
              localStorage.removeItem('token')
            }
            // self.$router.push('login')
            router.push("login");
            reject(err)
          })
      })
    },

    // Проверка жизни токена без редиректов
    checkTokenSimple({commit}) {
      return new Promise((resolve, reject) => {
        commit('check_token')

        if (localStorage.getItem('token')) {

          axios({
            url: process.env.VUE_APP_API + 'check-token', method: 'POST',
            data: {check: 'token'}, headers: {
              // todo: Не работает без заголовков, хотя они по умолчанию
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
            .then(resp => {
              if (resp.data) {
                if (localStorage.getItem('token')) {
                  // commit('auth_success', localStorage.getItem('token'), localStorage.getItem('user'))

                  const token = localStorage.getItem('token')
                  const user = localStorage.getItem('user')

                  let payload = {};
                  payload.status = "status";
                  payload.token = token;
                  payload.user = user
                  commit('auth_success', payload)
                }
                resolve(resp)
              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
                console.log('store checkTokenSimple');
                console.log(err)
                console.log(err.response);
              }

              if (localStorage.getItem('token')) {
                localStorage.removeItem('token')
              }
              reject(err)
            })
        } else {
          resolve('false')
        }
      })
    },

    // Проверка и обновление платной подписки
    // setPremium({commit}, order){
    setPremium({commit}){
      return new Promise((resolve, reject) => {
        commit('premium_request')
        // axios({url: process.env.VUE_APP_API + 'check-premium/' + order.order_id, method: 'GET', headers: {
        axios({url: process.env.VUE_APP_API + 'get-premium', method: 'GET', headers: {
          // todo: Не работает без заголовков, хотя они по умолчанию
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(resp => {

            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              // console.log('resp setPremium', resp);
            }

            if (resp.data && resp.data.order_id && resp.data.type && resp.data.expired_at) {
              let premiumPayload = {};
              premiumPayload.type = resp.data.type
              premiumPayload.order_id = resp.data.order_id
              premiumPayload.expired_at = resp.data.expired_at
              commit('premium_type', premiumPayload)
            }

            localStorage.setItem('premiumType', resp.data.type);
            this.state.premiumType = resp.data.type;

            localStorage.setItem('premiumOrderId', resp.data.order_id);
            this.state.premiumOrderId = resp.data.order_id;

            localStorage.setItem('premiumExpired', resp.data.expired_at);
            this.state.premiumExpired = resp.data.expired_at;
            // console.log('this.state.premiumExpired', this.state.premiumExpired);
            // console.log('localStorage', localStorage.getItem('premiumExpired'));

            resolve(resp)
            return resp
          })
          .catch(err => {
            if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true) || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store setPremium', err)
              console.log('store setPremium', err.response);
            }
            // console.log('err.response', err.response);
            // console.log('error_store', err);
            // commit('auth_error', err)
            // localStorage.removeItem('token')
            reject(err)
            return err
          })
      })
    },

    // Удаление подписки
    unsetPremium({commit}){
      return new Promise((resolve, reject) => {
        commit('premium_unset')
        if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true) || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
          console.log('store unsetPremium');
          console.log('reject', reject);
        }
        resolve();
      })
    },

    setUserSaved: ({commit, state}, newValue) => {
      commit('updateUserSaved', newValue)
      return state.getUserSaved
    },

    // Проверка и обновление платной подписки
    // setPremium({commit}, order){
    isAdminCheck({commit}){
      return new Promise((resolve, reject) => {
        commit('isAdmin_request')
        // axios({url: process.env.VUE_APP_API + 'check-premium/' + order.order_id, method: 'GET', headers: {
        axios({url: process.env.VUE_APP_API + 'admin/isAdmin', method: 'GET', headers: {
            // todo: Не работает без заголовков, хотя они по умолчанию
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(resp => {

            if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('resp isAdminCheck', resp);
            }

            // localStorage.setItem('premiumExpired', resp.data.expired_at);
            // this.state.premiumExpired = resp.data.expired_at;

            commit('isAdmin_set', resp.data)

            resolve(resp)
          })
          .catch(err => {
            if ((process.env.VUE_APP_TYPE === 'local' && router.currentRoute.query.isDebug === true) || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
              console.log('store isAdminCheck', err)
              console.log('store isAdminCheck', err.response);
            }

            reject(err)
          })
      })
    },


    //region Сохранение данных
    setSavedData({commit}, data) {
      return new Promise((resolve, reject) => {

        commit('savedDataUnset')

        // console.log('data setSavedData', data);

        this.state.savedData = JSON.stringify(data);
        localStorage.setItem('savedData', JSON.stringify(data));

        // console.log('this.state.savedData', this.state.savedData);
        // console.log('localStorage.getItem("savedData")', localStorage.getItem("savedData"));

        resolve(data)

        if (process.env.VUE_APP_TYPE === 'local' || (router.currentRoute.query && router.currentRoute.query.isDebug === true)) {
          console.log('reject', reject);
        }

        // commit('premium_type', premiumPayload)
        // localStorage.setItem('premiumType', resp.data.type);
        // this.state.premiumType = resp.data.type;
      })
    },
    //endregion

    //region Проверка доступа (авторизация + подписка)
    checkAccess({commit}, data) {
      console.log('test', commit, data);
      return 'ok';
    }
    //endregion

  },
  getters : {
    isAuthenticated: state => !!state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    // getUser: (state) => { return state.user2; }
    getUser: state => state.user,
    getUserEmail: state => state.user.email,
    getUserSaved: state => state.userSaved,
    getPremiumType: state => state.premiumType,
    getPremiumExpired: state => state.premiumExpired,
    getPremiumOrder: state => state.premiumOrderId,
    getSavedData: state => state.savedData
  }
})