<template>
  <div class="full-page full-page_p-top-25">
    <div class="container">
      <div class="descriptions">
        <div class="descriptions__header">
          <a class="descriptions__back" href="#" @click="back()"></a>
          <div class="descriptions__title">Мои описания</div>
        </div>
        <div class="descriptions__body">
          <div class="descriptions__list" v-if="dataSaved.length">
            <a class="main__item main__item_description"
               href="#"
               @click.prevent="$router.push('saved/' + item.id)"
               v-for="(item, index) in dataSaved"
               v-bind:key="index">{{ item.name }} <span>{{ index + 1 }}</span></a>

            <div class="btn btn_centered btn_skip" @click="$router.push({name: 'main'})">&lt; Вернуться на главную</div>
            <br>
            <br>


          </div>
          <div class="descriptions__empty" v-else>
            Здесь отображаются созданные вами описания
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";

export default {
  name: "SavedList",
  data() {
    return {
      dataSaved: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    back() {
      const categoryType = localStorage.getItem('category_type');
      console.log('categoryType', categoryType);
      if (categoryType) {
        this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
      }
      else if (this.$route.params && this.$route.params.type) {
        this.$router.push({name: 'main'})
      }
      else {
        this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
      }
    },
    loadData() {
      if (localStorage.getItem('token')) {
        //region Dates
        axios({
          url: process.env.VUE_APP_API + 'saved-phrases',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(resp => {

              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('saved-phrases resp', resp);
              }

              if (resp.status === 200) {
                if (resp.data.total > 0) {
                  this.dataSaved = resp.data.data;

                  if (localStorage.getItem('userSaved')) {

                    // let newUserSaved = parseInt(localStorage.getItem('userSaved'));
                    // newUserSaved = newUserSaved + parseInt(resp.data.total);
                    localStorage.setItem('userSaved', resp.data.total);
                    store.commit('updateUserSaved', resp.data.total)
                  }
                }
              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('err', err);
              }
            })
        //endregion
      }
    },
  }
}
</script>

<style scoped>

</style>