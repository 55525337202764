<template>
  <div>
    <header class="header" :class="[{header_open:isHeaderOpened, 'header_special': isSpecialHeader}, headerFrontClass]">
      <div :class="headerContainerClass">
        <div class="header__burger-button" @click="$router.push('/main')">
          <!--<span v-if="descriptions > 0"></span>-->
        </div>
        <a class="header__logo" href="#" @click.prevent="$router.push('/main')">Best Me</a>
        <div class="header__right">
          <a class="header__btn-pro" href="#" @click.prevent="openFilters()"></a>
          <transition name="slide-fade" mode="out-in">
            <div class="header__menu-counter" :class="{'header__menu-counter_active': count>0}" @click.prevent="openCart()" :key="count">{{ count }}</div>
          </transition>
        </div>

        <nav class="header__menu">
          <ul>
            <li><a href="#" @click.prevent="clickMenuLink('example-phrases')">Примеры фраз</a></li>
            <li><a href="#" @click.prevent="clickMenuLink('example-descriptions')">Примеры описаний</a></li>
            <li><a href="#" @click.prevent="clickMenuLink('want')">Создать описание</a></li>

            <li><a href="#" @click.prevent="clickMenuLink('saved')"><b>Мои описания</b> <span class="header__menu-counter">{{ descriptions }}</span>
              <div>Личный кабинет</div>
            </a></li>

            <li class="header__item-last"><a href="#" @click.prevent="clickMenuLink('settings')">Настройки</a></li>

            <template v-if="isAuth">

            </template>
            <template v-else>
              <!--<li class="header__item-last"><a href="#" @click.prevent="clickMenuLink('reviews')">Отзывы</a></li>-->
              <li class="header__item-login"><a href="#" @click.prevent="clickMenuLink('login')">Войти</a></li>
              <li class="header__item-login"><a href="#" @click.prevent="clickMenuLink('registration')">Зарегистрироваться</a></li>
            </template>

            <li class="header__item-email"><a href="mailto:info@bestme.nl">По всем вопросам: info@bestme.nl</a></li>
          </ul>
        </nav>


      </div>
    </header>

    <filters :isActive="isFiltersActive" @closeFilters="onCloseFilters"/>

    <cart-popup :isActive="isCartActive" @closeCart="onCloseCart" @emptyCart="onEmptyCart"/>
  </div>
</template>

<script>
import * as moment from "moment";
import store from "@/store";
import Filters from "@/components/Filters";
import CartPopup from "@/components/CartPopup";

export default {
  name: "Menu",
  components: {CartPopup, Filters},
  props: [
    'savedCount',
    'isNeedOpen',
    'isSpecialHeader'
  ],
  data() {
    return {
      isFiltersActive: false,
      isCartActive: false,
      isHeaderOpened: false,
      count: 0,
      headerFrontClass: '',
      headerContainerClass: 'container',
      siteName: "Best Me",
      premiumCountdown: null,
      ifHasPremium: false,
      descriptions: 0,
      sex: '',
      isAuth: false
    }
  },
  computed: {
    isFront() {
      return this.$route.name === 'Front'
    },
    isLanding() {
      return this.$route.name === 'Front' || this.$route.name === 'Chat'
    },
    userText: function () {
      let result = '';

      let type = null;
      // let typeText = null;
      if (this.$store.getters.getPremiumType) {
        type = parseInt(this.$store.getters.getPremiumType);
        if (type === 1) {
          // typeText = 'Jobs + Dates';
        }
        else if (type === 2) {
          // typeText = 'Dates';
        }
      }

      let countdown = null;
      if (this.$store.getters.getPremiumExpired) {
        countdown = moment(moment.now()).diff(moment(this.$store.getters.getPremiumExpired, "YYYY-MM-DD"), "days");
        countdown = Math.abs(countdown);
      }

      // if (typeText && countdown) {
      //   result = typeText + ' · ' + countdown + ' дн';
      // } else if (typeText) {
      //   result = typeText;
      // } else if (countdown) {
      //   result = countdown + ' дн';
      // }

      if (countdown) {
        result = countdown + ' дн';
      }

      return result
    }
  },
  watch: {

    // Открываеи меню по требованию из других компонентов
    isNeedOpen: {
      immediate: true,
      deep: true,
      handler() {
        if (this.isNeedOpen === true) {
          this.openMenu()
        }
      }
    },

    savedCount: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(newVal) {
        this.getSavedCount(newVal)
      }
    },

    descriptions: {
      immediate: true,
      deep: true,
      handler() {
        this.getDescriptions()
      }
    },

    userText: function () {
    },

    // $route (to,from){
    $route(to) {
      // console.log('to', to);
      // console.log('this.$route.name',this.$route.name);
      if (to.name === 'Front' || to.name === 'Chat') {
        this.headerFrontClass = 'header_landing'
        this.headerContainerClass = 'l-container'
        this.siteName = 'Best Me'
      }
      else {
        this.headerFrontClass = ''
        this.headerContainerClass = 'container'
        this.siteName = 'B.'
      }
    }
  },
  mounted() {
    if (this.$route.name === 'Front' || this.$route.name === 'Chat') {
      this.headerFrontClass = 'header_landing'
      this.headerContainerClass = 'l-container'
      this.siteName = 'Best Me'
    }

    //region Количество сохранённых
    this.getSavedCount()
    //endregion

    this.getDescriptions();

    this.getSex()

    // this.setPremiumInfo();
    //
    // this.$store.dispatch('setPremium');
    //
    // // Initialize inside mounted to ensure store is within scope
    // const { getters } = this.$store;
    //
    // const thisWorks = () => {
    //   const globalCompanies = getters.getPremiumType;
    //   console.log('globalCompanies', globalCompanies);
    //   // this.premiumType = getters.getPremiumType;
    // }

    // thisWorks();

    // this.premiumType = getters.getPremiumType;
    // this.setPremiumInfo();

    this.checkIfIsAuth();
  },
  methods: {

    // Проверка на авторизацию
    checkIfIsAuth() {
      store.dispatch('checkTokenSimple');
      if (localStorage.getItem('token')) {
        this.isAuth = true
      }
      else {
        this.isAuth = false
      }
    },

    getSex() {
      let sex = 'Мужской';
      if (localStorage.getItem('sex') === 'woman') {
        sex = 'Женский'
      }
      this.sex = sex;
    },

    // Получить количество сохранённых элементов
    getSavedCount(item) {
      if (item) {
        this.count = item;
      }
      else {
        const getSavedFromLocalstorage = localStorage.getItem('saved_count');
        if (getSavedFromLocalstorage) {
          // const saved = JSON.parse(getSavedFromLocalstorage);
          // this.count = saved.length;
          this.count = getSavedFromLocalstorage;
        }
      }
    },

    getDescriptions() {
      // this.descriptions = localStorage.getItem('user_saved')
      this.descriptions = this.$store.getters.getUserSaved
    },

    // Клик по пункту меню
    clickMenuLink(link) {
      // console.log('click');
      const path = '/' + link;
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
      this.isHeaderOpened = false;
    },
    // Открываем меню
    openMenu() {

      this.isHeaderOpened = !this.isHeaderOpened;
      if (this.isHeaderOpened) {
        this.descriptions = this.$store.getters.getUserSaved
      }
      this.$emit('openMenu', {
        isOpen: true
      })
    },

    //region Фильтры
    openFilters() {
      this.isFiltersActive = true;
      // console.log('isFiltersActive', this.isFiltersActive);
      // document.body.classList.add('modal-open');
    },
    onCloseFilters() {
      // document.documentElement.style.overflow = 'auto';
      // @closePopup="onHidePopup"
      this.isFiltersActive = false;
      this.$emit('filterSelected');
      // document.body.classList.remove('modal-open');
    },
    //endregion

    //region Корзина
    openCart() {
      this.isCartActive = true;
      // document.body.classList.add('modal-open');
      // document.documentElement.style.overflow = 'hidden';
    },
    onCloseCart() {
      this.isCartActive = false;
      // document.body.classList.remove('modal-open');
    },
    onEmptyCart() {
      this.getSavedCount(0);
      this.count = 0;
    }
    //endregion

  },

}
</script>

<style scoped>
.header__menu li div {
  font-size: 15px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */ {
  transform: scale(1.3);
  //opacity: 0;
}
</style>