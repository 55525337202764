<template>
  <div class="jobs-result">
    <div class="jobs-result__wrapper">
      <div class="jobs-result__title">{{subject}}</div>
      <div class="flex-break"></div>

      <div class="jobs-result__toggles" v-if="!isEng">
        <div class="jobs-result__toggle" :class="{'jobs-result__toggle_active': resultTabRu === 1}" type="button" @click="changeResultPager(1); resultTabRu = 1">Тезисно</div>
        <div class="jobs-result__toggle" :class="{'jobs-result__toggle_active': resultTabRu === 2}" type="button" @click="changeResultPager(2); resultTabRu = 2">Подробно</div>
      </div>
      <div class="jobs-result__toggles" v-if="isEng">
        <div class="jobs-result__toggle" :class="{'jobs-result__toggle_active': resultTabEn === 1}" type="button" @click="changeResultPager(1); resultTabEn = 1">Тезисно</div>
        <div class="jobs-result__toggle" :class="{'jobs-result__toggle_active': resultTabEn === 2}" type="button" @click="changeResultPager(2); resultTabEn = 2">Подробно</div>
      </div>
      <div class="flex-break"></div>

      <div class="lds-ring" v-if="!isDataLoaded"><div></div><div></div><div></div><div></div></div>
      <template v-else>
        <template v-if="!isEng">
          <div class="jobs-result__text" v-bind:class="{'blur-container blur-container_work': !isAccess}" ref="result1" v-if="resultTabRu === 1">
            <div v-html="resultTextarea1"></div>
             <blur isJob v-if="!isAccess"></blur>
          </div>
          <div class="jobs-result__text" v-bind:class="{'blur-container blur-container_work': !isAccess}" ref="result2" v-if="resultTabRu === 2">
            <div v-html="resultTextarea2"></div>
             <blur isJob v-if="!isAccess"></blur>
          </div>
        </template>
        <template v-if="isEng">
          <div class="jobs-result__text" v-bind:class="{'blur-container blur-container_work': !isAccess}" ref="result1" v-if="resultTabEn === 1">
            <div v-html="resultTextarea1en" v-if="resultTextarea1en"></div>
             <blur isJob v-if="resultTextarea1en && !isAccess"></blur>
            <div v-else>Для этой профессии результаты опроса пока доступны только на русском языке.</div>
          </div>
          <div class="jobs-result__text" v-bind:class="{'blur-container blur-container_work': !isAccess}" ref="result2" v-if="resultTabEn === 2">
            <div v-html="resultTextarea2en" v-if="resultTextarea2en"></div>
             <blur isJob v-if="resultTextarea2en && !isAccess"></blur>
            <div v-else>Для этой профессии результаты опроса пока доступны только на русском языке.</div>
          </div>
        </template>
        <div class="jobs-result__actions" v-if="isAccess">
          <div class="btn btn_grey btn_centered btn_save" @click.once="saveResult" v-if="!isSaved">Сохранить</div>
          <div class="btn btn_grey btn_centered btn_save js-active" type="button" v-if="isSaved">Сохранено</div>

          <button class="jobs-result__lang" type="button" v-if="!isEng" @click="isEng = !isEng">EN</button>
          <button class="jobs-result__lang" type="button" v-if="isEng" @click="isEng = !isEng">RU</button>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import Blur from "@/components/Blur";
// import store from "@/store";
import * as checkAccess from '@/plugins/access-check';

export default {
  name: "JobsResults",
  components: {Blur},
  props: {
    result1: String,
    result2: String,
    result1en: String,
    result2en: String,
    resultSubject: String,
  },
  data() {
    return {
      // Данные загружены
      isDataLoaded: false,
      isNeed: false,
      //region Результаты
      isEng: false,
      resultTabRu: 1,
      resultTabEn: 1,
      // Показ шагов
      isShowResults: [true, false],
      //region textarea с результатами
      subject: this.resultSubject,
      resultTextarea1: this.result1,
      resultTextarea2: this.result2,
      resultTextarea1en: this.result1en ? this.result1en : '',
      resultTextarea2en: this.result2en ? this.result2en : '',

      //endregion
      // Сохраните все результаты
      isShowResultAll: false,
      // Отправить на email
      isShowResultEmail: false,
      // Отправлено на email
      isShowResultSent: false,
      //endregion
      isSaved: false,

      savedData: null,

      isAccess: false
    }
  },
  mounted() {

    console.log('mounted');

    //region Сохранение данных для работы
    let jobs = {
      result1: this.resultTextarea1,
      result2: this.resultTextarea2,
      result1en: this.resultTextarea3,
      result2en: this.resultTextarea4,
      realSubject: this.resultSubject
    }
    this.$store.dispatch('setSavedData', {jobs});
    //endregion

    // Проверка доступа
    this.getAccess();
  },
  methods: {

    getAccess() {
      this.isDataLoaded = true;
      //region Проверка платной подписки
      if (localStorage.getItem('token')) {
        checkAccess.isAccess.then((res) => {
          if (res === true) {
            this.isAccess = true;
          }
          else {
            this.isAccess = false;
          }
        });
      }
      //endregion
    },


    saveResult() {
      this.isSaved = true;

      let self = this;

      axios({
        url: process.env.VUE_APP_API + 'jobs',
        data: {
          name: this.subject,
          description_short: this.resultTextarea1,
          description: this.resultTextarea2,
          description_short_en: this.resultTextarea1en,
          description_en: this.resultTextarea2en
        },
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(function (response) {

            if (process.env.VUE_APP_TYPE === 'local' || (this.$route.query && this.$route.query.isDebug === true)) {
              console.log('response', response);
            }

            // Обновляем "Обо мне" (+1)
            self.$store.commit('updateUserSavedPlus1');


          })
          .catch(function (error) {
            if (process.env.VUE_APP_TYPE === 'local' || (this.$route.query && this.$route.query.isDebug === true)) {
              console.log(error)
              console.log(error.response);
            }
          });

    },

    sendResult() {
      let self = this;

      axios.post(process.env.VUE_APP_DOMAIN + 'php/sendpulse.php', {
        name: this.subject,
        description_short: this.resultTextarea1,
        description: this.resultTextarea2,
        description_short_en: this.resultTextarea1en,
        description_en: this.resultTextarea2en
      })
          .then(function (response) {
            console.log(response);

            if (response.data.status === "error" || !response.data.status) {
              alert('Не получается отправить email. Извините.')

              if (process.env.VUE_APP_TYPE === "local") {
                console.log('response error', response.data);
              }
            }
            else {
              self.isShowResultEmail = false;
              self.isShowResultSent = true;

              // Отправляем событие в google tag
              self.$gtag.event('email_was_sent');
            }

          })
          .catch(function (error) {
            if (error) {
              alert('Не получается отправить email. Извините.')
              self.isEmailServiceError = true;
              if (process.env.VUE_APP_TYPE === "local") {
                console.log('error', error);
              }
            }
            self.isShowResultEmail = true;
            self.isShowResultSent = false;
          });
    },

    // Скопировать результат
    copyResult: function (number) {
      let text = '';
      text = this.$refs['result' + number].innerText
      this.$copyText(text);

      // Сменить текст на кнопке
      //this.$refs['resultBtn' + number].innerText = 'Скопировано';
    },
    // Ходим по шагам результатов на стрелочках
    changeResult: function (currentStep, action) {

      // Скрываем все результаты
      this.isShowResults = [false, false, false, false, false];

      // console.log('isShowResults', this.isShowResults);
      if (currentStep === 1 && action === 'prev') {
        this.isShowResults[0] = true;
        // this.isShowResult1 = true;
      }
      else if (action === 'next') {
        // заполнение данных
        // this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
        // this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;

        // Меняем шаг
        this.isShowResults[currentStep] = true;

        if (currentStep === 5) {
          this.isShowResultAll = true;
        }

      }
      else {
        // console.log('currentStep', currentStep - 1);
        this.isShowResults[currentStep - 2] = true;
        // var prevStep = currentStep - 1;
        // console.log('else');
      }

      // console.log('res', this.isShowResults);

      // console.log('step', currentStep);
      // console.log('action', action);
    },

    // Ходим по шагам результатов
    changeResultPager: function (step) {

      // Скрываем все результаты
      this.isShowResults = [false, false];

      let currentStep = step - 1;

      // if (this.$refs['result' + currentStep]) {
      //   this["resultTextarea" + currentStep] = this.$refs['result' + currentStep].innerText;
      // }

      // if (this.$refs['result' + currentStep + 'en']) {
      //   this["resultTextarea" + currentStep + "en"] = this.$refs['result' + currentStep + 'en'].innerText;
      // }

      this.isShowResults[currentStep] = true;

    },
  }
}
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>