<template>
  <div class="page">
    <div class="container container_main">

      <template v-if="isDebug">{{$route.params}}</template>

      <div class="l-plans">
        <div class="l-container">

          <div class="l-plans__wrapper">

            <div class="l-plans__title">Оформить подписку</div>
            <div class="l-plans__subtitle">Единый доступ к работе и знакомствам</div>

            <div class="l-plans__item">
              <div class="l-plans__item-title">Для работы</div>
              <div class="l-plans__item-text">
                <ul>
                  <li>50 профессий</li>
                  <li>5000 уникальных фраз</li>
                  <li>На русском и английском языках</li>
                </ul>
                <a href="#" @click="$router.push('/jobs')">Посмотреть профессии</a>
              </div>
              <div class="l-plans__item-title">Для знакомств</div>
              <div class="l-plans__item-text l-plans__item-text_small-margin">
                <ul>
                  <li>15 разноплановых анкет</li>
                  <li>1500 уникальных фраз</li>
                  <li>На русском и английском языках</li>
                </ul>
              </div>
              <a class="l-plans__item-button" href="#" @click.prevent.once="buy()">Оформить</a>
              <div class="l-plans__item-price">{{price}} ₽ · 30 дн</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isDebug">
        <span @click.prevent.once="buy(1, 1)" style="color:transparent; font-size: 0">Тестовая оплата</span>
      </div>

    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "Pay",
  data() {
    return {
      price: 149
    }
  },
  mounted() {
    // Если есть параметры, отправляем сразу на покупку
    if (this.$route.params.type && this.$route.params.amount) {
      this.buy()
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  methods: {
    buy() {

      // Если не авторизован, то отправляем сначала на регистрацию/авторизацию
      if (!this.$store.getters.isAuthenticated) {
        if (!this.$store.getters.isAuthenticated) {
          this.$router.push({name: 'registration', params: {destination: 'pay', type: 1, amount: this.price}})
        }
      } else {
        // Отправляем на оплату
        this.$router.push({name: 'payAction', params: {destination: 'pay', type: 1, amount: this.price}})
      }




      /*
      // Устаревший способ оплаты
      axios({
        url: process.env.VUE_APP_API + 'pay',
        method: 'POST',
        data: {
          type: type,
          amount: amount
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then((resp) => {
            if (resp.data.redirect) {
              window.location.href = resp.data.redirect
            }
          })
          .catch(err => {

            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log(err)
              console.log(err.response);
            }
          })
          */
    },
  }
}
</script>

<style scoped>
.page {
  background-color: #f1f1f2;
  min-height: 850px;
}
.l-plans__item-price_additional {
  margin-top: 0;
  font-weight: 400;
}
</style>