<template>
  <div class="promo__button" :class="classes" @click="next()">{{text}}</div>
</template>

<script>
export default {
  name: "PromoButton1",
  props: ['text', 'classes'],
  methods: {
    next() {
      this.$emit('clickNext')
    }
  }
}
</script>

<style scoped>

</style>