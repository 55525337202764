<template>
  <div class="lds-ring" :class="{'lds-ring_centered': isCentered}" v-if="!isLoaded"><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
  name: "Preload",
  props: ['isLoaded', 'isCentered']
}
</script>

<style scoped>

</style>