<template>
  <div>

    <!--<Menu :isNeedOpen="openMenu" @openMenu="onOpenMenu" isSpecialHeader="true"/>-->

    <div class="full-page full-page_grey full-page_flex-column full-page_wrap full-page_examples">
      <div class="container">

        <div class="descriptions__header">
          <div class="descriptions__back" @click="back()"></div>
          <div class="descriptions__title">Примеры фраз</div>
        </div>

        <div class="phrases phrases_examples">
          <div class="phrases__container">
            <div class="phrases__list phrases__list_examples">
              <div class="swiper-container"
                   ref='mySwiper'
                   v-swiper:mySwiper="swiperOptions"
                   :auto-update="true"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide" :class="'slide' + index"
                       :ref="'slide' + index"
                       :key="index"
                       v-for="(slide, index) in slides"
                  >

                    <div class="phrases__item phrases__item_example">
                      <div class="phrases__header">
                        <div class="phrases__avatar"><img :src="'/img/content/phrases-example_' + incrementIndex(index) + '.png?v=2'" :srcset="'/img/content/phrases-example_' + incrementIndex(index)  + '@2x.png?v=2 2x'"></div>
                        <div class="phrases__info">
                          <div class="phrases__name">{{ slide.name }}</div>
                          <div class="phrases__work">{{ slide.work }}</div>
                        </div>
                      </div>
                      <div class="phrases__title">{{ slide.title }}</div>
                      <div class="phrases__text">{{ slide.text }}</div>
                      <div class="phrases__pro" v-if="slide.isPro">PRO</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="phrases__dots phrases__dots_examples swiper-pagination swiper-pagination-bullets"></div>


            <div class="phrases__bottom" v-if="isNeed">
              <p>Создать описание</p>
              <div class="phrases__buttons">
                <div class="btn btn_bg-grey" @click="$router.push('/phrases')">Общее</div>
                <div class="btn btn_bg-grey" @click="$router.push({name:'pro', params: {isBack: 'true', destination: 'examplePhrases'}})">PRO</div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <!--<phrase-popup @closePopup="onHidePopup" @clickAdd="onClickAdd" :show="setPopupShow" :phraseTitle="phraseTitle" :phraseText="phraseText"/>-->

  </div>
</template>

<script>
import popupCloseMixin from "@/mixins/popupClose";
import {directive} from "vue-awesome-swiper";
// import Menu from "@/components/Menu";

export default {
  name: "ExamplePhrases",
  mixins: [popupCloseMixin],
  directives: {
    swiper: directive
  },
  components: {
    // Menu,
  },

  data() {
    return {
      isNeed: false,
      openMenu: false,
      //region Slider
      slides: [
        {
          name: "Максим, 25",
          work: "Видеооператор",
          title: "Использовал оригинал",
          text: "Обладаю навыками создания и редактирования контента для социальных сетей. Имею опыт разноплановой видеосъемки для YouTube-каналов.",
        },
        {
          name: "Алина, 21",
          work: "Студент МГЛУ",
          title: "Отредактировала вуз, добавила иностранные языки",
          text: "Учусь в МГЛУ на четвертом курсе бакалавриата. Владею английским (С1) и французским (С1) языками.",
        },
        {
          name: "Анна, 24",
          work: "SMM-менеджер",
          title: "Изменила цифру",
          text: "Интересы собственника бизнеса воспринимаю как свои личные. Ранее сумела снизить стоимость целевого действия на 10 процентов и тем самым улучшить коэффициент ROI.",
        },
        {
          name: "Евгений, 28",
          work: "Frontend-разработчик",
          title: "Использовал оригинал",
          text: "Умею работать без фреймворков. Ценю, когда в проекте имеется адекватная степень свободы и гибкости, которые позволяют выстраивать собственную архитектуру.",
        },
        {
          name: "Сергей, 19",
          work: "Студент РТУ МИРЭА",
          title: "Добавил софт и язык программирования",
          text: "Имею навыки сложных математических расчетов (уверенный пользователь Mathcad, С++).",
        },
        {
          name: "Диана, 29",
          work: "Дизайнер интерьеров",
          title: "Использовала оригинал",
          text: "Восхищаюсь работами Кристофера Холла. Мне нравится, как он сочетает стамбульские мотивы с западным минимализмом. Это по-настоящему круто!",
        },
        {
          name: "Ольга, 25",
          work: "Блогер",
          title: "Сочинила на основе оригинальной фразы",
          text: "Я из Волгодонска. Там очень красивая природа, и я до сих пор чувствую с ней связь. Не удивительно, что в Москве (и ее окрестностях) мое любимое место – Мещерский лес и особенно Мещерское озеро.",
        },
        {
          name: "Матвей, 23",
          work: "Выпускник МИФИ",
          title: "Использовал оригинал",
          text: "Планирую обучиться ораторскому мастерству. Понимаю, насколько важно уметь доносить собственные идеи в ясной, лаконичной и аргументированной форме.",
        },
        {
          name: "Яна, 24",
          work: "Пользователь Bumble",
          title: "Добавила иронии",
          text: "Люблю стрит-арт. Если рисуешь граффити, я твой тайный почитатель. Без ума от творчества Future 2020. Мечтаю сделать у него заказ и нарисовать что-нибудь дерзкое и неформатное у себя на стене (осталось только купить квартиру 😂).",
        }
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: true,
        slidesPerView: 1,
      },
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      setPopupShow: false,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 1,
      isProCategory: false,
      isDataLoaded: false,
      dynamicValue: false
    }
  },
  methods: {
    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        const categoryType = localStorage.getItem('category_type');
        if (categoryType) {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
        }
        else if (this.$route.params && this.$route.params.type) {
          this.$router.push({name: 'want'})
        }
        else {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
        }
      }
    },
    incrementIndex(val) {
      return val + 1
    },
    onOpenMenu() {
      // console.log('ok');
      this.openMenu = false
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>