<template>
  <div>

    <div class="phrases phrases_examples">
      <div class="phrases__container">
        <div class="phrases__list phrases__list_examples">
          <div class="swiper-container"
               ref='mySwiper'
               v-swiper:mySwiper="swiperOptions"
               @slideChange="changeSwiperIndex"
               :auto-update="true"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class="'slide' + index"
                   :ref="'slide' + index"
                   :key="index"
                   v-for="(slide, index) in filteredData"
              >

                <div class="phrases__item phrases__item_example phrases__item_music">
                  <div class="phrases__header phrases__header_descriptions">
                    <div class="phrases__avatar"><img :src="'/img/content/music-example_' + slide.avatar + '.png'" :srcset="'/img/content/music-example_' + slide.avatar  + '@2x.png 2x'"></div>
                    <div class="phrases__info">
                      <div class="phrases__name">{{ slide.name }}</div>
                      <div class="phrases__work">{{ slide.work }}</div>
                    </div>
                  </div>
                  <div class="phrases__title phrases__title_descriptions" v-html="slide.title"></div>
                  <div class="phrases__text"><div v-html="slide.text"></div><div class="phrases__open" @click="openPopup(slide)" v-if="!slide.hideFull">ЕЩË</div></div>
                  <div class="phrases__pro" v-if="slide.isPro">PRO</div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="phrases__dots phrases__dots_examples swiper-pagination swiper-pagination-bullets" :class="{'phrases__dots_flex': isSlidesCountMass}"></div>
        <div class="phrases__bottom" v-if="isNeed">
          <p>Создать описание</p>
          <div class="phrases__buttons">
            <div class="btn btn_bg-grey" @click="$router.push('/phrases')">Общее</div>
            <div class="btn btn_bg-grey" @click="$router.push({name:'pro', params: {isBack: 'true', destination: 'exampleDescriptions'}})">PRO</div>
          </div>
        </div>
      </div>
    </div>

    <div class="description-full" :class="{'js-active': isPopupShow}">
      <div class="description-full__wrapper">
        <div class="description-full__close" @click="closePopup"></div>
        <div class="container">
          <div class="description-full__text" v-html="handleNewLine(popupDescription)"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {directive} from "vue-awesome-swiper";
// import Menu from "@/components/Menu";

export default {
  name: "MusicExamples",
  directives: {
    swiper: directive
  },
  components: {
    // Menu,
  },
  data() {
    return {
      isNeed: false,
      isSlidesCountMass: false,
      openMenu: false,
      //region Slider
      slides: [
        {
          avatar: 1,
          name: "Анна, 24",
          text: "<b>Поклонница Rock’N’Roll</b>. Любимый исполнитель – Mr. Big. Это тот самый случай, когда ты выбираешь сердцем где-то в подростковом возрасте. Слушаешь 24/7, вешаешь постеры на стену и испытываешь сумасшедший драйв на их концерте." +
              "<br><br>Люблю послушать мастодонтов вроде Guns N’ Roses, Aerosmith, Bon Jovi и Extreme." +
              "<br><br><b>Обожаю Jazz</b>. Особенно в воскресенье вечером, где-то после девя...",
          textFull: "<b>Поклонница Rock’N’Roll</b>. Любимый исполнитель – Mr. Big. Это тот самый случай, когда ты выбираешь сердцем где-то в подростковом возрасте. Слушаешь 24/7, вешаешь постеры на стену и испытываешь сумасшедший драйв на их концерте.\n\n" +
              "Люблю послушать мастодонтов вроде Guns N’ Roses, Aerosmith, Bon Jovi и Extreme.\n\n" +
              "<b>Обожаю Jazz</b>. Особенно в воскресенье вечером, где-то после девяти. В этот период как-то особенно спокойно. Начинаю с Purple Rain в исполнении Etta James, это просто шедевр.\n\n" +
              "<b>Слушаю классическую музыку.</b> Наверное, это выдает во мне выпускницу музыкальной школы 🙂 Мне нравится слушать симфонический оркестр.\n\n" +
              "<b>Играю на фортепиано</b>. Композиция Hero в исполнении Mariah Carey получается у меня, пожалуй, лучше всего. Только я не пою 😊\n\n" +
              "Вокал и текст композиции для меня важнее мелодии и общего ритма. В общем, я из тех, кто вслушивается в слова и улавливает их смысл.\n\n" +
              "Из сильных вокалистов отдаю предпочтение Whitney Houston, Celine Dion и, конечно, Mariah Carey.\n\n" +
              "А еще я фанатка <b>Michael Jackson</b>. И у меня есть саундтрек моей жизни – Stand Up & B Strong в исполнении Prince.\n\n" +
              "В последнее время все чаще <b>слушаю российскую поп-музыку</b>. Слышу все больше по-настоящему качественной и самобытной музыки. Это здорово!\n\n" +
              "Была на концертах Mr. Big, Kiss и Scorpions в Берлине (я долгое время там жила), Aerosmith и Noel Gallagher в Москве.",
        },
        {
          avatar: 2,
          name: "Роман, 19",
          text: "Я <b>настоящий меломан</b>. Музыка для меня все.<br><br>" +
              "И у меня есть одна особенность – если мне что-то нравится, я заслушиваю это «до дыр». Плейлисты – мое спасение.<br><br>" +
              "<b>Фанат хип-хопа</b> – русского и зарубежного. Чаще всего слушаю Dr. Dre, Lil Wayne, Travis Scott, Kaney West, Скриптонит и Басту." +
              "<br><br>И я <b>без ума от Noize MC</b>. Ценю его смелость и изобрета...",
          textFull: "Я <b>настоящий меломан</b>. Музыка для меня все.\n" +
              "\n" +
              "И у меня есть одна особенность – если мне что-то нравится, я заслушиваю это «до дыр». Плейлисты – мое спасение.\n" +
              "\n" +
              "<b>Фанат хип-хопа</b> – русского и зарубежного. Чаще всего слушаю Dr. Dre, Lil Wayne, Travis Scott, Kaney West, Скриптонит и Басту.\n" +
              "\n" +
              "И я <b>без ума от Noize MC</b>. Ценю его смелость и изобретательность.\n" +
              "\n" +
              "<b>Регулярно слушаю Nirvana</b>. Наверное, лет с двенадцати. Но старшие говорят, что это все же временно – внутренний бунт со временем пройдет 😂 \n" +
              "\n" +
              "<b>Играю на гитаре</b> (только я не пою). Предпочитаю fingerstyle, когда мелодию как будто «собираешь» нотами на грифе.\n" +
              "\n" +
              "В последнее время <b>увлекаюсь обычной «легкой» музыкой</b> – типа Empire of the Sun и Milky Chance.\n" +
              "\n" +
              "Кто еще в моей медиатеке… ну, конечно, иконы поп-жанра в роде Alicia Kyes, Justin Bieber, Beyonce, Rihanna и Lady Gaga. Ed Sheeran тоже ничего 😉\n" +
              "\n" +
              "Кстати, <b>мне нравится музыка на итальянском языке</b>. Ничего не понимаю, но, кажется, это и не нужно – тут все дело в атмосфере и чувствах 🙂\n" +
              "\n" +
              "Francesco Regna и Laura Pausini – мои любимые исполнители.\n" +
              "\n" +
              "В бесконечном споре о том, что лучше – Apple Music или Spotify, <b>выбираю Deezer</b>. Ты?",
        },
        {
          avatar: 3,
          name: "Алексей, 28",
          text: "<b>Любимый исполнитель – Noel Gallagher</b>. Впервые я услышал Oasis, когда учился играть на гитаре, и песни Ноела стали моей гаванью. Когда-то я играл и исполнял, кажется, весь его репертуар.<br><br>" +
              "<b>Поклонник Depeche Mode</b>. Всё благодаря моей сестре. Она на десять лет меня старше, и Depeche Mode был суперпопулярен среди ее сверстников. Когда все тащились от Enjoy the Silence, я кайфов...",
          textFull: "<b>Любимый исполнитель – Noel Gallagher</b>. Впервые я услышал Oasis, когда учился играть на гитаре, и песни Ноела стали моей гаванью. Когда-то я играл и исполнял, кажется, весь его репертуар." +
              "\n" +
              "<b>Поклонник Depeche Mode</b>. Всё благодаря моей сестре. Она на десять лет меня старше, и Depeche Mode был суперпопулярен среди ее сверстников. Когда все тащились от Enjoy the Silence, я кайфовал от Policy of Truth.\n" +
              "\n" +
              "Если говорить о жанрах, <b>мой выбор - R&B</b>. Под зажигательный ритм, хочется двигаться и что-то делать, а под мелодичный – легко замечтаться. Возможно, поэтому я так сильно влюблен в эту музыку.\n" +
              "\n" +
              "Сейчас мне крайне импонирует певица Snoh Aalegra. Ее трек LOST YOU – это просто 💣 Он уносит меня куда-то совсем далеко…\n" +
              "\n" +
              "Часто <b>слушаю электронную музыку</b>. Предпочитаю мелодичный house – когда ритм и танцевальный, и не слишком танцевальный одновременно.\n" +
              "\n" +
              "Мелодия и звук для меня важнее вокала и слов. Не удивительно, что house-треки без исполнителя мне нравятся сильнее всего. \n" +
              "\n" +
              "В последнее время заслушиваюсь Begin Again в исполнении Ben Bohmer и Phenomena от Shingo Nakamura.\n" +
              "\n" +
              "<b>В прошлом поклонник Rock’N’Roll</b>. Могу «тряхнуть стариной» и послушать Guns N’ Roses и AC/DC – Т.N.T. и Big Gun особенно хороши.\n" +
              "\n" +
              "А еще я <b>без ума от джаза</b>. \n" +
              "\n" +
              "Увы, я очень чувствителен к звуку. Кажется, я <b>аудиофил</b>. Если хочешь, смогу объяснить тебе разницу между Hi-Fi и Hi-End – ведь это очень полезная информация 😂\n",
          type: 'resume',
          type_id: 0
        },
        {
          avatar: 4,
          name: "Кристина, 22",
          text: "Может прозвучать банально, но <b>мне нравится обычная поп-музыка</b>. Люблю LANY. Я все время думала, что это имя. Оказалось, это аббревиатура из двух слов – Los Angeles и New York.<br><br>" +
              "В общем, любят ребята сокращения. Название их последнего альбома – gg bb xx. Что бы это значило...<br><br><b>Мне нравится Weeknd</b>, Ariana Grande, Dua Lipa, Zivert и Муся Тоти...",
          textFull: "Может прозвучать банально, но <b>мне нравится обычная поп-музыка</b>. Люблю LANY. Я все время думала, что это имя. Оказалось, это аббревиатура из двух слов – Los Angeles и New York. \n" +
              "\n" +
              "В общем, любят ребята сокращения. Название их последнего альбома – gg bb xx. Что бы это значило...\n" +
              "\n" +
              "<b>Мне нравится Weeknd</b>, Ariana Grande, Dua Lipa, Zivert и Муся Тотибадзе.\n" +
              "\n" +
              "Ох, и я <b>без ума от Sophie Ellis-Bextor</b> и Roisin Murphy. Трек Murphy’s Law второй из них особенно хорош! Кстати, отличная игра слов.\n" +
              "\n" +
              "Я поняла. Я назвала бы эту музыку так – поп с ярко выраженным танцевальным ритмом :)\n" +
              "\n" +
              "Конечно, моя музыка зависит от настроения. Если на душе тоскливо, включаю I Don’t Wanna Know в исполнении James Smith. И делаю погромче.\n" +
              "\n" +
              "А если весело, включаю Nice to Meet Ya ирландца Niall Horan – и просто начинаю прыгать :)\n" +
              "\n" +
              "И я довольно сентиментальная личность. <b>Люблю песни про любовь</b>. Моя любимая – Hello, которую исполняет Adele (главное в очередной раз не расплакаться).\n" +
              "\n" +
              "К слову, частенько <b>слушаю Ed Sheeran</b>. Мне нравится то, насколько его песни простые и эмоциональные одновременно.\n" +
              "\n" +
              "И я вдруг осознала, что никогда не была ни на одном настоящем концерте. Странно, почему...\n" +
              "\n" +
              "<b>Зато я классно пою</b>. По крайней мере, так говорят :) \n",
        },
        {
          avatar: 5,
          name: "Ольга, 20",
          text: "Музыку слушаю разную. <b>Обожаю Arctic Monkeys.</b><br><br>" +
              "<b>Фанатка Radiohead</b> и творчества Тома Йорка, в частности. Его музыка ни на что не похожа, и это очень круто! Kid A для меня – их лучший альбом." +
              "<br><br>Часто <b>слушаю электронную музыку</b>. В ней я растворяюсь полностью – и менталь...",
          textFull: "Музыку слушаю разную. <b>Обожаю Arctic Monkeys.</b>\n" +
              "\n" +
              "<b>Фанатка Radiohead</b> и творчества Тома Йорка, в частности. Его музыка ни на что не похожа, и это очень круто! Kid A для меня – их лучший альбом.\n" +
              "\n" +
              "Часто <b>слушаю электронную музыку</b>. В ней я растворяюсь полностью – и ментально, и физически.\n" +
              "\n" +
              "DJ Tiesto, Armin van Buuren и Paul van Dyk – «мои ребята» 😜\n" +
              "\n" +
              "<b>Окончила музыкальную школу</b> по классам «Фортепиано» и «Эстрадно-джазовый вокал».\n" +
              "\n" +
              "<b>Играю на укулеле</b>. Хотела научиться на гитаре, но друзья решили иначе – и подарили мне этот сказочный гавайский инструмент 🙂\n" +
              "\n" +
              "И я поклонница <b>Noize MC</b>.\n" +
              "Мне нравится, как он поет и читает тексты. Всегда по-разному. Ценю, что он затрагивает серьезные темы.\n" +
              "\n" +
              "Удивительно, но <b>меня начинает тянуть в сторону K-pop</b>, до сих пор не могу найти причину (возможно, ее просто нет…). BLACKPINK то и дело все чаще звучит в моих наушниках.\n" +
              "\n" +
              "Кстати, влюблена в музыку настолько, что <b>сочиняю сама</b>.\n",
        },
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: false,
        slidesPerView: 1,
      },
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 1,
      isProCategory: false,
      isDataLoaded: false,

      popupTitle: '',
      popupDescription: '',
      isPopupShow: false,
      filter: ''
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    filteredData() {
      if (!this.filter) {
        return this.slides;
      }
      return this.slides.filter(p => p.type === this.filter);
    }
  },
  methods: {
    // При смене слайда
    changeSwiperIndex() {

    },
    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        const categoryType = localStorage.getItem('category_type');
        if (categoryType) {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
        }
        else if (this.$route.params && this.$route.params.type) {
          this.$router.push({name: 'want'})
        }
        else {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
        }
      }
    },
    init() {

      // Фильтрация по категории
      if (this.$route.params && this.$route.params.type) {
        this.filter = this.$route.params.type;
      }
      // Если слайдов > 10
      if (this.filteredData.length > 9) {
        this.isSlidesCountMass = true;
      }
    },
    incrementIndex(val) {
      return val + 1
    },

    handleNewLine(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    openPopup(slide) {
      // this.currentSlide = this.mySwiper.activeIndex;
      // localStorage.setItem('lastSlideDescriptions', this.mySwiper.activeIndex);
      // console.log('lastSlideDescriptions', localStorage.getItem('lastSlideDescriptions'));
      // console.log('slide', slide);
      this.isPopupShow = true;
      this.popupTitle = slide.title;
      this.popupDescription = slide.textFull;

    },
    // Открытие меню
    onOpenMenu() {
      this.openMenu = false
    },

    closePopup() {
      this.isPopupShow = false;

      // const lastSlide = localStorage.getItem('lastSlideDescriptions');
      // if (lastSlide) {
      //   this.mySwiper.slideTo(parseInt(lastSlide), 1000, false);
      // }
    }
  }
}
</script>

<style scoped>

</style>