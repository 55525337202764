<template>
  <div class="full-page full-page_p-top full-page_relative">
    <div class="container container_full-height">
      <div class="descriptions__header">
        <div class="descriptions__back" @click="back()"></div>
        <div class="descriptions__title">Настройки</div>
      </div>
      <preload :isLoaded="isLoaded" v-if="!isLoaded"/>

      <div class="settings" v-if="isLoaded">
        <div class="settings__item settings__item_noarrow" :class="phraseClass">
          <div class="settings__title">{{ phrase }}</div>
          <div class="settings__change" @click="changeSex()">Изменить</div>
        </div>
        <div class="settings__item settings__item_login" @click="go('login')" v-if="!isAuth">
          <div class="settings__title">Войти в аккаунт</div>
        </div>
        <div class="settings__item settings__item_register" @click="go('registration')" v-if="!isAuth">
          <div class="settings__title">Зарегистрироваться</div>
        </div>
      </div>
    </div>
    <div class="container" v-if="isAuth && isLoaded">
      <div class="settings-logout" @click="$router.push('/logout')">Выйти из аккаунта</div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Preload from "@/components/Preload";

export default {
  name: "Settings",
  components: {Preload},
  data() {
    return {
      phrase: "Фразы в мужском роде",
      phraseMan: "Фразы в мужском роде",
      phraseWoman: "Фразы в женском роде",
      phraseClass: 'settings__item_man',
      phraseClassMan: 'settings__item_man',
      phraseClassWoman: 'settings__item_woman',

      isAuth: false,

      isLoaded: false
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false;
    },
  },
  mounted() {
    this.getSex();
    this.checkIfIsAuth();
  },
  methods: {
    back() {
      const categoryType = localStorage.getItem('category_type');
      if (categoryType) {
        this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
      }
      else if (this.$route.params && this.$route.params.type) {
        this.$router.push({name: 'main'})
      }
      else {
        this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
      }
    },
    // Проверка на авторизацию
    checkIfIsAuth() {
      store.dispatch('checkTokenSimple').then(() => {
        // console.log('done')
        if (localStorage.getItem('token')) {
          this.isAuth = true
        }
        else {
          this.isAuth = false
        }
        this.isLoaded = true;
      })
    },
    go(url) {
      this.$router.push({name: url})
    },
    // Сменить пол
    changeSex() {
      if (localStorage.getItem('sex') === "man") {
        localStorage.setItem('sex', 'woman');
      }
      else {
        localStorage.setItem('sex', 'man');
      }
      // Меняем класс для пункта с родом
      this.getSex()
    },

    // Определяем пол и меняем класс и фразу в пункте
    getSex() {
      if (localStorage.getItem('sex') === "man") {
        this.phrase = this.phraseMan;
        this.phraseClass = this.phraseClassMan;
      }
      else {
        this.phrase = this.phraseWoman;
        this.phraseClass = this.phraseClassWoman;
      }
    }
  }
}
</script>

<style scoped>
.create__block {
  cursor: pointer;
}
</style>