<template>
  <footer class="l-footer">
    <div class="block-contacts" :class="{'js-active': isContactsActive}">
      <div class="block-contacts__title">По всем вопросам пишите</div>
      <div class="block-contacts__email"><a href="mailto: info@bestme.nl">info@bestme.nl</a></div>
      <div class="block-contacts__close" @click="isContactsActive = false">закрыть</div>
    </div>
    <div class="l-container">
      <div class="l-footer__menu">
        <ul>
          <li><a href="#" @click.prevent="clickMenuLink('terms')">Условия Соглашения</a></li>
          <li><a href="#" @click.prevent="clickMenuLink('privacy')">Политика конфиденциальности</a></li>
          <!--<li><a href="#" @click.prevent="clickMenuLink('faq')">Вопрос – Ответ</a></li>-->
          <li><a href="#" @click.prevent="isContactsActive = !isContactsActive">Контакты</a></li>
          <!--<li><a href="#" @click.prevent="clickMenuLink('security')">Оплата</a></li>-->
          <li><a href="#" @click.prevent="">18+</a></li>
        </ul>
      </div>
      <div class="l-footer__desc">Все представленные на сайте фотографии являются фотографиями моделей и используются исключительно в демонстрационных целях.</div>
      <div class="l-footer__copyright">©  Best Me  · {{ year }} ·  Все права защищены.</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterFront",
  data() {
    return {
      isContactsActive: false,
      year: 2021
    }
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
  methods: {
    // Клик по пункту меню
    clickMenuLink(link) {
      const path = '/' + link;
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }

  }
}
</script>

<style scoped>

</style>