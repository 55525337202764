<template>
  <div class="full-page full-page_p-top-25">
    <div class="container">
      <!--<div class="description-to-top" @click="toTop"></div>-->
      <div class="descriptions">
        <div class="descriptions__header">
          <a href="#" class="descriptions__back" @click.prevent="back()"></a>
          <div class="descriptions__title" v-if="isDataLoaded">{{saved_name}}</div>
        </div>
        <div class="descriptions__body">
          <!--<div class="descriptions__name">Описание 1</div>-->

          <preload :isLoaded="isDataLoaded" :is-centered="true" />

          <template v-if="isDataLoaded">
            <template v-if="isResume">
              <h2>Деловые качества</h2>
              <textarea-autosize
                  class="descriptions__text"
                  placeholder="Введите что-нибудь"
                  ref="myTextarea"
                  v-model="textarea_resume_text"
                  :min-height="30"
              />

              <h2>Личные качества</h2>
            </template>

            <textarea-autosize
                v-if="isDataLoaded"
                class="descriptions__text"
                placeholder="Введите что-нибудь"
                ref="myTextarea"
                v-model="textarea_text"
                :min-height="30"
            />
          </template>

          <div class="descriptions__bottom" v-if="isDataLoaded">
            <div class="descriptions__delete" @click="remove()"></div>
            <div class="descriptions__save btn btn_bg-dark" @click="save()">Сохранить</div>
            <div class="flex-break"></div>
            <div class="descriptions__copy btn btn_skip" @click="copy()">{{copyText}}</div>
          </div>

        </div>
      </div>
    </div>

    <div class="popup popup_delete" v-if="isDeleteJobPopupShow">
      <div class="popup__body">
        <div class="popup__title">Удалить?</div>
        <div class="popup__actions">
          <button class="btn btn_ghost" type="button" @click="removeReal()">Да</button>
          <button class="btn btn_ghost" type="button" @click="isDeleteJobPopupShow = false">Нет</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Preload from "@/components/Preload";

export default {
  name: "Saved",
  components: {Preload},
  data() {
    return {
      fullText: '',
      textarea_text: '',
      textarea_resume_text: '',
      isResume: false,
      isDataLoaded: false,
      copyText: 'Скопировать',
      saved_name: '',
      isDeleteJobPopupShow: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // todo: Запрос к серверу, чтобы получить список сохраненных
    // Получение данных
    getData() {
      axios({
        url: process.env.VUE_APP_API + 'saved-phrases/' + this.$route.params.id,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('saved-phrases/{id}', resp);
            }
            if (resp.status === 200 && resp.data) {
              this.saved_name = resp.data.name;
              this.textarea_text = resp.data.text;

              if (resp.data.resume_text !== null) {
                this.textarea_resume_text = resp.data.resume_text;
                this.isResume = true;
              }
              this.isDataLoaded = true
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })

    },

    // Удаление
    remove() {
      this.isDeleteJobPopupShow = true;

      //
    },
    removeReal() {
      axios({
        url: process.env.VUE_APP_API + 'saved-phrases/' + this.$route.params.id,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      // this.$router.push('/saved');
      this.back();
    },

    // сохранение
    save() {
      this.isDataLoaded = false;
      const self = this;
      axios({
        url: process.env.VUE_APP_API + 'saved-phrases/' + this.$route.params.id,
        method: 'POST',
        data: {
          text: self.textarea_text,
          resume_text: self.textarea_resume_text
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(resp => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              // console.log('phrases/{category_id}', resp);
            }
            if (resp.status === 200 && resp.data) {
              this.textarea_text = resp.data.text
              this.textarea_resume_text = resp.data.resume_text
              this.isDataLoaded = true
            }

          })
          .catch(err => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log('err', err);
            }
          })
    },

    // Копировать
    // todo: ИСПРАВИТЬ ПОД РЕЗЮМЕ!!!
    copy() {
      this.copyText = 'Скопировано'
      if (this.isResume) {
        let text = '';
        text += 'Деловые качества\n\n';
        text += this.textarea_resume_text;
        text += '\n\n\n';
        text += 'Личные качества\n\n'
        text += this.textarea_text;
        this.$copyText(text);
      } else {
        this.$copyText(this.textarea_text);
      }
    },

    toTop() {
      // window.scrollTo(0,0);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      setTimeout(() => this.$router.push('/saved'), 400);
    },

    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        this.$router.push({name: 'saved'})
      }
    },
  }
}
</script>

<style scoped>

</style>