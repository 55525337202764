<template>
  <div class="container container_main">
    <div class="text-page">
      <a class="btn btn_back" href="#" @click.prevent="back()">&lt; Назад</a>
      <h1>Соглашение об условиях использования Вебсайта</h1>
      <h4>Преамбула</h4>
      <p>Настоящее Соглашение об использовании Вебсайта (далее – Соглашение) заключается между ООО «ПАРС», именуемое в дальнейшем «Компания», местонахождение: 105023, Москва, улица Парковая 14, дом 8, офис Э/ПОМ//К/ОФ5/I/26А2Е, именуемой в
        дальнейшем «Администрация» и любым лицом, намеревающимся использовать и/или использующим Вебсайт в сети Интернет <a href="https://bestme.nl" target="_blank">https://bestme.nl</a> (далее – «Вебсайт» или «Best Me»), именуемым в
        дальнейшем «Пользователь». </p>
      <h4>Общие положения</h4>
      <p>Этот Вебсайт позволяет Пользователю создавать описание его/ее профессиональных и человеческих качеств (далее – «Описание») для поиска работы и/или своей второй половины. Описание может размещаться Пользователем на ресурсах,
        предназначенных для поиска вакансий и/или для знакомств, в том числе в приложениях, веб-сайтах, социальных сетях, мессенджерах.</p>
      <p>Вебсайт не предоставляет услуги, связанные с поиском работы и/или знакомств.</p>
      <p>Настоящее Соглашение об условиях использования Вебсайта (далее – «Правила» или «Соглашение») применяются к любому использованию Вебсайта и к любым сообщениям, электронным письмам и информации «Best Me» (далее – «Сообщения»).</p>
      <p>Если Вы не принимаете настоящие Правила, Вы не можете пользоваться Вебсайтом.<br>Best Me может внести изменения в настоящие Правила в любое время и эти изменения вступают в силу немедленно после размещения по адресу <a
          href="https://bestme.nl/terms">https://bestme.nl/terms</a>.</p>
      <p>Используя Вебсайт, Вы подтверждаете, что у Вас есть необходимое оборудование, программное обеспечение и возможность пользоваться Вебсайтом, электронная почта и доступ в Интернет. Best Me не несет ответственности за то, как Вебсайт
        работает на Вашем оборудовании и с Вашим программным обеспечением.</p>
      <h4>Пользование Вебсайтом</h4>
      <p>Вы можете пользоваться Вебсайтом исключительно в личных некоммерческих целях. Соответственно Вы обязуетесь: не использовать Вебсайт с целью извлечения прибыли; не вступать без согласия Best Me в сделки с другими пользователями
        Вебсайта, использовать Вебсайт для распространения рекламы или незаконной пропаганды.</p>
      <p>Вы обязуетесь не публиковать в открытом доступе адреса электронной почты, телефонные номера, ссылки и другие контактные данные, указанные на Вебсайте и в Сообщениях.</p>
      <h4>Авторские права и другие объекты интеллектуальной собственности</h4>
      <p>Все права, программное обеспечение, дизайн, базы данных, патенты и любые права на изобретения, ноу-хау, коммерческие наименования, секреты производства, товарные знаки и обозначения товаров и услуг (как зарегистрированные, так и
        незарегистрированные) и любые их части (далее – «Права на интеллектуальную собственность»), размещенные на Вебсайте, принадлежат Best Me или используются Best Me на основании соглашений с правообладателями, если не указывается
        иное. Все указанные права закреплены за Best Me.</p>
      <p>Вы не имеете права и обязуетесь:</p>
      <ul>
        <li>не взламывать или пытаться взламывать, распространять, менять, передавать Best Me или его части, а также информацию, содержащуюся на Вебсайте для любых целей, кроме предусмотренных Правилами;</li>
        <li>использовать указанные объекты интеллектуальной собственности в публичных или коммерческих целях без предварительного письменного согласия Best Me;</li>
        <li>демонстрировать, публиковать, копировать, воспроизводить, печатать или иным образом использовать Вебсайт или содержащуюся в нем информацию (за исключением созданного на Вебсайте Описания в интересах третьих лиц или в иных
          приложениях, или на иных вебсайтах без предварительного письменного согласия Best Me;
        </li>
        <li>обрабатывать или использовать информацию, содержащуюся на Вебсайте или созданную Вами с помощью Вебсайта, в незаконных, морально низких или нечестных целях.</li>
      </ul>
      <h4>Вебсайт</h4>
      <p>Вебресурсы третьих лиц, включая приложения, вебсайты, социальные сети, мессенджеры, в которых содержатся ссылки на Best Me, не проверялись и не утверждались Best Me. Доступ на такие вебресурсы третьих лиц и их использование
        осуществляются Вами по своему усмотрению и на свой риск.</p>
      <p>Ваши личные данные (адрес электронной почты и пароль), которые используются Вами для доступа к сервисам Вебсайта, не должны разглашаться или передаваться третьим лицам без предварительного письменного согласия Best Me.</p>
      <p>В случае если Вы считаете, что ваш пароль стал известен другим лицам, Вы должны немедленно сообщить об этом Best Me (отправив письмо на адрес электронной почты info@bestme.nl), так как за неправомерные действия, совершенные с
        использованием Вашего пароля, несете ответственность Вы, а не Best Me.</p>
      <p>Все сообщения, отправленные Best Me, и их вложения предназначаются исключительно для получателей таких сообщений.</p>
      <p>Best Me может автоматически отправлять электронные письма для информирования пользователей Вебсайта о сервисах Best Me. Вы соглашаетесь, что Вы будете получать эти электронные письма.</p>
      <h4>Приватность</h4>
      <p>Когда Вы проходите опрос на Вебсайте для составления Вашего личного Описания, Best Me предлагает Вам ответить на несколько вопросов.</p>
      <p>Best Me может сохранять составленными вами Описания исключительно без привязки к вашим личным данным, а именно – адресу электронной почты и паролю. Соответственно Best Me не может идентифицировать ни одно из Описаний ни с одним из
        пользователей Вебсайта.</p>
      <p>Описания могут использоваться Best Me для: анализа ответов, которые выбирают пользователи, проходя опрос на Вебсайте, в целях улучшения пользовательского опыта и содержания фраз, которые используются в Описаниях; предоставления
        пользователям новых и усовершенствованных сервисов.</p>
      <h4>Ответственность</h4>
      <p>Best Me не несет ответственности за убытки и ущерб любого рода, включая без ограничения прямой ущерб, упущенную выгоду, даже если таковые явились следствием обстоятельств, о которых Вы уведомляли Best Me. Веst Me не обязан
        выплачивать компенсации в том числе, но, не ограничиваясь, в случаях, связанных с:</p>
      <ul>
        <li>любой неточностью информации на Вебсайте, а также перерывами и задержками в оказании услуг;</li>
        <li>нарушением любым лицом любых прав на объекты интеллектуальной собственности третьих лиц, связанным с использованием Вебсайта;</li>
        <li>любыми убытками, причиненными под воздействием вирусов или иного вредоносного программного обеспечения, которое может поражать программное обеспечение пользователя, повреждать данные и иные объекты, если это явилось следствием
          доступа на Вебсайт, использования Вебсайта или получения сообщений электронной почты от Best Me;
        </li>
        <li>доступностью, качеством и содержанием других вебресурсов, которые принадлежат и управляются третьими лицами, ссылки на которые могут быть на Вебсайте;</li>
        <li>доступностью, использованием и/или раскрытием любой информации, которую Вы предоставили Best Me, неуполномоченными третьими лицами.</li>
      </ul>
      <p>Best Me не гарантирует, что работа Вебсайта всегда будет корректной и без технических ошибок.</p>
      <p>Настоящие правила регулируются законодательством Российской Федерации. Вопросы, не урегулированные в настоящих правилах, регулируются законодательством Российской Федерации.</p>
      <p>В случае возникновения споров или разногласий, связанных с соблюдением настоящих Правил, Вы и Best Me приложите все усилия, чтобы решить их с помощью переговоров. Споры, которые не были разрешены в ходе переговоров, должны
        разрешаться в соответствии с процедурами, установленными действующим законодательством Российской Федерации.</p>
      <h4>Расторжение</h4>
      <p>Best Me имеет право расторгнуть настоящее Соглашение и немедленно прекратить доступ к Вебсайту в случае нарушения Вами любого условия настоящих Правил. Также Соглашение может быть немедленно расторгнуто любой стороной по ее
        усмотрению.</p>
      <p>Расторжение Соглашения не повлияет на права, полученные Сторонами в период действия Соглашения.</p>
      <p>Best Me не будет нести ответственность в случае невозможности выполнить обязанности по Соглашению, если такая ситуация сложилась в результате непредвиденных обстоятельств, находящихся вне разумного контроля Сторон, включая в том
        числе неполадки в работе сети Интернет, средств связи, пожары, боевые действия, наводнения или иные стихийные бедствия.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  methods: {
    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        const categoryType = localStorage.getItem('category_type');
        if (categoryType) {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
        }
        else if (this.$route.params && this.$route.params.type) {
          this.$router.push({name: 'main'})
        }
        else {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
        }
      }
    }
  }
}
</script>

<style scoped>
.text-page {
  padding-bottom: 80px;
}
</style>