<template>
  <div>
    <div class="cart-popup" :class="{'js-active' : isActive}">
      <div class="container">
        <div class="descriptions">
          <div class="descriptions__header">
            <div class="descriptions__title descriptions__title_center">Мое описание</div>
            <div class="descriptions__close" @click="close()"></div>
          </div>
          <div class="descriptions__body">

            <preload :isLoaded="isDataLoaded" :is-centered="true"/>

            <template v-if="isDataLoaded">

              <div class="descriptions__empty" v-if="!textarea_resume_text && !textarea_text">
                <p>Здесь отображаются добавленные&nbsp;вами&nbsp;фразы.</p>
                <p>Здесь вы также сможете их редактировать и&nbsp;писать собственный текст.</p>
              </div>

              <template v-if="isResume">
                <h2>Деловые качества</h2>
                <textarea-autosize
                    class="descriptions__text"
                    placeholder=""
                    ref="myTextarea"
                    v-model="textarea_resume_text"
                    :min-height="30"
                    @input="updateValue"
                />

                <h2>Личные качества</h2>
              </template>

              <textarea-autosize
                  v-if="isDataLoaded"
                  class="descriptions__text"
                  placeholder=""
                  ref="myTextarea"
                  v-model="textarea_text"
                  :min-height="30"
                  @input="updateValue"
              />
            </template>

            <div class="descriptions__bottom">
              <div class="descriptions__delete" @click="remove"></div>
              <div class="descriptions__save btn btn_bg-dark" @click="save">Сохранить</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preload from "@/components/Preload";
import axios from "axios";
import store from "@/store";

export default {
  name: "CartPopup",
  components: {Preload},
  props: ['isActive'],
  data() {
    return {
      fullText: '',
      isResume: false,
      textarea_resume_text: '',
      textarea_text: '',
      isDataLoaded: true,
      isAuth: false,
      count: 0
    }
  },
  watch: {
    isActive: {
      immediate: true,
      deep: true,
      handler() {
        if (this.isActive === true) {
          this.init();
        }
      }
    },
  },
  mounted() {

    this.init();

    // Сохраняем
    if (this.$route.params.isNeedSave) {
      this.save();
    }


  },
  methods: {

    // Если есть текст резюме, то показываем заголовки и доп.поле
    checkResume() {
      const text = this.textarea_resume_text;
      if (text && text.length) {
        this.isResume = true;
      }
      else {
        this.isResume = false;
      }

    },

    // Всё для инициализации
    init() {
      this.getData();
      this.checkIfIsAuth();
      this.getSavedCount();
      this.checkResume();
    },


    close() {
      this.$emit('closeCart')
    },

    //region Возврат
    hasHistory() {
      return window.history.length > 2
    },
    back() {
      if (localStorage.getItem('category_type')) {
        this.$router.push({name: 'phrases_types', params: {isBack: 'true', type: localStorage.getItem('category_type')}})
      }
      else {
        this.$router.push({name: 'phrases_types', params: {isBack: 'true', type: 'resume'}})
      }

      // this.hasHistory()
      //     ? this.$router.go(-1)
      //     : this.$router.push('/')
    },
    //endregion

    // Получить количество сохранённых элементов
    getSavedCount(item) {
      if (item) {
        this.count = item;
      }
      else {
        const getSavedFromLocalstorage = localStorage.getItem('saved_count');
        if (getSavedFromLocalstorage) {
          // const saved = JSON.parse(getSavedFromLocalstorage);
          // this.count = saved.length;
          this.count = getSavedFromLocalstorage;
        }
      }
    },

    // Проверка на авторизацию
    checkIfIsAuth() {
      this.$store.dispatch('checkTokenSimple');
      if (localStorage.getItem('token')) {
        this.isAuth = true
      }
      else {
        this.isAuth = false
      }
    },

    // Обновление
    updateValue() {
      if (this.textarea_resume_text) {
        localStorage.setItem('saved_resume_text', this.textarea_resume_text);
      }
      if (this.textarea_text) {
        localStorage.setItem('saved_text', this.textarea_text);
      }
    },

    // Удаление
    remove() {
      this.clearLocalStorage();
      // this.$router.push('phrases');
      this.$emit('emptyCart')
      this.close();
    },

    clearLocalStorage() {
      localStorage.setItem('saved', '');
      localStorage.setItem('saved_count', '0');
      localStorage.setItem('saved_resume_text', '');
      localStorage.setItem('saved_text', '');
    },

    // Сохранение
    save() {
      if (this.isAuth) {
        this.isDataLoaded = false;
        const self = this;
        const name = localStorage.getItem('saved_type');

        console.log('name', name);

        axios({
          url: process.env.VUE_APP_API + 'saved-phrases/',
          method: 'POST',
          data: {
            name: name,
            text: self.textarea_text,
            resume_text: self.textarea_resume_text
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
            .then(resp => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                // console.log('phrases/{category_id}', resp);
              }
              if (resp.status === 200 && resp.data) {
                if (resp.data.text) {
                  this.textarea_text = resp.data.text
                }
                if (resp.data.resume_text) {
                  this.textarea_resume_text = resp.data.resume_text
                }
                this.isDataLoaded = true


                if (localStorage.getItem('userSaved')) {
                  let newUserSaved = parseInt(localStorage.getItem('userSaved'));
                  newUserSaved = newUserSaved + 1;
                  localStorage.setItem('userSaved', newUserSaved);
                  store.commit('updateUserSaved', newUserSaved)
                }
                else {
                  localStorage.setItem('userSaved', '1')
                }

                self.clearLocalStorage();
                // self.$router.push('saved');
                self.$router.push({name: 'main'});

              }

            })
            .catch(err => {
              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log('err', err);
              }
            })
      }
      else {
        // console.log('not auth');
        this.$router.push({name: 'registration', params: {destination: 'cart'}})
      }

    },

    // Получение данных
    getData() {

      this.textarea_text = localStorage.getItem('saved_text')
      this.textarea_resume_text = localStorage.getItem('saved_resume_text')

      // const currentLocalstorage = localStorage.getItem('saved');
      //
      // if (currentLocalstorage) {
      //   const currentLocalstorageArr = JSON.parse(currentLocalstorage);
      //   this.loopData(currentLocalstorageArr.length, JSON.parse(currentLocalstorage))
      // }
    },

    loopData(len, data) {
      let i = 0;
      while (i < len) { // выводит 0, затем 1, затем 2
        this.fullText += '<p>';
        this.fullText += data[i];
        this.fullText += '</p>';
        i++;
      }
    },


    toTop() {
      // window.scrollTo(0,0);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>

</style>