<template>
  <div class="auth">
    <div class="container">

      <template v-if="isDebug">{{$route.params}}</template>

      <div class="auth__form">
        <div class="auth__toggles">
          <!--<button class="auth__toggle" type="button" @click="$router.push({name:'login', params: {destination: $route.params.destination, type: $route.params.type, amount: $route.params.amount}})">Войти</button><span>или</span>-->
          <button class="auth__toggle auth__toggle_active" type="button">Зарегистрироваться</button>
        </div>
        <!--<div class="auth__text">Чтобы пользоваться платными сервисами</div>-->
        <div class="auth__body">
          <div class="auth__field">
            <input type="text" placeholder="Ваш email" v-model="fieldEmail" @input="checkFieldEmail()">
            <div class="auth__field-text" v-if="isEmailError">Проверьте email</div>
            <div class="auth__field-text" v-if="isEmailExists">Email существует. <router-link to="login">Авторизуйтесь</router-link></div>
          </div>
          <div class="auth__field">
            <input :type="fieldPasswordType" placeholder="Придумайте пароль" v-model="fieldPassword" @input="checkFieldPassword()">
            <div class="auth__password-switcher" @click="changePasswordType()"></div>
            <div class="auth__field-text auth__field-text_error" v-if="isPasswordError">Не менее 8 символов</div>
          </div>
          <div class="auth__button">
            <div class="btn btn_bg-dark" @click="register()">Зарегистрироваться</div>
          </div>
          <div class="auth__link"><a href="#" class="auth__forgot-pw" @click.prevent="$router.push({name:'login', params: {destination: $route.params.destination, type: $route.params.type, amount: $route.params.amount}})">Войти</a></div>

        </div>
      </div>
      <div class="auth__rules">Продолжая, Вы принимаете условия <router-link to="terms" target="_blank">Соглашения</router-link></div>
    </div>
  </div>
</template>

<script>
const emailCheckRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export default {
  name: "Registration",
  data() {
    return {
      fieldEmail:'',
      fieldPassword:'',
      fieldPasswordType:'password',
      passwordLengthNeed: 8,
      isEmailError: false,
      isPasswordError: false,
      isEmailValid: false,
      isPasswordValid: false,
      isEmailExists: false
    }
  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
    // Классы для поля email
    isFieldsValid: function () {
      return this.isEmailValid && this.isPasswordValid
    },
  },
  methods: {
    checkFieldPassword() {
      if (this.fieldPassword.length < this.passwordLengthNeed) {
        this.isPasswordError = true;
        this.isPasswordValid = false;
      } else {
        this.isPasswordError = false;
        this.isPasswordValid = true;
      }
    },
    checkFieldEmail() {
      if (emailCheckRegex.test(this.fieldEmail)) {
        this.isEmailError = false;
        this.isEmailValid = true;
      } else {
        this.isEmailError = true;
        this.isEmailValid = false;
      }
    },
    changePasswordType() {
      if(this.fieldPasswordType === 'password') {
        this.fieldPasswordType = 'text'
      } else {
        this.fieldPasswordType = 'password'
      }
    },

    register() {
      if (this.isFieldsValid) {
        let email = this.fieldEmail
        let password = this.fieldPassword
        let password_confirmation = this.fieldPassword
        this.$store.dispatch('register', { email, password, password_confirmation })
            .then(() => {
              if (this.$route.params && this.$route.params.destination) {
                // this.$router.push(this.$route.params.destination)
                this.$router.push({name: this.$route.params.destination, params: {isNeedSave: 'true'}})
              } else {
                this.$router.push('/main')
              }
              // todo: Оплата
              // if (this.$route.params.type) {
              //   this.$router.push({name:'pay',
              //     params: {destination: this.$route.params.destination, type: this.$route.params.type, amount: this.$route.params.amount}});
              // } else {
              //   // this.$router.push('/')
              //   this.$router.push({name:'pay'})
              // }
            })
            .catch(err => {

              if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
                console.log(err)
                console.log(err.response);
              }

              if (err.response.data.errors.email) {
                this.isEmailError = true;
              } else {
                this.isEmailError = false;
              }

              if (err.response.data.errors.email[0] === 'The email has already been taken.') {
                this.isEmailExists = true;
                this.isEmailError = false;
              } else {
                this.isEmailExists = false;
              }

              if (err.response.data.errors.password) {
                this.isPasswordError = true;
              } else {
                this.isPasswordError = false;
              }

              if (err.response.status === 401) {
                this.isAuthError = true;
              }
              if (err.response.status === 500) {
                this.isGlobalError = true;
              }
            })
      } else {
        this.checkFieldPassword();
        this.checkFieldEmail();
      }
    },
  },
  mounted() {

    if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
      console.log('type', this.$route.params.type);
      console.log('amount', this.$route.params.amount);
    }


    if (process.env.VUE_APP_TYPE === 'local') {
      this.fieldEmail = process.env.VUE_APP_USER_EMAIL
      this.fieldPassword = process.env.VUE_APP_USER_PASSWORD
      this.isEmailValid = this
      this.isPasswordValid = this
    }
  }
}
</script>

<style scoped>

</style>