<template>
  <div class="page">
    <div class="container">
      <div class="lds-ring" v-if="!isDataLoaded"><div></div><div></div><div></div><div></div></div>
      <button v-if="isDataLoaded" @click="savePayBackend" class="btn btn_bg-dark pay-btn">Оплатить</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PayAction",
  data() {
    return {
      isScriptLoading: false,
      isDataLoaded: false,
      localCp: null
    }
  },
  mounted() {
    // Подгрузка виджета cloudpayments
    this.loadScript();
  },


  methods: {
    // Подгрузка виджета cloudpayments
    loadScript() {
      this.$loadScript("https://widget.cloudpayments.ru/bundles/cloudpayments").then(() => {
        this.isScriptLoading = true;

        // Сохранение на бекэнд
        this.savePayBackend();
      });
    },

    // Шаг 1. Сохранение запроса оплаты на бэкенд. Получаем orderId
    savePayBackend() {
      axios({
        url: process.env.VUE_APP_API + 'pay-cp',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then((resp) => {
            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log(resp)
            }
            if (resp && resp.data.order_id) {
              // Показываем форму оплаты
              this.pay(resp.data.order_id)
            }
            this.isDataLoaded = true;
          })
          .catch(err => {

            if (process.env.VUE_APP_TYPE === 'local' || this.isDebug) {
              console.log(err)
              console.log(err.response);
            }
            this.isDataLoaded = true;
          })
    },

    // Шаг 2. Оплата
    pay(orderId) {
      if (this.isScriptLoading) {
        let self = this
        /*global cp*/
        const publicId = process.env.VUE_APP_CLOUDPAYMENTS_PUBLIC_ID;
        const widget = new cp.CloudPayments();
        widget.pay('auth', // или 'charge'
            { //options
              publicId: publicId, //id из личного кабинета
              description: 'Оплата подписки', //назначение
              // amount: 149,
              amount: 799,
              currency: 'RUB',
              invoiceId: orderId,
              skin: "mini",
              // data: {
              //   userEmail: this.$store.getters.getUserEmail
              // }
            },
            {
              onSuccess: function () {
                // options
                // действие при успешной оплате
                // Срабатывает после нажатия на "ок" для закрытия виджета
                const link = '/payment-success/?order_id=' + orderId;
                self.$router.push(link);
              },
              onFail: function (reason, options) {
                // fail
                // действие при неуспешной оплате
                console.log('reason', reason);
                console.log('options2', options);
              },
              //
              onComplete: function (paymentResult, options) {
                // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                // например вызов вашей аналитики Facebook Pixel
                if (paymentResult && paymentResult?.success === true) {
                  let orderId = null;
                  if (options && options.invoiceId) {
                    orderId = options.invoiceId;
                  }
                  // Шаг 3. Отправляем на страницу с сообщением об успешной оплате
                  // this.payBackend(orderId);
                  const link = '/payment-success/?order_id=' + orderId;
                  self.$router.push(link);
                }
              }
            }
        )

      }
    },


    // payBackend(orderId) {
    //   this.$router.push('/payment-success/?order_id=' + orderId)
    // },

    // Уникальный код
    uniqueCode() {
      let text = ""
      let chars = "abcdefghijklmnopqrstuvwxyz"
      for (let i = 0; i < 5; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return Math.round(+new Date() / 1000) + text
    },

  }
}
</script>

<style scoped>
.page {
  padding-top: 80px;
}
.pay-btn {
  max-width: 200px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>