<template>
  <div class="full-page full-page_p-top">
    <div class="container">
      <div class="descriptions__header">
        <div class="descriptions__back" @click="back()"></div>
        <div class="descriptions__title">Портфолио</div>
      </div>
      <div class="portfolio">
        <div class="portfolio__list">
          <div class="portfolio__item" @click="go(111)">Графический дизайн</div>
          <div class="portfolio__item" @click="go(108)">Веб-дизайн</div>
          <div class="portfolio__item" @click="go(113)">Моушн-дизайн</div>
          <div class="portfolio__item" @click="go(112)">Дизайн интерьеров</div>
        </div>
        <div class="portfolio__list">
          <div class="portfolio__item" @click="go(115)">Фотосъемка</div>
          <div class="portfolio__item" @click="go(114)">Ретуширование</div>
          <div class="portfolio__item" @click="go(110)">Видеосъемка</div>
          <div class="portfolio__item" @click="go(109)">Видеомонтаж</div>
        </div>
        <div class="portfolio__list">
          <div class="portfolio__item" @click="go(119)">Верстка</div>
          <div class="portfolio__item" @click="go(107)">Frontend</div>
          <div class="portfolio__item" @click="go(116)">Backend</div>
          <div class="portfolio__item" @click="go(117)">QA</div>
        </div>
        <div class="portfolio__list">
          <div class="portfolio__item" @click="go(118)">SMM</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  mounted() {

  },
  computed: {
    isDebug() {
      return this.$route.query.isDebug || false
    },
  },
  methods: {
    go(id) {
      this.$router.push({path: '/phrases/portfolio/' + id, params: {type: 'portfolio', portfolio_id: id}})
    },
    example() {

    },
    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        const categoryType = localStorage.getItem('category_type');
        if (categoryType) {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
        }
        else if (this.$route.params && this.$route.params.type) {
          this.$router.push({name: 'main'})
        }
        else {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
        }
      }
    }
  }
}
</script>

<style scoped>
.create__block {
  cursor: pointer;
}
</style>