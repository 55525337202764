<template>
  <div class="popup-filters" :class="{'js-active' : isActive}">
    <div class="container">
      <div class="popup-filters__content">
        <div class="popup-filters__header">
          <div class="popup-filters__title">Дополняйте</div>
          <div class="popup-filters__icon popup-filters__icon_active" @click="close"></div>
        </div>
        <div class="filters">
          <div class="filters__row">
            <div class="filters__title">Резюме</div>
            <div class="filters__list">
              <div class="filters__item filters__item_big" @click="go(3)">Деловые<br> качества
              <div>Тезисно</div>
            </div>
              <div class="filters__item filters__item_big" @click="go(4)">Личные<br> качества
                <div>Тезисно</div>
              </div>
            </div>
          </div>
          <div class="filters__row">
            <div class="filters__title">Письмо</div>
            <div class="filters__list">
              <div class="filters__item filters__item_big" @click="go(23)">Деловые<br> качества
                <div>Подробно</div>
              </div>
              <div class="filters__item filters__item_big" @click="go(25)">Личные<br> качества
                <div>Подробно</div>
              </div>
              <div class="filters__item filters__item_small" @click="go(24)">Достижения</div>
            </div>
          </div>
          <div class="filters__row">
            <div class="filters__title">Шпаргалка</div>
            <div class="filters__list">
              <div class="filters__item" @click="go(43)">Недостатки</div>
              <div class="filters__item" @click="go(44)">Особенности
              </div>
              <div class="filters__item filters__item_small" @click="go(45)">Хочу научиться</div>
            </div>
          </div>
          <div class="filters__row">
            <div class="filters__title">Соцсети и знакомства</div>
            <div class="filters__list">
              <div class="filters__item" @click="go(48)">Музыка</div>
              <div class="filters__item" @click="go(47)">Кино</div>
              <div class="filters__item filters__item_small" @click="go(49)">Стихия</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Filters",
  props: ['isActive'],
  methods: {
    close() {
      this.$emit('closeFilters')
    },
    go(id) {
      const lastSlide = localStorage.getItem('lastSlide');
      if (this.$route && this.$route.query && this.$route.query.filter_id && parseInt(this.$route.query.filter_id) === id) {
        this.close();
      } else {
        this.$router.push({query: { filter_id: id }, params: {lastSlide: lastSlide, isBack: false}});
        this.close();
      }
    }
  }
}
</script>

<style scoped>

</style>