<template>
  <div>
    <!--<Menu :isNeedOpen="openMenu" @openMenu="onOpenMenu" isSpecialHeader="true"/>-->

    <div class="full-page full-page_grey full-page_flex-column full-page_wrap full-page_examples">
      <div class="container">

        <div class="descriptions__header">
          <div class="descriptions__back" @click="back()"></div>
          <div class="descriptions__title">Примеры описаний</div>
        </div>

        <div class="phrases phrases_examples">
          <div class="phrases__container">
            <div class="phrases__list phrases__list_examples">
              <div class="swiper-container"
                   ref='mySwiper'
                   v-swiper:mySwiper="swiperOptions"
                   @slideChange="changeSwiperIndex"
                   :auto-update="true"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide" :class="'slide' + index"
                       :ref="'slide' + index"
                       :key="index"
                       v-for="(slide, index) in filteredData"
                  >

                    <div class="phrases__item phrases__item_example phrases__item_example-big">
                      <div class="phrases__header phrases__header_descriptions">
                        <div class="phrases__avatar"><img :src="'/img/content/phrases-descriptions_' + slide.avatar + '.png?v=5'" :srcset="'/img/content/phrases-descriptions_' + slide.avatar  + '@2x.png?v=5 2x'"></div>
                        <div class="phrases__info">
                          <div class="phrases__name">{{ slide.name }}</div>
                          <div class="phrases__work">{{ slide.work }}</div>
                        </div>
                      </div>
                      <div class="phrases__title phrases__title_descriptions" v-html="slide.title"></div>
                      <div class="phrases__text" v-html="slide.text"></div>
                      <div class="phrases__pro" v-if="slide.isPro">PRO</div>
                      <div class="phrases__open btn btn_bg-grey" @click="openPopup(slide)" v-if="!slide.hideFull">Открыть</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="phrases__dots phrases__dots_examples swiper-pagination swiper-pagination-bullets" :class="{'phrases__dots_flex': isSlidesCountMass}"></div>
            <div class="phrases__bottom" v-if="isNeed">
              <p>Создать описание</p>
              <div class="phrases__buttons">
                <div class="btn btn_bg-grey" @click="$router.push('/phrases')">Общее</div>
                <div class="btn btn_bg-grey" @click="$router.push({name:'pro', params: {isBack: 'true', destination: 'exampleDescriptions'}})">PRO</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<phrase-popup @closePopup="onHidePopup" @clickAdd="onClickAdd" :show="setPopupShow" :phraseTitle="phraseTitle" :phraseText="phraseText"/>-->

    <div class="description-full" :class="{'js-active': isPopupShow}">
      <div class="description-full__wrapper">
        <div class="description-full__close" @click="closePopup"></div>
        <div class="container">
          <div class="description-full__text" v-html="handleNewLine(popupDescription)"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {directive} from "vue-awesome-swiper";
// import Menu from "@/components/Menu";

export default {
  name: "ExampleDescriptions",
  directives: {
    swiper: directive
  },
  components: {
    // Menu,
  },
  data() {
    return {
      isNeed: false,
      isSlidesCountMass: false,
      openMenu: false,
      //region Slider
      slides: [
        {
          avatar: 1,
          name: "Олеся, 22",
          work: "Студентка РГГУ",
          title: "Добавила в резюме на hh.ru<br>" +
              "в раздел «О себе»",
          text: "<b>Деловые качества:</b><br/>" +
              "Навыки работы с информацией, ее анализа и структурирования.<br/>" +
              "<br/>" +
              "Навыки копирайтинга, опыт составления текстов на различные (в том числе новые для себя) темы.<br/>" +
              "<br/>" +
              "Понимание специфики работы с медиаконтентом, опыт создания и редактирования фото- и видеоматериала.<br/>",
          textFull: "<b>Деловые качества:</b><br/>" +
              "<br/>" +
              "Навыки работы с информацией, ее анализа и структурирования.<br/>" +
              "<br/>" +
              "Навыки копирайтинга, опыт составления текстов на различные (в том числе новые для себя) темы.<br/>" +
              "<br/>" +
              "Понимание специфики работы с медиаконтентом, опыт создания и редактирования фото- и видеоматериала.<br/>" +
              "<br/>" +
              "Владение английским языком на уровне В2 (практически свободное устное и письменное общение).<br/>" +
              "<br/>" +
              "<br/>" +
              "<b>Личные качества:</b><br/>" +
              "<br/>" +
              "Любовь к организованности и самодисциплине.<br/>" +
              "<br/>" +
              "Самостоятельность. Желание работать в условиях доверия и определенной творческой свободы.<br/>" +
              "<br/>" +
              "Инициативность и умение брать на себя дополнительную личную ответственность.<br/>" +
              "<br/>" +
              "Любознательность. Желание открывать для себя что-то новое.<br/>" +
              "<br/>" +
              "Внимание к деталям и разумный педантизм.<br/>" +
              "<br/>" +
              "Эмпатия. Способность к сопереживанию и пониманию потребностей другого человека.",
          type: 'resume',
          type_id: 0
        },
        {
          avatar: 2,
          name: "Арсений, 20",
          work: "Студент МАИ",
          title: "Добавил в резюме на SuperJob.ru<br> в раздел «Знания и навыки»",
          text: "<b>Деловые качества:</b><br/>" +
              "Навыки осуществления сложных математических расчетов.<br/>" +
              "<br/>" +
              "Владение инструментами проектирования и 3D-визуализации (3ds Max, AutoCAD, Solid Edge).<br/>" +
              "<br/>" +
              "Навыки графического дизайна (начальный уровень).<br/>",
          textFull: "<b>Деловые качества:</b><br/>" +
              "<br/>" +
              "Навыки осуществления сложных математических расчетов.<br/>" +
              "<br/>" +
              "Владение инструментами проектирования и 3D-визуализации (3ds Max, AutoCAD, Solid Edge).<br/>" +
              "<br/>" +
              "Навыки графического дизайна (начальный уровень).<br/>" +
              "<br/>" +
              "Опыт программирования расчетных алгоритмов (Python, PHP).<br/>" +
              "<br/>" +
              "Владение английским языком на уровне А2 (чтение и изъяснение вызывает у меня небольшие сложности).<br/>" +
              "<br/>" +
              "<br/>" +
              "<b>Личные качества:</b><br/>" +
              "<br/>" +
              "Целеустремленность и последовательность. Умение доводить каждое дело до логического завершения.<br/>" +
              "<br/>" +
              "Многозадачность. Умение переключаться и не терять энергичность.<br/>" +
              "<br/>" +
              "Остроумие и адекватная степень самоиронии.<br/>" +
              "<br/>" +
              "Вдумчивость. Умение принимать рациональные, взвешенные решения.<br/>" +
              "<br/>" +
              "Усидчивость. Способность к длительной сконцентрированной работе.<br/>",
          type: 'resume',
          type_id: 0
        },
        {
          avatar: 14,
          name: "Алексей, 26",
          work: "Графический дизайнер",
          title: "Добавил в резюме",
          text: "<b>Деловые качества:</b><br>" +
              "Опыт работы с инструментами Adobe: <br/>" +
              "Photoshop, Illustrator, InDesign, Premiere, Acrobat.<br>" +
              "<br>" +
              "Опыт работы с инструментами проектирования: " +
              "Figma, Sketch, Invision.<br/>" +
              "<br/>" +
              "Опыт верстки на Tilda, Webflow.<br/>" +
              "<br/>" +
              "Опыт полиграфической верстки на QuarkXPress.",
          textFull: "<b>Деловые качества:</b>\n\n" +
              "Опыт работы с инструментами Adobe: Photoshop, Illustrator, InDesign, Premiere, Acrobat.\n\n" +
              "Опыт работы с инструментами проектирования: Figma, Sketch, Invision.\n\n" +
              "Опыт верстки на Tilda, Webflow.\n\n" +
              "Опыт полиграфической верстки на QuarkXPress.\n\n" +
              "Знание HTML/CSS, GIT, опыт адаптивной верстки.\n\n" +
              "Знание традиционного и диджитал-рисунка, векторной и растровой графики.\n\n" +
              "Уверенное пользование графическим планшетом.\n\n" +
              "Опыт работы с таск-трекерами: Redmine, Trello, Asana.\n\n" +
              "Знание базовых программ Microsoft Office и инструментов Google.\n\n" +
              "Опыт делегирования и менеджмента.\n\n" +
              "Владение английским на уровне В2 (практически свободное устное и письменное общение).\n\n" +
              "\n" +
              "<b>Личные качества:</b>\n\n" +
              "Любовь к организованности и дисциплине.\n\n" +
              "Большой опыт многозадачности.\n\n" +
              "Нацеленность на приоритезацию задач.\n\n" +
              "Способность быстро адаптироваться к изменениям в проектах.\n\n" +
              "Холодный подход к стрессовым ситуациям.\n\n" +
              "Стремление находить компромиссные решения.\n\n",
          type: 'resume',
          type_id: 0
        },
        {
          avatar: 3,
          name: "Александр, 22",
          work: "Студент МГУ",
          title: "Отправил в личку Instagram",
          text: "Привет!<br/>" +
              "<br/>" +
              "Увидел вакансию в сторис. Это супер интересно!<br/>" +
              "<br/>" +
              "Снимаю видео и фотографию. Умею видеть кадр целиком, находить правильный ракурс, улавливать идеальный свет.<br/>" +
              "<br/>" +
              "Мне нравится экспериментировать.<br/>" +
              "И мне легко сочинять.",
          textFull: "Привет!\n\n" +
              "Увидел вакансию в сторис. Это супер интересно!\n\n" +
              "Снимаю видео и фотографию. Умею видеть кадр целиком, находить правильный ракурс, улавливать идеальный свет.\n\n" +
              "Мне нравится экспериментировать. И мне легко сочинять.\n\n" +
              "Пишу разнообразно. Использую различную стилистику – от строго делового до расслабленно молодежного и коммерчески привлекательного. Зависит от задачи.\n\n" +
              "Хотел бы развиваться в творческом направлении. Мне крайне импонирует то, чем вы занимаетесь. Было бы здорово стать частью вашей команды.\n\n" +
              "Думаю, у меня получится привнести в компанию свежий взгляд, посмотреть на рабочий процесс с новой стороны и, возможно, его оптимизировать.",
          type: 'letter',
          type_id: 0
        },
        {
          avatar: 4,
          name: "Анна, 19",
          work: "Студентка СПбГЭУ",
          title: "Отправила по email",
          text: "Учусь в Санкт-Петербургском государственном экономическом университете на втором курсе бакалавриата. Изучаю экономику и финансы. <br/>" +
              "<br/>" +
              "Получаю удовольствие от работы с информацией. Мне нравится процесс анализа и систематизации. Инстинктивно стремлюсь к тому, чтобы конвертировать разрозненные данные в четкие, аргументированные смыслы.",
          textFull: "Учусь в Санкт-Петербургском государственном экономическом университете на втором курсе бакалавриата. Изучаю экономику и финансы. \n" +
              "\n" +
              "Получаю удовольствие от работы с информацией. Мне нравится процесс анализа и систематизации. Инстинктивно стремлюсь к тому, чтобы конвертировать разрозненные данные в четкие, аргументированные смыслы.\n" +
              "\n" +
              "Планирую освоить навыки составления презентаций. Интуитивных, лаконичных и визуально привлекательных. Понимаю, что это наилучший – и чрезвычайно актуальный – способ грамотно и аргументировано доносить идеи.\n" +
              "\n" +
              "Играю в волейбол – этот вид спорта отлично тренирует чувство пасса (как я его называю), ощущение синхронности в действиях команды. Для меня это чрезвычайно важно.\n" +
              "\n" +
              "Поступление в один из ведущих вузов Петербурга, будучи жителем небольшого поселка (без каких-либо связей и финансовых ресурсов), считаю своим главным достижением.\n" +
              "\n" +
              "Участвую в конференциях. Четыре раза из пяти занимала первое место. Доклады готовлю как на русском, так и на английском языках.\n" +
              "\n" +
              "Работала волонтером. Участвовала в организации спортивных, экскурсионных и культурно-исторических мероприятий.\n" +
              "\n" +
              "Мечтаю работать в сильной устоявшейся компании с международным именем. Моя цель – приобрести качественный опыт и навыки, стать в хорошем смысле «звездой», высоковостребованным специалистом.\n" +
              "\n" +
              "Буду рада совмещенному режиму работы (в офисе и удаленно). Нахожусь на очной форме обучения.",
          type: 'letter',
          type_id: 0
        },
        {
          avatar: 5,
          name: "Кира, 23",
          work: "Моушн-дизайнер",
          title: "Разместила на личном сайте",
          text: "Занимаюсь моушн-дизайном порядка пяти лет.<br/>" +
              "<br/>" +
              "Имею опыт реализации разноплановых проектов в области рекламы и интернет-торговли. <br/>" +
              "<br/>" +
              "Имею опыт анимации двух- и трехмерных объектов, персонажей, объемных статистических данных, а также текстовой информации.",
          textFull: "Занимаюсь моушн-дизайном порядка пяти лет.\n" +
              "\n" +
              "Имею опыт реализации разноплановых проектов в области рекламы и интернет-торговли. \n" +
              "\n" +
              "Имею опыт анимации двух- и трехмерных объектов, персонажей, объемных статистических данных, а также текстовой информации.\n" +
              "\n" +
              "Понимаю специфику работы видеоператора и режиссера видеомонтажа. Знакома с особенностями студийной и уличной съемки.\n" +
              "\n" +
              "При необходимости осуществляю обработку фотографий: цветокоррекция, обтравка, ретушь.\n" +
              "\n" +
              "Знаю, как создавать контент для больших медиаэкранов.\n" +
              "\n" +
              "Считаю компьютерную графику своим призванием. Стремлюсь повышать собственный уровень профессионализма с каждым новым проектом.\n" +
              "\n" +
              "Ценю уважительное и тактичное общение. Активно участвую в коллективном творчестве, соблюдая границы личного пространства и нерабочего времени.",
          type: 'portfolio',
          type_id: 0
        },
        {
          avatar: 6,
          name: "Станислав, 24",
          work: "Фотограф",
          title: "Разместил на личном сайте",
          text: "Занимаюсь фотографией более пяти лет.<br/>" +
              "<br/>" +
              "Имею опыт съемки на пленочный фотоаппарат. <br/>" +
              "<br/>" +
              "Люблю создавать атмосферные снимки, обладающие естественной глубиной (без искусственной цветокоррекции).<br/>" +
              "<br/>" +
              "Снимаю как черно-белую, так и цветную фотографию.",
          textFull: "Занимаюсь фотографией более пяти лет.\n\n" +
              "Имею опыт съемки на пленочный фотоаппарат. Люблю создавать атмосферные снимки, обладающие естественной глубиной (без искусственной цветокоррекции).\n\n" +
              "Снимаю как черно-белую, так и цветную фотографию.\n\n" +
              "Специализируюсь на портретной съемке. Изучаю характер и манеры человека, стараюсь интуитивно улавливать его задумчивость (а порой «безмыслие»).\n\n" +
              "Периодически снимаю репортаж. Подстраиваюсь под меняющиеся композицию и свет, делаю свое присутствие «незаметным», чтобы «не спугнуть» тот самый динамичный кадр.  \n\n" +
              "Предпочитаю работать в стилистике минимализма. Вижу смысл в отсутствии лишних предметов, наличии спокойного однотонного фона и, возможно, одного цветового акцента.\n\n" +
              "Мне также нравится авангард. Иногда требуется смешать стили, добавить в кадр элементы театральности и сюрреалистичности.\n\n" +
              "Восхищаюсь работами Сары Мун. Мне нравится ее акварельный и, в какой-то степени, импрессионистский стиль. \n\n" +
              "Имею устоявшуюся команду (визажист, nail-стилист, сет-дизайнер). Привлекаю к проекту профессионалов, которым доверяю.\n\n" +
              "Умею доносить свои идеи, грамотно и дипломатично защищать авторскую задумку. \n\n" +
              "Отношусь к каждому проекту как к своему личному. Искреннее удовлетворение заказчика – моя лучшая награда.",
          type: 'portfolio',
          type_id: 0
        },
        {
          avatar: 7,
          name: "Ирина, 21",
          work: "Frontend-разработчик",
          title: "Отправила работодателю",
          text: "Занимаюсь frontend-программированием порядка трех лет.<br/>" +
              "<br/>" +
              "Имею опыт реализации двух проектов в сфере E-commerce.<br/>" +
              "<br/>" +
              "Использую методологию объектно-ориентированного программирования. Руководствуюсь принципами DRY, KISS, SOLID.",
          textFull: "Занимаюсь frontend-программированием порядка трех лет.\n" +
              "\n" +
              "Имею опыт реализации двух проектов в сфере E-commerce.\n" +
              "\n" +
              "Использую методологию объектно-ориентированного программирования. Руководствуюсь принципами DRY, KISS, SOLID.\n" +
              "\n" +
              "Для автоматизации рутинных операций использую препроцессоры HTML (Pug, Haml).\n" +
              "\n" +
              "В целях ускорения и упрощения разработки, обеспечения «бесшовной» поддержки стилей использую препроцессоры CSS (SCSS, SASS).\n" +
              "\n" +
              "Ориентируюсь в методике непрерывной разработки и интеграции. Считаю CI/CD важнейшим инструментом поддержки и укрепления конкурентных преимуществ компании. \n" +
              "\n" +
              "Уделяю внимание UX/UI. Стараюсь создавать эмоционально привлекательный, интуитивно понятный и функциональный интерфейс.\n" +
              "\n" +
              "Стремлюсь разрабатывать сложные сервисы, создавать визуально привлекательные проекты, а также переводить статичный контент в интерактивные веб-приложения.\n",
          type: 'portfolio',
          type_id: 0
        },
        {
          avatar: 8,
          name: "Анастасия, 20",
          work: "Студентка КФУ",
          title: "Использовала на собеседовании",
          text: "К сожалению или к счастью, я довольно чувствительная. И потому склонна принимать вещи слишком близко к сердцу.<br/>" +
              "<br/>" +
              "Плюс, правда, в том, что чужую проблему воспринимаю как личную, и мне искренне хочется помочь. Минус – в чрезмерной эмоциональности. Мне важно научиться управлять этим чувством.",
          textFull: "К сожалению или к счастью, я довольно чувствительная. И потому склонна принимать вещи слишком близко к сердцу.\n" +
              "\n" +
              "Плюс, правда, в том, что чужую проблему воспринимаю как личную, и мне искренне хочется помочь. Минус – в чрезмерной эмоциональности. Мне важно научиться управлять этим чувством.\n" +
              "\n" +
              "А еще я довольно мнительный человек. Часто придумываю себе проблемы на ровном месте.\n" +
              "\n" +
              "Не испытываю проблем при работе с цифрами. Вместе с тем навыки работы с Excel оцениваю на уровне ниже среднего. Уверена, смогу улучшить компетентность в этом вопросе – в короткие сроки и без посторонней помощи.\n" +
              "\n" +
              "Стыдно признаться, но я тяжело переношу критику. При этом понимаю, что критика – конструктивная, конечно, - крайне полезна. Для каждого из нас.\n",
          type: 'crib',
          type_id: 0
        },
        {
          avatar: 9,
          name: "Евгений, 22",
          work: "Студент ВолгГТУ",
          title: "Отправил по WhatsApp",
          text: "Учусь создавать крутой медиаконтент. <br/>" +
              "Понимаю, что это многоплановый и неограниченный рамками творческий процесс. Это мегакруто!<br/>" +
              "<br/>" +
              "Учусь снимать классные видео и применять различные техники видеомонтажа.<br/>" +
              "<br/>" +
              "Хотел бы также приобрести навыки работы на этапе pre-production.",
          textFull: "Учусь создавать крутой медиаконтент. \n" +
              "Понимаю, что это многоплановый и неограниченный рамками творческий процесс. Это мегакруто!\n" +
              "\n" +
              "Учусь снимать классные видео и применять различные техники видеомонтажа.\n" +
              "\n" +
              "Хотел бы также приобрести навыки работы на этапе pre-production: разработка концепции, написание сценария, раскадровка.\n" +
              "\n" +
              "Конечно, у меня есть свои минусы. \n" +
              "Замечаю за собой излишнюю поспешность. Стараюсь выполнить задачу быстрее и в результате допускаю обидные оплошности. Мне нужно научиться делать все более размеренно.\n" +
              "\n" +
              "И я до сих пор сложно переживаю неудачи. Понимаю, что ошибки неизбежны, но по-прежнему расстраиваюсь, когда осознаю, что сделал что-то неправильно.\n",
          type: 'crib',
          type_id: 0
        },
        {
          avatar: 10,
          name: "Максим, 26",
          work: "",
          title: "Разместил в Tinder",
          text: "«Он невероятно умен» – The Telegraph.<br/>" +
              "<br/>" +
              "«Его манерам можно позавидовать» – Меган Маркл.<br/>" +
              "<br/>" +
              "«Кажется, этот парень объездил весь мир» – Орел и Решка.<br/>" +
              "<br/>" +
              "«Хватит сочинять чужие цитаты» – мой первый учитель.<br/>" +
              "<br/>" +
              "«Мой рост – 178 см» – я.",
          textFull: "",
          hideFull: true,
          type: 'connection',
          type_id: 0
        },
        {
          avatar: 11,
          name: "Мария, 24",
          work: "",
          title: "Разместила в Bumble",
          text: "172. Познакомлюсь с интересным и свободным мужчиной.<br/>" +
              "<br/>" +
              "Без ума от музыки. Надеюсь, у нас с тобой это взаимно.<br/>" +
              "<br/>" +
              "Влюблена в архитектуру. Будет классно, если ты тоже (интересно, тебе нравится французский балкон?).<br/>" +
              "<br/>" +
              "Мне нравится ренессанс и классическая живопись.",
          textFull: "172. Познакомлюсь с интересным и свободным мужчиной.\n" +
              "\n" +
              "Без ума от музыки. Надеюсь, у нас с тобой это взаимно.\n" +
              "\n" +
              "Влюблена в архитектуру. Будет классно, если ты тоже (интересно, тебе нравится французский балкон?).\n" +
              "\n" +
              "Мне нравится ренессанс и классическая живопись. Видимо, я поклонница реалистичного и фотографически точного мира :)\n" +
              "\n" +
              "Мне будет комфортно, если ты будешь выше меня ростом.",
          type: 'connection',
          type_id: 0
        },
        {
          avatar: 12,
          name: "Полина, 21",
          work: "Студентка РГПУ им. Герцена",
          title: "Разместила в Instagram",
          text: "Живу в Петербурге. Учусь в Институте иностранных языков РГПУ им. Герцена.<br/>" +
              "<br/>" +
              "Изучаю английский и французский. Скоро начну итальянский. А еще хочу «взять» испанский. Вот и я думаю – не многовато ли 😂<br/>" +
              "<br/>" +
              "Люблю классическую музыку. Наверное, это выдает во мне выпускницу музыкальной школы 🙂<br/>",
          textFull: "Живу в Петербурге. Учусь в Институте иностранных языков РГПУ им. Герцена.\n" +
              "\n" +
              "Изучаю английский и французский. Скоро начну итальянский. А еще хочу «взять» испанский. Вот и я думаю – не многовато ли 😂\n" +
              "\n" +
              "Люблю классическую музыку. Наверное, это выдает во мне выпускницу музыкальной школы 🙂\n" +
              "\n" +
              "Играю на фортепиано. Композиция «Hero» в исполнении Мэрайя Кэри получается у меня, пожалуй, лучше всего. Только я не пою 😊\n" +
              "\n" +
              "К сожалению, я очень сентиментальная. Люблю песни про любовь. Главное – не слушать слишком часто Джона Майера, иначе можно влюбиться…\n" +
              "\n" +
              "Родилась в поселке Пено Тверской области. Он со всех сторон омывается Волгой. Неудивительно, что мое любимое место в Петербурге – Новая Голландия. Центр, тишина, а вокруг вода…\n" +
              "\n" +
              "Страшно боюсь темноты. Поэтому каждый день вынуждена преодолевать себя 🙂\n" +
              "\n" +
              "Могу часами смотреть на синий цвет. \n" +
              "\n" +
              "И я без ума от запаха мяты и зеленого чая.\n",
          type: 'socials',
          type_id: 0
        },
        {
          avatar: 13,
          name: "Александр, 22",
          work: "Студент РУДН",
          title: "Разместил в Facebook",
          text: "Учусь в РУДН. Изучаю гостиничный бизнес и туризм. Интересно, туризм еще актуален? :)<br/>" +
              "<br/>" +
              "Обожаю Нью-Йорк. Не могу забыть Манхэттен. Наверное, исходил все его улицы.<br/>" +
              "<br/>" +
              "Если будете в Нью-Йорке, загляните в Gantry Plaza State Park. Это старый причал в районе Queens аккурат напротив здания ООН.",
          textFull: "Учусь в РУДН. Изучаю гостиничный бизнес и туризм. Интересно, туризм еще актуален? :)\n" +
              "\n" +
              "Обожаю Нью-Йорк. Не могу забыть Манхэттен. Наверное, исходил все его улицы.\n" +
              "\n" +
              "Если будете в Нью-Йорке, загляните в Gantry Plaza State Park. Это старый причал в районе Queens аккурат напротив здания ООН. Оттуда, пожалуй, лучший вид на небоскребы. Лучше вечером, когда огни. Незабываемо!\n" +
              "\n" +
              "Я киноман. Меня цепляет сильное кино. То, что провоцирует рефлексию и вызывает сильный отклик внутри.\n" +
              "\n" +
              "В этом смысле меня сильно поразил «Синонимы» Надавы Лапиды.\n" +
              "\n" +
              "Слушаю рэп. Причем американский. Причем из золотых 1980-х. Чтоб классический бит и звук пластинки. Все как у Public Enemy и Geto Boys. \n" +
              "\n" +
              "И я поклонник джаза. Удивительно, но джаз помогает мне сконцентрироваться, когда для большинства – это способ расслабиться. Песни Майкла Бубле 👍\n" +
              "\n" +
              "Родом из подмосковного Фрязино. Там недалеко усадьба Гребнево – невероятно красивое место. Если еще не видели, приезжайте. Вам понравится!\n",
          type: 'socials',
          type_id: 0
        },
        //
        // {
        //   name: "Елена, 26",
        //   work: "Фармацевт-провизор",
        //   title: "Отправила по email",
        //   text: "Имею опыт работы фармацевтом-провизором порядка трех лет.<br/>" +
        //       "<br/>" +
        //       "Знакома с аптечным ассортиментом, разбираюсь в составе лекарственных средств и показаниях к их применению.<br/>" +
        //       "<br/>" +
        //       "Осуществляю отпуск изделий медицинского назначения и сопутствующих товаров.<br/>" +
        //       "<br/>" +
        //       "Понимаю специфику работы за «первым столом».",
        //   textFull: "Имею опыт работы фармацевтом-провизором порядка трех лет.\n\n" +
        //       "Знакома с аптечным ассортиментом, разбираюсь в составе лекарственных средств и показаниях к их применению.\n\n" +
        //       "Осуществляю отпуск изделий медицинского назначения и сопутствующих товаров.\n\n" +
        //       "Понимаю специфику работы за «первым столом».\n\n" +
        //       "Стрессоустойчива. Психологически и физически вынослива.\n\n" +
        //       "Испытываю естественное желание помогать людям.\n\n" +
        //       "В общении с посетителями исключительно профессиональна и тактична – особенно с людьми старшего и преклонного возраста.\n\n" +
        //       "Умею работать в паре. Подстраховка и взаимопомощь – для меня непустые слова.\n\n" +
        //       "Имею действующий сертификат: «Фармация».\n\n" +
        //       "Владею английским языком на уровне А1 (общение будет для меня затруднительным).\n\n",
        //   isPro: true
        // },
        // {
        //   name: "Анна, 28",
        //   work: "Бухгалтер по расчету заработной платы",
        //   title: "Использовала на собеседовании",
        //   text: "Работаю над повышением личной эффективности. Цифры требуют порядок. Особенно в отчетах. Понимаю, что любая неточность в итоговых показателях <br/>" +
        //       "несет риск финансовых и репутационных потерь. Поэтому при работе с данными предельно внимательна и аккуратна.",
        //   textFull: "Работаю над повышением личной эффективности. Цифры требуют порядок. Особенно в отчетах. Понимаю, что любая неточность в итоговых показателях несет риск финансовых и репутационных потерь. Поэтому при работе с данными предельно внимательна и аккуратна.\n\n" +
        //       "Инициативна. Если вижу возможность для оптимизации, действую. Ранее сумела оптимизировать порядок учета резерва на оплату отпусков, что способствовало равномерному распределению расходов компании по году.\n\n" +
        //       "Люблю совершать пешие прогулки. Считаю, что прогулка перед сном – лучший способ расслабиться и «обновиться» перед следующим днем.   \n\n" +
        //       "Поклонница красивой музыки. Смогу составить вдохновляющий и мотивирующий плейлист для сотрудников компании.\n\n",
        //   isPro: true
        // }
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: false,
        slidesPerView: 1,
      },
      totalSlides: 0,
      swiperTouchStartX: null,
      //endregion
      currentSlide: 0,
      savedCount: 0,
      phraseTitle: '',
      phraseText: '',
      phrasesCategories: [],
      currentCategory: 1,
      isProCategory: false,
      isDataLoaded: false,

      popupTitle: '',
      popupDescription: '',
      isPopupShow: false,
      filter: ''
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    filteredData() {
      if (!this.filter) {
        return this.slides;
      }
      return this.slides.filter(p => p.type === this.filter);
    }
  },
  methods: {
    // При смене слайда
    changeSwiperIndex() {

    },
    back() {
      const lastPageObj = this.$store.state.route.from;

      if (lastPageObj && lastPageObj.name !== 'phrases_types' && lastPageObj.name !== this.$route.name) {
        this.$router.push({name: lastPageObj.name})
      }
      else {
        const categoryType = localStorage.getItem('category_type');
        if (categoryType) {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: categoryType, isBack: true}})
        }
        else if (this.$route.params && this.$route.params.type) {
          this.$router.push({name: 'want'})
        }
        else {
          this.$router.push({name: 'phrases_types', params: {openMenu: true, type: 'resume'}})
        }
      }
    },
    init() {

      // Фильтрация по категории
      if (this.$route.params && this.$route.params.type) {
        this.filter = this.$route.params.type;
      }
      // Если слайдов > 10
      if (this.filteredData.length > 9) {
        this.isSlidesCountMass = true;
      }
    },
    incrementIndex(val) {
      return val + 1
    },

    handleNewLine(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },

    openPopup(slide) {
      // this.currentSlide = this.mySwiper.activeIndex;
      // localStorage.setItem('lastSlideDescriptions', this.mySwiper.activeIndex);
      // console.log('lastSlideDescriptions', localStorage.getItem('lastSlideDescriptions'));
      // console.log('slide', slide);
      this.isPopupShow = true;
      this.popupTitle = slide.title;
      this.popupDescription = slide.textFull;

    },
    // Открытие меню
    onOpenMenu() {
      this.openMenu = false
    },

    closePopup() {
      this.isPopupShow = false;

      // const lastSlide = localStorage.getItem('lastSlideDescriptions');
      // if (lastSlide) {
      //   this.mySwiper.slideTo(parseInt(lastSlide), 1000, false);
      // }
    }
  }
}
</script>

<style scoped>

</style>